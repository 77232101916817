import { Formik, Form } from 'formik';
import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import Button, { CloseButton } from '~/components/common/Button';
import { TextLink } from '~/components/common/TextLink';
import { Field } from '~/components/common/Formik';
import {
  handleLoginSuccess,
  useLogin,
  useRegister,
  useVerifyEmail,
  useVerifyReCaptcha,
} from '~/services/api/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_KEY } from '~/config';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import {
  RegisterSchema,
  VerifyEmailSchema,
  RegisterWithOutReCaptchaSchema,
} from '~/utils/schema/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import themes, { styled } from '~/themes';
import Modal from '~/components/common/Modal';
import Tooltip, { TooltipPasswordContent } from '~/components/common/Tooltip';
import InfoIcon from '~/assets/images/icons/info.svg';
import {
  addToQueryString,
  fromQueryString,
  omitFromQueryString,
} from '~/utils/queryString';
import { useAuth } from '~/providers/AuthProvider';
import Logo from '~/assets/images/logo/logo.svg';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { use2Fa } from '~/hooks/use2Fa';
import { AuthLayout } from './components/AuthLayout';
import DialogVerifyCode2FA from './DialogVerifyCode2FA';
import VerifyCode2FA from './VerifyCode2FA';

type SignUpProps = {};

enum RegisterState {
  VerifyAccount = 'verify-account',
  HasOtherBsAccount = 'has-bs-account',
  RegisterForm = 'register-form',
}

export const StyledTextInputField = styled(Field.TextInput)(() => ({
  borderRadius: '10px !important',
  minHeight: '46px !important',
  maxHeight: '46px !important',
}));

export const StyledButton = styled(Button)(() => ({
  'borderRadius': '10px !important',
  'minHeight': '50px !important',
  'maxHeight': '50px !important',
  'fontSize': 18,
  'background': '#FD9C78',
  '&:hover': {
    background: '#FD9C78',
  },
}));

export const StyledLogoContainer = styled(Box)(() => ({}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 'bold',
  textAlign: 'center',
  paddingBottom: theme.spacing(2),
  color: '#282E3A !important',
}));

function UnifiedAlert({ open, onClose }) {
  const navigate = useNavigate();

  return (
    <Modal
      PaperProps={{
        sx: {
          maxWidth: 350,
          borderRadius: '10px !important',
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Stack alignItems='center' position='relative'>
        <CloseButton
          iconSize={12}
          sx={{
            position: 'absolute',
            top: 10,
            right: -10,
            background: 'transparent',
          }}
          onClick={onClose}
        />
        <Typography
          fontWeight='500'
          fontSize={20}
          my={3}
          sx={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
        >
          {
            'An account with the same\n email address already exists.\n Please sign in.'
          }
        </Typography>
        <StyledButton
          sx={{ width: 210 }}
          onClick={() => {
            navigate('/login');
            onClose();
          }}
        >
          Sign in
        </StyledButton>
      </Stack>
    </Modal>
  );
}

export const Register: React.FC<SignUpProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isOffRecaptchaValidate = useFeatureIsOn('off-recaptcha-validate');
  const isNewSSO = useFeatureIsOn(
    'acd-11071-cookie-attributes-not-set-optimal-security',
  );

  const searchQueries = fromQueryString(location.search);
  const { account } = useAuth();

  const [registerState, setRegisterState] = useState<RegisterState>(
    RegisterState.VerifyAccount,
  );

  const hasCFAccountAndHasBsAccountRef = useRef<boolean>(false);
  const [recaptchaId, setRecaptchaId] = useState('');

  const [storeAuthParams, setStoreAuthParams] = useState({
    email: '',
    password: '',
  });
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [openUnifiedAlert, setOpenUnifiedAlert] = useState(false);

  const {
    handleEnable2FA,
    handleSendCode,
    loadingSendCode,
    phone,
    handleVerifyCode,
    enable2FA,
    handleCancel,
    loadingVerify,
    errorMsg: errorMsg2Fa,
    clearErrorMsg,
  } = use2Fa({
    recaptchaId,
    onLoginSuccess: async () => {
      await handleLoginSuccess(isNewSSO, {
        isSetCustomClaim: true,
      });
      navigate({
        pathname: '/user-onboarding',
        search: addToQueryString(location.search, {
          email: storeAuthParams.email,
        }),
      });
    },
  });

  const clearAllErrorMsg = () => {
    clearErrorMsg();
    setErrorMsg('');
  };

  const { mutate: loginMutation, isLoading: isLoginLoading } = useLogin({
    options: {
      isSetCustomClaim: true,
    },
    onSuccess: async () => {
      navigate({
        pathname: '/user-onboarding',
        search: addToQueryString(location.search, {
          email: storeAuthParams.email,
        }),
      });
    },
    onError: (errorString, error) => {
      if (error?.code === 'auth/multi-factor-auth-required') {
        handleEnable2FA(error);
      } else setErrorMsg(errorString);
    },
  });

  const { mutate: registerMutation, isLoading: isRegisterLoading } =
    useRegister({
      onSuccess: () => {
        loginMutation(storeAuthParams);
      },
    });

  const { mutate: verifyReCaptchaMutation } = useVerifyReCaptcha({
    onSuccess: async () => {
      registerMutation(storeAuthParams);
    },
  });

  const { mutate: verifyEmailMutation, isLoading: isLoadingVerifyEmail } =
    useVerifyEmail({
      onSuccess: ({ has_app, has_fb_account }) => {
        if (!has_app && !has_fb_account) {
          return setRegisterState(RegisterState.RegisterForm);
        }
        if (has_fb_account && !has_app) {
          clearAllErrorMsg();
          setRecaptchaId('recaptcha-container-id');
          return setRegisterState(RegisterState.HasOtherBsAccount);
        }
        if (has_fb_account && has_app) {
          hasCFAccountAndHasBsAccountRef.current = true;
        }
        return setOpenUnifiedAlert(true);
      },
      onError: () => {
        navigate({
          pathname: location.pathname,
          search: omitFromQueryString(location.search, 'email'),
        });
      },
    });

  const reCaptchaRef = createRef();

  const registerValidationSchema = useMemo(() => {
    if (registerState === RegisterState.VerifyAccount) {
      return VerifyEmailSchema;
    }
    if (registerState === RegisterState.HasOtherBsAccount) {
      return RegisterWithOutReCaptchaSchema;
    }

    if (isOffRecaptchaValidate) return RegisterWithOutReCaptchaSchema;

    return RegisterSchema;
  }, [registerState, isOffRecaptchaValidate]);

  useEffect(() => {
    if (
      registerState === RegisterState.VerifyAccount &&
      searchQueries?.email &&
      !isLoadingVerifyEmail &&
      !hasCFAccountAndHasBsAccountRef.current
    ) {
      verifyEmailMutation({ email: searchQueries?.email });
    }
  }, [registerState, searchQueries, hasCFAccountAndHasBsAccountRef.current]);

  useEffect(() => {
    if (account) {
      navigate('/');
    }
  }, [account]);

  const handleSubmit = ({ email, password, captcha_token }, { setTouched }) => {
    setStoreAuthParams({
      email,
      password,
    });
    if (registerState === RegisterState.VerifyAccount) {
      setTouched({});
      return verifyEmailMutation({ email });
    }
    if (registerState === RegisterState.HasOtherBsAccount) {
      clearAllErrorMsg();
      return loginMutation({ email, password });
    }
    return verifyReCaptchaMutation(captcha_token);
  };

  const formComponent = ({ setFieldValue, errors, touched }) => {
    if (registerState === RegisterState.VerifyAccount) {
      return <StyledTextInputField name='email' legend='Email' />;
    }
    if (registerState === RegisterState.HasOtherBsAccount) {
      return (
        <>
          <Typography
            sx={{
              background: '#FDE9AB',
              p: 1,
              textAlign: 'center',
              fontSize: 16,
              fontFamily: 'Lato',
              borderRadius: '2px',
            }}
          >
            Breadstack Delivery is integrated with the Breadstack Account
            system. It looks like you’ve got a Breadstack Account already,
            please enter your password to continue.
          </Typography>
          <StyledTextInputField name='email' legend='Email' disabled />
          <Stack
            direction='row'
            alignItems='center'
            sx={{ position: 'relative' }}
          >
            <StyledTextInputField
              name='password'
              legend='Password'
              type='password'
              onChange={(e) => {
                clearAllErrorMsg();
                setFieldValue('password', e.target.value);
              }}
            />
            <Tooltip title={<TooltipPasswordContent />}>
              <img
                width={30}
                height={30}
                src={InfoIcon}
                alt='info'
                style={{
                  top: 35,
                  right: -10,
                  position: 'absolute',
                  marginRight: themes.spacing(-3),
                  marginLeft: themes.spacing(1),
                  [themes.breakpoints.down('md')]: {
                    display: 'none',
                  },
                }}
              />
            </Tooltip>
          </Stack>
          <Typography
            sx={{
              lineHeight: 1.66,
              color: '#F24822',
              mt: '10px !important',
              fontSize: 14,
            }}
          >
            {errorMsg || errorMsg2Fa}
          </Typography>
          <TextLink
            to='/reset-password'
            style={{ textAlign: 'start', textDecoration: 'none', fontSize: 16 }}
          >
            Forgot Password?
          </TextLink>
          <div id='recaptcha-container-id' />
          {enable2FA && (
            <DialogVerifyCode2FA open={enable2FA} onClose={handleCancel}>
              <VerifyCode2FA
                phone={phone}
                onComplete={handleVerifyCode}
                onClickReSend={handleSendCode}
                loading={isLoginLoading || loadingSendCode || loadingVerify}
                error={errorMsg2Fa}
                clearError={clearErrorMsg}
              />
            </DialogVerifyCode2FA>
          )}
        </>
      );
    }
    return (
      <>
        <StyledTextInputField
          name='email'
          legend='Email'
          disabled={!!searchQueries?.email}
        />
        <Stack
          direction='row'
          alignItems='center'
          sx={{ position: 'relative' }}
        >
          <StyledTextInputField
            name='password'
            legend='Password'
            type='password'
          />
          <Tooltip title={<TooltipPasswordContent />}>
            <img
              width={30}
              height={30}
              src={InfoIcon}
              alt='info'
              style={{
                top: 35,
                right: -10,
                position: 'absolute',
                marginRight: themes.spacing(-3),
                marginLeft: themes.spacing(1),
                [themes.breakpoints.down('md')]: {
                  display: 'none',
                },
              }}
            />
          </Tooltip>
        </Stack>
        <ReCAPTCHA
          ref={reCaptchaRef}
          sitekey={RECAPTCHA_KEY}
          onChange={(token) => setFieldValue('captcha_token', token)}
          style={{ marginTop: 20 }}
        />
        {!!errors.captcha_token && !!touched.captcha_token && (
          <FormHelperText error data-testid='captcha-input-error'>
            Please solve Captcha correctly
          </FormHelperText>
        )}
      </>
    );
  };

  return (
    <AuthLayout>
      <Formik
        initialValues={{
          email: searchQueries?.email || '',
          password: '',
          captcha_token: '',
        }}
        enableReinitialize
        validationSchema={registerValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, errors, touched }) => (
          <Form>
            <Stack
              spacing={2}
              sx={{
                label: {
                  fontSize: 16,
                  color: '#282E3A !important',
                },
              }}
            >
              <StyledLogoContainer pb={5} textAlign='center'>
                <img src={Logo} data-testid='logo' alt='logo' height={24} />
              </StyledLogoContainer>

              <StyledTitle>Create your free account</StyledTitle>
              {formComponent({ setFieldValue, errors, touched })}
              <Stack flexDirection='row' sx={{ marginTop: '40px !important' }}>
                {registerState === RegisterState.HasOtherBsAccount && (
                  <Button
                    fullWidth
                    buttonType='default'
                    sx={{ mr: 2, borderRadius: '10px !important' }}
                    onClick={() =>
                      setRegisterState(RegisterState.VerifyAccount)
                    }
                  >
                    Back
                  </Button>
                )}
                <StyledButton
                  fullWidth
                  type='submit'
                  loading={
                    isRegisterLoading ||
                    isLoginLoading ||
                    isLoadingVerifyEmail ||
                    (!enable2FA && loadingSendCode)
                  }
                >
                  Next
                </StyledButton>
              </Stack>
            </Stack>
            {openUnifiedAlert && (
              <UnifiedAlert
                open={openUnifiedAlert}
                onClose={() => setOpenUnifiedAlert(false)}
              />
            )}
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};
