import { Skeleton, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import ChartContainer from '~/components/common/Chart/ChartContainer';
import Table from '~/components/common/Table';
import { TColumn } from '~/components/common/Table/Table';
import { Icon } from '~/components/common/Icon';
import Button from '~/components/common/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DriverTableInfo } from '~/services/api/dashboard';
import { DriverAvatar } from '~/components/shared/AssignTask/DriverAvatar';
import { random } from 'lodash';
import { useIsFetching } from '@tanstack/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import { Progress } from './Progress';

type DriverTableProps = {
  data: DriverTableInfo[];
  exporting?: boolean;
};

export const DriverTable: React.FC<DriverTableProps> = ({
  data,
  exporting = false,
}) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const fetchingDashboard = useIsFetching([queryKeys.dashboard.getDashboard]);
  const isFetchingDashboard = fetchingDashboard === 1;
  const isEmptyDriverData = data.length === 0;

  const driverTableData = useMemo(() => {
    if (isFetchingDashboard) {
      return [];
    }
    if (isEmptyDriverData)
      return Array(5)
        .fill('')
        .map(() => ({
          id: (Math.random() + 1).toString(36).substring(7),
          full_name: '',
          completed_task: 0,
          driver_avg_rate: 0,
          driver_avg_on_time: 0,
          driver_ulitization_rate: 0,
          total_distance: 0,
        }));
    if (!isShowMore && !exporting) {
      return data.slice(0, 5);
    }
    return data;
  }, [data, isShowMore, isFetchingDashboard, isEmptyDriverData]);

  const columns: TColumn[] = useMemo(
    () => [
      {
        id: 'full_name',
        title: 'Driver',
        cellStyle: {
          minWidth: 200,
        },
        renderComponent: ({ full_name, avatar }) => (
          <Stack direction='row' spacing={1} alignItems='center'>
            <DriverAvatar size={20} avatar={avatar} name={full_name} />
            {isEmptyDriverData ? (
              <Skeleton width={random(60, 140)} animation={false} />
            ) : (
              <Typography fontSize={14}>{full_name}</Typography>
            )}
          </Stack>
        ),
      },
      {
        id: 'driver_avg_rate',
        title: 'Average rating',
        renderComponent: ({ driver_avg_rate }) => (
          <Stack direction='row' spacing={0.8} alignItems='center'>
            <Typography fontSize={14}>
              {Math.round(driver_avg_rate * 10) / 10}
            </Typography>
            <Icon name='star' size={16} useBackgroundImg />
          </Stack>
        ),
      },
      {
        id: 'completed_task',
        title: 'Task completed',
        renderComponent: ({ completed_task }) => (
          <Typography fontSize={14}>{completed_task}</Typography>
        ),
      },
      {
        id: 'driver_avg_on_time',
        title: 'On time rate',
        cellStyle: { minWidth: 120 },
        renderComponent: ({ driver_avg_on_time }) => (
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography fontSize={14}>
              {Math.round(driver_avg_on_time * 10) / 10}%
            </Typography>
            {isEmptyDriverData ? (
              <Progress value={80} isEmpty thickness={8} />
            ) : (
              <Progress value={driver_avg_on_time} thickness={8} />
            )}
          </Stack>
        ),
      },
      {
        id: 'driver_ulitization_rate',
        title: 'Utilization rate',
        renderComponent: ({ driver_ulitization_rate }) => (
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography fontSize={14}>{driver_ulitization_rate}%</Typography>
            {isEmptyDriverData ? (
              <Progress value={80} isEmpty thickness={8} />
            ) : (
              <Progress value={driver_ulitization_rate} thickness={8} />
            )}
          </Stack>
        ),
      },
      {
        id: 'total_distance',
        title: 'Total distance',
        renderComponent: ({ total_distance }) => (
          <Typography fontSize={14}>{total_distance} km</Typography>
        ),
      },
    ],
    [isEmptyDriverData],
  );

  const showMoreButton = useMemo(() => {
    if (data.length <= 5 || exporting) {
      return '';
    }
    return (
      <Button
        buttonType='text'
        disableRipple
        onClick={() => {
          setIsShowMore((v) => !v);
        }}
      >
        <Typography
          sx={{
            color: '#2D41E8',
            fontSize: 12,
          }}
        >
          {isShowMore ? 'Show Less' : 'Show More'}
        </Typography>
        {isShowMore ? (
          <KeyboardArrowUpIcon
            sx={{
              color: '#2D41E8',
            }}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={{
              color: '#2D41E8',
            }}
          />
        )}
      </Button>
    );
  }, [isShowMore, data, exporting]);

  return (
    <ChartContainer
      title='Driver performance'
      sxContainer={{ height: 'unset' }}
    >
      <Table
        columns={columns}
        striped={false}
        dataProps={driverTableData}
        headerCellSxProps={{
          color: 'rgba(51, 54, 61, 0.4)',
          fontSize: 12,
          borderWidth: 0,
          pl: '10px !important',
        }}
        cellSxProps={{
          padding: '10px !important',
          color: '#33363D',
          border: 'none !important',
        }}
        containerSxProps={{
          '& table': {
            '& .MuiTableRow-root': {
              border: 'none !important',
            },
          },
        }}
        noHoverInteraction
      />
      {showMoreButton}
    </ChartContainer>
  );
};
