import { colors, bgColors } from '~/themes';

export const tags: {
  [key: string]: {
    label: string;
    color: string;
    bgColor: string;
  };
} = {
  unassigned: {
    label: 'Unassigned',
    color: colors.pink,
    bgColor: bgColors.lightPink500,
  },
  delivered: {
    label: 'Delivered',
    color: bgColors.lightGreen,
    bgColor: colors.green,
  },
  returned: {
    label: 'Returned',
    bgColor: 'rgba(184, 73, 160, 0.10)',
    color: '#9B57D1',
  },
  cancelled: {
    label: 'Cancelled',
    bgColor: 'rgba(51, 54, 61, 0.05)',
    color: 'rgba(51, 54, 61, 0.40)',
  },
  pending_pickup: {
    label: 'Pickup',
    color: '#F7982A',
    bgColor: 'rgba(254, 116, 0, 0.10)',
  },
  finding_driver: {
    label: 'Finding Driver',
    color: 'white',
    bgColor: bgColors.red,
  },
  pre_assigned: {
    label: 'Pre-assigned',
    color: 'white',
    bgColor: bgColors.breadstack,
  },
  returning: {
    label: 'Return',
    color: '#9B57D1',
    bgColor: 'rgba(184, 73, 160, 0.10)',
  },
  delivering: {
    label: 'Dropoff',
    color: '#53C8A7',
    bgColor: 'rgba(27, 196, 125, 0.10)',
  },
  tier_3: {
    label: 'Same Day',
    color: colors.gray900,
    bgColor: bgColors.midPurple,
  },
  tier_2: {
    label: '3 Hours',
    color: 'white',
    bgColor: bgColors.darkPurple,
  },
  tier_1: {
    label: 'Priority',
    color: 'white',
    bgColor: bgColors.darkPurple,
  },
  android: {
    label: 'Android App',
    color: colors.gray900,
    bgColor: colors.gray300,
  },
  ios: {
    label: 'iOS App',
    color: colors.gray900,
    bgColor: colors.gray300,
  },
  new: {
    label: 'New',
    bgColor: colors.gray900,
    color: colors.gray300,
  },
  admin: {
    label: 'Created by Admin',
    bgColor: colors.gray300,
    color: colors.gray900,
  },
  unpaid: {
    label: 'Unpaid',
    bgColor: colors.red,
    color: colors.lightRed,
  },
  completed: {
    label: 'Completed',
    bgColor: 'rgba(27, 196, 125, 0.10)',
    color: '#53C8A7',
  },
  failed: {
    label: 'Failed',
    bgColor: colors.gray900,
    color: colors.gray300,
  },
  picked_up: {
    label: 'In Transit', // which means picked up
    color: bgColors.midPurple,
    bgColor: colors.violet900,
  },
  pending: {
    label: 'Pending',
    bgColor: colors.yellow,
    color: bgColors.lightYellow,
  },
  waybillScanned: {
    label: 'Waybill Scanned',
    bgColor: bgColors.lightGreen,
    color: colors.green,
  },
  failedToDeliver: {
    label: 'Failed to Deliver',
    bgColor: bgColors.lightRed,
    color: colors.red,
  },
};
