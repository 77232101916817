/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box } from '@mui/system';
import StarIcon from '~/assets/images/icons/star.svg';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';
import { styled } from '@mui/styles';
import { Theme } from '~/themes';
import { Grid, Skeleton, Typography } from '@mui/material';
import UpTrendIcon from '~/assets/images/icons/up-trend.svg';
import DownTrendIcon from '~/assets/images/icons/down-trend.svg';
import ChartContainer from '../ChartContainer';

export interface IRatingItem {
  feedback: string;
  date: string;
  code: string;
  id: string;
  rating: number;
  searchQuery: string;
}

interface IRating {
  averageRating: number;
  lowReviews: IRatingItem[];
  highReviews: IRatingItem[];
  isLoading: boolean;
  engagementRating: number;
  engagementRatingTrend: number;
  scrollable?: boolean;
}

export const StyledTitle = styled(Box)(() => ({
  display: 'flex',
  fontSize: 14,
  color: '#98A2B2',
}));

export const StyledRating = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 220,
  height: '100%',
  gap: 32,
  [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
    width: '100%',
    height: 'unset',
    padding: theme.spacing(2),
  },
}));

export const StyledReviewItem = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0.8),
  gap: theme.spacing(0.5),
  backgroundColor: '#F4F5F9',
  borderRadius: theme.spacing(0.4),
  flex: 1,
}));

export const StyledList = styled(Box)(({ theme }: { theme: Theme }) => ({
  overflowX: 'auto',
  maxHeight: 204,
  [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
    maxHeight: 160,
  },
}));

const Rating: React.FC<IRating> = ({
  averageRating,
  lowReviews,
  highReviews,
  isLoading,
  engagementRating,
  engagementRatingTrend,
  scrollable = true,
}) => {
  const renderItem = (list: IRatingItem[]) =>
    list.map((item) => (
      <StyledReviewItem key={item.id}>
        {item.feedback && (
          <Typography
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              color: '#33363D',
              fontSize: 14,
            }}
          >
            {item.feedback}
          </Typography>
        )}
        <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
          {item.rating}
          <img
            alt={`star-${item.code}`}
            src={StarIcon}
            style={{ marginLeft: -5, height: 16 }}
          />
        </Box>
      </StyledReviewItem>
    ));

  const renderLoadingItem = (list: number[]) =>
    list.map((item) => (
      <StyledReviewItem key={(Math.random() + 1).toString(36).substring(7)}>
        {Array(item)
          .fill(0)
          .map((_, idx) => (
            <Skeleton
              key={(Math.random() + 1).toString(36).substring(7)}
              width={idx === 0 ? '100%' : '60%'}
              animation={false}
            />
          ))}
        <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
          <Skeleton width={86} animation={false} />
          <img
            alt='star'
            src={StarIcon}
            style={{ marginLeft: 'auto', marginRight: -5, height: 13 }}
          />
          0
        </Box>
      </StyledReviewItem>
    ));

  return (
    <ChartContainer
      sxContent={{
        gap: 1,
        alignItems: 'start',
      }}
      sxContainer={{
        gap: 1.6,
        ...(scrollable
          ? {}
          : {
              height: 'unset !important',
            }),
      }}
      title='Customer satisfaction'
    >
      <StyledRating>
        <Box flexDirection='column' gap={0.4}>
          <StyledTitle mb={1}>Average rating</StyledTitle>
          <Box display='flex' flexDirection='row' alignItems='center' gap={0.8}>
            <Box fontSize={24} fontWeight='500' color='#26292E'>
              {averageRating}
            </Box>
            <img src={StarIcon} height={24} alt='avg-star' />
          </Box>
        </Box>
        <Box flexDirection='column' gap={0.4}>
          <StyledTitle
            mb={1}
            sx={{
              whiteSpace: 'pre-line',
            }}
          >{`Delivery tracking\n engagement rate`}</StyledTitle>
          <Box display='flex' flexDirection='row' alignItems='center' gap={0.8}>
            <Box fontSize={24} fontWeight='500' color='#26292E'>
              {engagementRating}%
            </Box>
            <Box
              fontSize={14}
              color={engagementRatingTrend < 0 ? '#F24822' : '#48BB78'}
              fontWeight={500}
              display='flex'
              flexDirection='row'
              gap={0.8}
              alignItems='center'
              justifyContent='center'
            >
              <img
                src={engagementRatingTrend < 0 ? DownTrendIcon : UpTrendIcon}
                alt=''
                width={24}
              />
              {Math.abs(engagementRatingTrend)}%
            </Box>
          </Box>
        </Box>
      </StyledRating>
      <Grid
        container
        columnSpacing={1.8}
        flex={1}
        direction='row'
        alignItems='start'
        height='100%'
      >
        <Grid item xs={6}>
          <Box display='flex' flexDirection='column'>
            <StyledTitle mb={0.8}>High reviews</StyledTitle>
            {isLoading ? (
              <StyledList className='customized-scrollbar'>
                <Grid container spacing={0.8}>
                  <Grid item xs={12}>
                    <Box display='flex' flexDirection='column' gap={1}>
                      {renderLoadingItem([1, 2, 1])}
                    </Box>
                  </Grid>
                </Grid>
              </StyledList>
            ) : highReviews?.length ? (
              <StyledList
                className='customized-scrollbar'
                sx={{
                  ...(!scrollable
                    ? {
                        maxHeight: 'unset !important',
                      }
                    : {}),
                }}
              >
                <Grid container spacing={0.8}>
                  <Grid item xs={12}>
                    <Box display='flex' flexDirection='column' gap={0.8}>
                      {renderItem(highReviews)}
                    </Box>
                  </Grid>
                </Grid>
              </StyledList>
            ) : (
              'No data'
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display='flex' flexDirection='column'>
            <StyledTitle mb={0.8}>Low reviews</StyledTitle>
            {isLoading ? (
              <StyledList className='customized-scrollbar'>
                <Grid container spacing={0.8}>
                  <Grid item xs={12}>
                    <Box display='flex' flexDirection='column' gap={1}>
                      {renderLoadingItem([1, 2, 1])}
                    </Box>
                  </Grid>
                </Grid>
              </StyledList>
            ) : lowReviews?.length ? (
              <StyledList className='customized-scrollbar'>
                <Grid container spacing={0.8}>
                  <Grid item xs={12}>
                    <Box display='flex' flexDirection='column' gap={0.8}>
                      {renderItem(lowReviews)}
                    </Box>
                  </Grid>
                </Grid>
              </StyledList>
            ) : (
              'No data'
            )}
          </Box>
        </Grid>
      </Grid>
    </ChartContainer>
  );
};

export default Rating;
