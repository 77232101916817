import React from 'react';
import { DonutChart } from 'analytics-library';
import { Box, SxProps } from '@mui/system';
import InfoIcon from '~/assets/images/icons/info.svg';
import { makeStyles } from '@mui/styles';
import { Theme } from '~/themes';
import Tooltip from '../../Tooltip';
import { IPieLegendItem } from '../PieLegend/PieLegend';
import PieLegend from '../PieLegend';
import ChartContainer from '../ChartContainer';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(97, 97, 97, 0.8)',
    width: 250,
  },
}));

const loadingColors = ['#E5E5E5', '#E5E5E5'];

export interface IDonutItem {
  value: number;
  quantity: number;
  type: string;
  convertedValue: string;
  externalLink?: string;
}

interface IDonut {
  title: string;
  colors: string[];
  items: IDonutItem[];
  bigTitle?: string;
  bigDescription?: string;
  titleSx?: SxProps<Theme>;
  tooltip?: string;
  customHtml?: () => string;
  isLoading: boolean;
  formatter?: any;
  subTitleExport?: string;
}

const Donut: React.FC<IDonut> = ({
  title,
  colors,
  items,
  bigTitle,
  bigDescription,
  titleSx,
  tooltip,
  customHtml,
  isLoading,
  formatter,
  subTitleExport,
}) => {
  const total = items[0].quantity + items[1].quantity;
  const percentage = !total
    ? '0%'
    : `${Math.round((items[0].quantity * 100) / total)}%`;

  const legendColors = [...colors];
  const legendData: IPieLegendItem[] = [...items].map((item, idx) => ({
    color: legendColors[idx],
    title: isLoading
      ? '0'
      : `${item.convertedValue} | ${Math.round(
          (item.quantity * 100) / total,
        )}%`,
    description: item.type,
    titleSx: titleSx || {},
    // externalLink: item.externalLink,
  }));

  const classes = useStyles();

  return (
    <ChartContainer title={title} sxContent={{ gap: 3 }}>
      <Box position='relative'>
        <DonutChart
          data={items}
          noData={false}
          angleField='value'
          colorField='type'
          color={isLoading ? loadingColors : colors}
          innerRadius={0.75}
          height={180}
          width={180}
          {...(formatter
            ? {
                tooltip: {
                  formatter,
                  domStyles: {
                    'g2-tooltip': {
                      borderRadius: '2px',
                      boxShadow: '0px 3px 6px #00000029',
                      background: '#33363D',
                    },
                    'g2-tooltip-list': {
                      color: '#33363D',
                    },
                    'g2-tooltip-list-item': {
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2px',
                    },
                    'g2-tooltip-marker': {
                      marginRight: '0px',
                    },
                    'g2-tooltip-value': {
                      fontWeight: 500,
                      color: '#FFF',
                      marginLeft: 0,
                      float: 'unset',
                      fontSize: '14px',
                    },
                  },
                },
              }
            : { tooltip: false })}
          interactions={[{ type: 'element-active' }]}
          state={{
            active: {
              style: {
                lineWidth: 0.5,
                stroke: '#33363D',
              },
            },
          }}
          pieStyle={{
            lineWidth: 0,
          }}
          statistic={{
            title: {
              offsetY: -5,
              style: {
                fontWeight: '400',
                color: '#9B9B9B',
                fontSize: '14px',
              },
              ...(subTitleExport
                ? {
                    content: subTitleExport,
                  }
                : { customHtml }),
            },
            content: {
              offsetY: 5,
              style: {
                color: '#000000',
                fontWeight: 'bold',
                fontSize: '20px',
              },
              content: percentage,
              // customHtml: () => `<div>${percentage}</div>`,
            },
          }}
        />
        {tooltip && (
          <Box
            position='absolute'
            right='50%'
            bottom={35}
            sx={{
              transform: 'translateX(50%)',
            }}
          >
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
              }}
              placement='bottom-start'
              title={tooltip}
            >
              <img width={13} height={13} src={InfoIcon} alt='info' />
            </Tooltip>
          </Box>
        )}
      </Box>
      <PieLegend
        items={legendData}
        bigTitle={bigTitle}
        bigDescription={bigDescription}
      />
    </ChartContainer>
  );
};

export default Donut;
