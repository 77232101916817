import React, { useCallback } from 'react';
import { HeatmapChart } from 'analytics-library';
import { Box, Stack } from '@mui/system';
import moment from 'moment-timezone';
import ChartContainer from '../ChartContainer';

const colors = [
  '#F4F5F9',
  '#1123D3',
  'rgba(41, 67, 212, 0.4)',
  'rgba(41, 67, 212, 0.2)',
];

export interface ITimeSlot {
  weekDay: string;
  timeSlot: string;
  task: number;
}

interface ITimeSlots {
  isLoading: boolean;
  items: ITimeSlot[];
}

const TimeSlots: React.FC<ITimeSlots> = ({ isLoading, items }) => {
  // const maxValue = Math.max(...items.map((d) => d.task));

  const lowThreshold = 10;
  const highThreshold = 20;

  // if (maxValue > 4) {
  //   lowThreshold =
  //     Math.floor(maxValue / 3) === 1 ? 2 : Math.floor(maxValue / 3);
  //   highThreshold = Math.floor((maxValue * 2) / 3);
  // }

  const taskLegend = (
    <Stack direction='row' gap={2} ml='auto'>
      <Stack direction='row' gap={0.5} alignItems='center'>
        <Box
          width={12}
          height={12}
          borderRadius={0.5}
          sx={{ backgroundColor: colors[1] }}
        />
        <Box fontSize={12} fontWeight='normal' color='#9B9B9B' flex={1}>
          {` > ${highThreshold} tasks`}
        </Box>
      </Stack>
      <Stack direction='row' gap={0.5} alignItems='center'>
        <Box
          width={12}
          height={12}
          borderRadius={0.5}
          sx={{ backgroundColor: colors[2] }}
        />
        <Box fontSize={12} fontWeight='normal' color='#9B9B9B' flex={1}>
          {` ${lowThreshold}-${highThreshold} tasks`}
        </Box>
      </Stack>
      <Stack direction='row' gap={0.5} alignItems='center'>
        <Box
          width={12}
          height={12}
          borderRadius={0.5}
          sx={{ backgroundColor: colors[3] }}
        />
        <Box fontSize={12} fontWeight='normal' color='#9B9B9B' flex={1}>
          {` < ${lowThreshold} tasks`}
        </Box>
      </Stack>
    </Stack>
  );

  const charConfig: any = {
    height: 250,
    tooltip: {
      formatter: (data) => ({
        name: ``,
        value: data?.task,
        title: '',
      }),
      domStyles: {
        'g2-tooltip': {
          borderRadius: '2px',
          boxShadow: '0px 3px 6px #00000029',
          background: '#33363D',
        },
        'g2-tooltip-list': {
          color: '#33363D',
        },
        'g2-tooltip-list-item': {
          display: 'flex',
          alignItems: 'center',
          gap: '2px',
        },
        'g2-tooltip-marker': {
          width: '10px',
          height: '10px',
          borderRadius: '0px',
          marginRight: '0px',
          border: '1px solid rgba(255, 255, 255, 1)',
        },
        'g2-tooltip-value': {
          fontWeight: 500,
          color: '#FFF',
          marginLeft: 0,
          float: 'unset',
          fontSize: '14px',
        },
      },
    },
    xField: 'timeSlot',
    yField: 'weekDay',
    colorField: 'task',
    xAxis: {
      position: 'top',
      tickLine: null,
      line: null,
      grid: null,
      label: {
        offset: 15,
        style: {
          fontSize: 12,
          fill: '#9EA6B5',
        },
        formatter: (val) => `${moment(val, 'HH:mm').format('h a')}`,
      },
    },
    yAxis: {
      position: 'left',
      tickLine: null,
      line: null,
      grid: null,
      label: {
        offset: 40,
        style: {
          fontSize: 12,
          fill: '#9EA6B5',
          textAlign: 'start',
        },
        formatter: (val) => `${val?.slice(0, 3)}`,
      },
    },
  };

  const setColor = useCallback(
    ({ task }) => {
      if (isLoading) return '#E5E5E5';
      if (task === 0) return colors[0];
      if (task < lowThreshold) return colors[3];
      if (task <= highThreshold) return colors[2];
      return colors[1];
    },
    [items],
  );

  return (
    <ChartContainer
      title='Task volume by time slot'
      sxContainer={{ gap: 1.6, height: 'auto' }}
      sxContent={{ maxHeight: 270, minHeight: 270 }}
      titleDescription={taskLegend}
    >
      <Box width='100%' p={0.8}>
        <HeatmapChart
          data={items}
          {...{
            ...charConfig,
            color: setColor,
          }}
        />
      </Box>
    </ChartContainer>
  );
};

export default TimeSlots;
