import { Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FastField, Field } from '~/components/common/Formik';
import { Formik } from 'formik';
import { StoreURLSchema, WooStoreSchema } from '~/utils/schema/stores';
import {
  ConnectionStoreStatus,
  CreateStoreStatus,
  IntegrationType,
} from '~/models/stores';
import { CountryCode, parsePhoneNumberWithError } from 'libphonenumber-js';
import { useCheckConnection } from '~/services/api/stores';
import { TextLink } from '~/components/common/TextLink';
import { CanFleetPluginURL } from '~/constants/stores';
import ExportIcon from '~/assets/images/icons/export.svg';
import PlugIcon from '~/assets/images/icons/plug-fill.svg';
import themes from '~/themes';
import { StyledStoreForm } from '../style';
import { StoreAddressForm } from './StoreAddressForm';
import { StoreConnection } from './StoreConnection';
import { AddStoreLayout } from '../layout/AddStoreLayout';

type WooCommerceStoreProps = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setPlatform: (p: IntegrationType) => void;
  onClose: () => void;
  createIntegrationMutation: any;
  isLoadingCreateIntegration: boolean;
};

export const WooCommerceStore: React.FC<WooCommerceStoreProps> = ({
  step,
  onClose,
  setStep,
  setPlatform,
  createIntegrationMutation,
  isLoadingCreateIntegration,
}) => {
  const formRef = useRef(null);
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStoreStatus>(null);

  const {
    mutate: checkConnectionMutation,
    // isLoading: isLoadingCheckConnection,
  } = useCheckConnection({
    onSuccess: (resp) => {
      if (resp?.connected) {
        setConnectionStatus(ConnectionStoreStatus.Connected);
      } else {
        setConnectionStatus(ConnectionStoreStatus.NotConnected);
      }
    },
    onError: () => setConnectionStatus(ConnectionStoreStatus.NotConnected),
  });

  const handleSubmitAddStore = useCallback(
    (values) => {
      if (step === 2) {
        setStep((s) => s + 1);
      } else {
        values.status =
          connectionStatus === ConnectionStoreStatus.Connected
            ? CreateStoreStatus?.Active
            : CreateStoreStatus?.Deactivated;
        createIntegrationMutation({
          integration_type: IntegrationType.Woo,
          store_url: values?.store_url,
          status: values.status,
          display_name: values?.display_name,
          address_1: values?.address_1,
          address_2: values?.address_2,
          country: values?.country,
          state: values?.state,
          city: values?.city,
          postcode: values?.postcode,
          phone: parsePhoneNumberWithError(
            values.phone,
            values.country_code as CountryCode,
          ).number,
        });
      }
    },
    [step, connectionStatus],
  );

  const validationSchema = useMemo(() => {
    if (step === 2) {
      return WooStoreSchema;
    }
    if (step === 3) {
      return StoreURLSchema;
    }
    return null;
  }, [step]);

  const contentComponent = (values) => {
    if (step === 2) {
      return (
        <Stack spacing={2} width='100%'>
          <Field.TextInput legend='Store Name' name='display_name' />
          <Field.TextInput
            legend='Phone Number'
            name='phone'
            type='tel'
            prefix={<FastField.CountryCodeDropdown name='country_code' />}
          />
          <Stack width='100%'>
            <StoreAddressForm />
          </Stack>
        </Stack>
      );
    }

    if (step === 3) {
      return (
        <>
          <Stack
            p={1.6}
            sx={{
              'backgroundColor': '#F4F5F9',
              'borderRadius': '8px',
              '& .MuiInputBase-colorPrimary': {
                backgroundColor: 'white !important',
              },
            }}
          >
            <Stack
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              gap={1}
              mb={0.8}
              flexWrap='wrap-reverse'
            >
              <Typography fontWeight={700} color={themes.color.violet500}>
                {values?.display_name}
              </Typography>
              <StoreConnection status={connectionStatus} />
            </Stack>
            <Field.TextInput name='store_url' legend='Store URL' />
          </Stack>
          <Stack mt={2.4} gap={0.5}>
            <Typography color='#717D96'>Set up the store connection</Typography>
            <Typography
              color='#717D96'
              sx={{
                width: '100%',
                ml: 1,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '6px',
              }}
            >
              1. Install the plugin{' '}
              <TextLink
                style={{
                  fontWeight: '500',
                  textDecoration: 'none',
                  color: '#33363D',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '4px',
                }}
                to={CanFleetPluginURL.Woo}
              >
                <img src={ExportIcon} alt='download-icon' />
                Breadstack Delivery Plugin
              </TextLink>
            </Typography>
            <Typography
              sx={{
                width: '100%',
                ml: 0.8,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '6px',
              }}
              color='#717D96'
            >
              2. After the plugin is installed{' '}
              <Typography
                sx={{
                  fontWeight: '500',
                  color: '#33363D',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: '1px',
                }}
                onClick={() => {
                  setConnectionStatus(ConnectionStoreStatus.Checking);
                  checkConnectionMutation({
                    integration_type: IntegrationType.Woo,
                    store_url: values?.store_url,
                    store_id: values?.store_id,
                  });
                }}
              >
                <img src={PlugIcon} alt='check-icon' />
                Check Connection
              </Typography>
            </Typography>
          </Stack>
        </>
      );
    }
    return '';
  };

  return (
    <Formik
      initialValues={{
        store_id: '',
        store_url: '',
        status: CreateStoreStatus?.Deactivated,
        integration_type: IntegrationType.Breadstack,
        display_name: '',
        phone: '',
        address_1: '',
        address_2: '',
        country: '',
        city: '',
        state: '',
        postcode: '',
        country_code: 'CA',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmitAddStore}
      innerRef={formRef}
    >
      {({ values }) => (
        <AddStoreLayout
          storeType={IntegrationType.Woo}
          onBack={() => {
            setStep((s) => {
              if (s === 2) {
                setPlatform(null);
              }
              return s - 1;
            });
          }}
          onNext={() => {
            formRef.current.submitForm();
          }}
          onClose={onClose}
          step={step}
          loading={isLoadingCreateIntegration}
        >
          <StyledStoreForm>{contentComponent(values)}</StyledStoreForm>
        </AddStoreLayout>
      )}
    </Formik>
  );
};
