/* eslint-disable react/no-array-index-key */
import { Stack, Typography } from '@mui/material';
import { IntegrationType } from '~/models/stores';
import themes from '~/themes';

type StepperProps = {
  activeStep?: number;
  totalStep?: number;
  storeType?: string;
};

export const Stepper: React.FC<StepperProps> = ({
  activeStep = 1,
  totalStep = 3,
  storeType = IntegrationType.Breadstack,
}) => {
  const renderDescription = () => {
    switch (activeStep) {
      case 1: {
        return 'Select the platform you’re using for your online store.';
      }
      case 2:
        return storeType === IntegrationType.Breadstack
          ? 'Select the store you want to add.'
          : 'Enter the store information.';
      case 3:
        return storeType === IntegrationType.Breadstack
          ? 'You can set up store connection following the instruction below. \nOr continue to add store and set up the connection later.'
          : 'Enter your store URL and set up connection following the instruction below. Or continue to add store and set up the connection later.';

      default:
        return '';
    }
  };

  return (
    <Stack>
      <Typography
        sx={{
          fontWeight: 700,
          color: themes.color.violet500,
        }}
      >
        Step {activeStep} of {totalStep}
      </Typography>
      <Typography
        sx={{
          color: '#717D96',
        }}
      >
        {renderDescription()}
      </Typography>
    </Stack>
  );
};
