import { createStyles } from '@mui/styles';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';
import { Theme } from '~/themes';

const style = (theme: Theme) =>
  createStyles({
    textCenter: {
      color: 'red',
    },
    loadingContainer: {
      position: 'sticky',
      height: 0,
      width: '100%',
      top: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9,
      background: 'transparent',
    },
    loadingContent: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9,
      background: 'rgba(256, 256, 256, 0.7)',
    },
    tableContainer: {
      boxSizing: 'border-box',
      position: 'relative',
      height: '100%',
    },
    table: {},
    tableRow: {
      'borderBottom': '1px solid #E9E7F6',
      '&:hover': {
        'background': `${theme.bg.midPurple} !important`,
        '&.noHoverInteraction': {
          background: 'transparent !important',
        },
        '&.striped': {
          background: 'transparent !important',
        },
      },
      '&.clickable': {
        cursor: 'pointer',
      },
      '&.selected': {
        background: `${theme.bg.midPurple} !important`,
      },
      '&.selected &:hover': {
        background: `${theme.bg.midPurple} !important`,
      },
      '&.noBorder': {
        borderBottom: 'none !important',
      },

      '&.striped': {
        borderBottom: 'none !important',
      },
      '&:nth-child(even):hover': {
        '&.striped': {
          background: `${theme.bg.lightPurple} !important`,
        },
      },
      '&.striped > &:nth-child(even)': {
        background: `${theme.bg.lightPurple} !important`,
      },
    },
    tableCell: {
      padding: theme.spacing(1.5),
      position: 'relative',
    },
    tableHeadCell: {
      'whiteSpace': 'nowrap',
      'padding': theme.spacing(1.5),
      '&.noBorder': {
        borderBottom: 'none !important',
      },
    },
    avatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    customHeader: {
      '& .MuiTableHead-root > tr:first-child': {
        backgroundColor: theme.bg.white,
      },
      '& $tableHeadCell': {
        'padding': '6px 15px',
        'borderBottom': 'none',
        'backgroundColor': theme.bg.lightPurple,
        '&:first-child': {
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
        },
        '&:last-child': {
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
        },
      },
    },
    striped: {
      '& .MuiTableBody-root > :nth-child(even)': {
        backgroundColor: theme.bg.lightPurple,
      },
      '& $tableCell': {
        borderBottom: 'none',
      },
    },
    sortLabel: {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      lineHeight: '1.5rem',
    },
    sortIcon: {
      transition: 'all .2s ease-in-out',
      fontSize: 24,
    },
    sortableIcon: {
      'display': 'block',
      'width': 18,
      'height': 24,
      'position': 'relative',
      '&:before': {
        content: '""',
        width: 0,
        height: 0,
        borderBottom: '5px solid',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        color: theme.color.violet600,

        position: 'absolute',
        top: 'calc(50% - 7px)',
        right: 0,
      },
      '&:after': {
        content: '""',
        width: 0,
        height: 0,
        borderTop: '5px solid',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        color: theme.color.violet600,
        position: 'absolute',
        top: 'calc(50% + 1px)',
        right: 0,
      },
    },
    sortIconDesc: {
      transform: 'rotate(180deg)',
    },
    noData: {
      padding: theme.spacing(6, 0),
      textAlign: 'center',
      color: theme.color.violet600,
      borderBottom: 'none',
    },
    moreItem: {
      cursor: 'pointer',
      color: theme.color.violet900,
      width: 24,
    },
    expandRowPointer: {
      'cursor': 'pointer',
      '&.selected': {
        '& td': {
          borderBottom: 'none',
        },
      },
    },
    noBorder: {
      borderBottom: 'none !important',
    },
    [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
      tableHeadCell: {
        padding: 8,
      },
      tableCell: {
        padding: 8,
      },
    },
  });

export default style;
