import { Box, Stack, Typography } from '@mui/material';
import { ITrackData, TaskStatus } from '~/models/track';
import { styled } from '~/themes';
import moment, { CalendarSpec, Moment } from 'moment';
import { useMemo } from 'react';

type TrackVerticalProgressProps = {
  value?: number;
  data: ITrackData;
};

export const StyledLine = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: 2,
  backgroundColor: theme.bg.gray600,
  top: 7,
  left: 3,
  zIndex: -1,
}));

export const StyledTrackItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'checked',
})<{ checked: boolean }>(({ checked }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 18,
  ...(checked && {
    '& > div': {
      backgroundColor: `#121142 !important`,
      border: 'none',
    },
  }),
}));

export const StyledTrackIcon = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  width: theme.spacing(0.8),
  height: theme.spacing(0.8),
  borderRadius: '100%',
  marginRight: theme.spacing(1),
  backgroundColor: '#BEBEBE',
}));

export const StyledTrackTitle = styled(Typography)<{ active: boolean }>(
  ({ theme, active }) => ({
    color: 'rgba(51, 54, 61, 0.40)',
    fontWeight: 500,
    fontSize: 14,
    ...(active && {
      color: theme.color.violet500,
    }),
  }),
);

export const formatDateTimeLocal = (
  date?: string | Date | Moment,
  formats?: CalendarSpec,
) => {
  if (!date) return 'ASAP';
  const formatBeforeDate = moment(
    `${moment(date).format('YYYY-MM-DDTHH:mm:ss+00:00')}`,
  ).local();
  const current = moment()?.local();
  const isSameYear = formatBeforeDate.isSame(current, 'year');
  const formatDate = isSameYear ? 'MM/DD' : 'MM/DD/YYYY';

  return moment(`${moment(date).format('YYYY-MM-DDTHH:mm:ss+00:00')}`)
    .local()
    .calendar(null, {
      sameDay: 'h:mm a [| today]',
      nextDay: 'h:mm a [| tomorrow]',
      lastDay: 'h:mm a [| yesterday]',
      nextWeek: `h:mm a | ${formatDate}`,
      lastWeek: `h:mm a | ${formatDate}`,
      sameElse: `h:mm a | ${formatDate}`,
      ...formats,
    });
};

export const TrackVerticalProgress: React.FC<TrackVerticalProgressProps> = ({
  value,
  data,
}) => {
  const taskStatus = data.status;

  const progressList = useMemo(() => {
    if (taskStatus === TaskStatus.Cancelled)
      return [
        {
          label: 'Order Received',
          value: 0.25,
        },
        {
          label: 'Cancelled',
          value: 1,
        },
      ];
    return [
      {
        label: 'Order Received',
        value: 0.25,
      },
      {
        label: 'In Transit',
        value: 0.5,
      },
      {
        label: 'Out for Delivery',
        value: 0.75,
      },
      {
        label: taskStatus === TaskStatus.Returned ? 'Returned' : 'Delivered',
        value: 1,
      },
    ];
  }, [data]);

  const getTrackTime = (val) => {
    switch (val) {
      case 0.25:
        return value >= 0.25 ||
          taskStatus === TaskStatus.Cancelled ||
          taskStatus === TaskStatus.Returned
          ? formatDateTimeLocal(data?.created_at)
          : '';
      case 0.5:
        return value >= 0.5
          ? formatDateTimeLocal(data?.picked_up_at || data?.created_at)
          : '';
      case 0.75:
        return value >= 0.75
          ? formatDateTimeLocal(data?.out_for_delivery_time)
          : '';

      case 1:
        return value >= 1
          ? formatDateTimeLocal(data?.delivery_completed_time)
          : '';

      default:
        return '';
    }
  };

  return (
    <Stack
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        mt: 1.6,
        mb: 0.2,
      }}
    >
      <StyledLine
        sx={{
          height:
            taskStatus === TaskStatus.Cancelled
              ? progressList.length * 22
              : (progressList.length + 1) * 22,
        }}
      />
      {progressList.map((item) => (
        <Stack
          key={item.value}
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <StyledTrackItem
            checked={
              item.value === value || (value === 0.9 && item.value === 0.75)
            }
          >
            <StyledTrackIcon />
            <StyledTrackTitle
              active={
                item.value === value || (value === 0.9 && item.value === 0.75)
              }
            >
              {item.label}
            </StyledTrackTitle>
          </StyledTrackItem>
          <StyledTrackTitle
            active={
              item.value === value || (value === 0.9 && item.value === 0.75)
            }
          >
            {getTrackTime(item.value)}
          </StyledTrackTitle>
        </Stack>
      ))}
    </Stack>
  );
};
