import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Typography, Grid, Divider } from '@mui/material';
import moment from 'moment-timezone';
import { useFormikContext } from 'formik';
import { Theme, styled } from '~/themes';
import { TIMEZONE_SETTING } from '~/constants/adminOptions';
import { Field } from '~/components/common/Formik';
import { CHANGE_PAGE_OPTION, DEFAULT_TIMEZONE } from '~/constants/common';
import { IAccount } from '~/models/adminOptions';
import { ITimezone } from '~/models/common';
import { USER_DEVICE_ZONE } from '~/utils/common';
import { getDefaultTimezone } from '~/utils/settings';
import {
  useRemoveUserAvatar,
  useUpdateUserAvatar,
} from '~/services/api/shared';
import Button from '~/components/common/Button';
import { setAccountQueryData } from '~/utils/reactQuery';
import {
  UploadImageDialog,
  UploadImageProvider,
  UploadImageSelection,
} from '~/components/shared/UploadImage/';
import ChangePwdModal from './ChangePwdModal';

interface ILocalTime {
  timezone: string;
}

const LocalTime: React.FC<ILocalTime> = ({ timezone }) => {
  const [localTime, setLocalTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setLocalTime(moment());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <b>
        Local time is: &nbsp;
        {timezone
          ? localTime
              .clone()
              .tz(timezone.replace('___', ''))
              .format('YYYY-MM-DD HH:mm:ss')
          : ''}
      </b>
      <div>
        Universal time is: &nbsp;
        {localTime.utc().format('YYYY-MM-DD HH:mm:ss')}
      </div>
    </>
  );
};

const ContentContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowX: 'scroll',
  gap: theme.spacing(3),
  marginRight: theme.spacing(-5),
  paddingRight: theme.spacing(5),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    marginRight: theme.spacing(-3),
    paddingRight: theme.spacing(3),
  },
}));

const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.color.violet900,
  fontSize: 16,
  fontWeight: 500,
}));

const TextTitle = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.color.black,
  fontSize: 14,
}));

const RadioContainer = styled(Field.GroupRadioButton)(
  ({ theme }: { theme: Theme }) => ({
    'width': '100%',
    '& > div > label': {
      borderRadius: theme.spacing(2.5),
      border: `1px solid ${theme.color.violet600}`,
    },
  }),
);

const RadioTitle = styled('div')(() => ({
  'display': 'flex',
  'justifyContent': 'space-between',
  'fontSize': 14,
  '&> div': {
    display: 'flex',
  },
}));

const LocalTimeContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}));

const RadioHighlight = styled('div')(({ theme }: { theme: Theme }) => ({
  fontWeight: 300,
  marginLeft: theme.spacing(2),
}));

const RadioContent = styled('div')(() => ({
  width: 'calc(100% + 32px)',
  marginLeft: -32,
  marginTop: 20,
}));

const ListDesc = styled('div')(({ theme }: { theme: Theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(3),
}));

const TimeDesc = styled('div')(({ theme }: { theme: Theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(2),
}));

const ImageContainer = styled(Grid)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
}));

const NameContainer = styled('div')(() => ({
  'display': 'flex',
  'gap': 10,
  'flex': 1,
  'alignItems': 'start',
  'flexWrap': 'wrap',
  '& > div': {
    '&:first-of-type': {
      width: 'unset',
      flex: 1,
      minWidth: 100,
    },
  },
  'button': {
    marginTop: '23px',
  },
}));

interface IProps {
  dataTimezone: ITimezone[];
  isLoadingTimezone: boolean;
}

export const GeneralContent: React.FC<IProps> = ({
  dataTimezone,
  isLoadingTimezone,
}) => {
  const [isChangingPwd, setIsChangingPwd] = useState(false);
  const { values, setFieldValue } = useFormikContext<IAccount>();

  const { mutate: uploadImage, isLoading: isLoadingUploadAvatar } =
    useUpdateUserAvatar({
      onSuccess: () => {},
    });

  const { mutate: removeImage, isLoading: isLoadingRemoveAvatar } =
    useRemoveUserAvatar({
      onSuccess: (resp: IAccount) => {
        setFieldValue('avatar', '');
        setAccountQueryData({ avatar: '', org_id: resp.org_id });
      },
    });

  const autoTimeZone = useMemo(() => {
    if (!dataTimezone) return '';
    if (getDefaultTimezone(dataTimezone)) {
      return getDefaultTimezone(dataTimezone);
    }
    const tzString = moment().tz(USER_DEVICE_ZONE).format('Z');
    return `(UTC${tzString})`;
  }, [dataTimezone]);

  const onSaveImage = useCallback(async (formData: FormData | null) => {
    if (formData) {
      uploadImage({ body: formData });
    } else {
      removeImage({
        body: {
          data: null,
        },
      });
    }
  }, []);

  return (
    <ContentContainer className='customized-scrollbar'>
      <ChangePwdModal
        isOpen={isChangingPwd}
        onClose={() => setIsChangingPwd(false)}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} marginBottom={-2}>
          <Title>Profile</Title>
        </Grid>
        <ImageContainer item xs={12} sm={8} lg={5} xl={4}>
          <UploadImageProvider
            defaultImages={[values.avatar].filter((item) => !!item)}
            onSaveImage={onSaveImage}
          >
            <UploadImageSelection
              loading={isLoadingUploadAvatar || isLoadingRemoveAvatar}
            />
            <UploadImageDialog />
          </UploadImageProvider>

          <NameContainer>
            <Field.TextInput name='display_name' legend='Name' />
            <Button buttonType='default' onClick={() => setIsChangingPwd(true)}>
              Change Password
            </Button>
          </NameContainer>
        </ImageContainer>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={8} lg={5} xl={4}>
          <Title>List Pagination</Title>
          <ListDesc>
            You can choose how many items you would like to display per list
            page.
          </ListDesc>
          <Field.Select
            name='items_per_page'
            legend='No. Of Items Per Page'
            options={CHANGE_PAGE_OPTION}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={8} lg={5} xl={4}>
          <Title>Times Zone Settings</Title>
          <TimeDesc>
            Choose auto-detect time zone or manually set a time zone.
          </TimeDesc>
          <RadioContainer
            name='timezoneType'
            onChange={(_, newValue) => {
              if (
                newValue === TIMEZONE_SETTING.MANUALLY &&
                values.timezone === TIMEZONE_SETTING.AUTO_DETECT
              ) {
                setFieldValue('timezone', DEFAULT_TIMEZONE);
              }
            }}
            options={[
              {
                value: TIMEZONE_SETTING.AUTO_DETECT,
                label: (
                  <RadioTitle>
                    <TextTitle>Auto-detect time zone</TextTitle>
                    <RadioHighlight>{autoTimeZone}</RadioHighlight>
                  </RadioTitle>
                ),
              },
              {
                value: TIMEZONE_SETTING.MANUALLY,
                label: (
                  <div>
                    <TextTitle>Manually set a time zone</TextTitle>
                    {values.timezoneType === TIMEZONE_SETTING.MANUALLY && (
                      <RadioContent>
                        <Field.Select
                          name='timezone'
                          legend='Time Zone'
                          options={(dataTimezone || []).map((item) => ({
                            label: item.name,
                            value: item.olson_name,
                            description: item.utc,
                          }))}
                          disabled={isLoadingTimezone}
                        />
                        <LocalTimeContainer>
                          <LocalTime timezone={values.timezone} />
                        </LocalTimeContainer>
                      </RadioContent>
                    )}
                  </div>
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
    </ContentContainer>
  );
};
