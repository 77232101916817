/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '~/themes';
import { Box, Button, IconButton } from '@mui/material';
import clsx from 'clsx';
import { useUpdateTrackRating } from '~/services/api/track';
import { useParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { TextInput } from '~/components/common/TextInput';
import { useMobileViewPort } from '~/utils/hooks/useSize';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';

const style = (theme: Theme) =>
  createStyles({
    contentContainer: {
      padding: theme.spacing(1.6),
    },
    ratingContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    feedbackContainer: {},
    closeFeedback: {
      marginLeft: -12,
      color: theme.color.black,
      marginBottom: theme.spacing(1),
    },
    ratingTitle: {
      marginBottom: theme.spacing(1),
    },
    ratingDesc: {
      color: theme.color.gray900,
      fontSize: 16,
      marginBottom: theme.spacing(3),
    },
    message: {
      fontSize: 26,
      marginBottom: theme.spacing(2),
    },
    addFeedback: {
      color: theme.color.violet900,
      fontSize: 16,
      fontWeight: 600,
      marginTop: theme.spacing(3),
      textDecoration: 'underline',
    },
    textInputContainer: {
      padding: '10px',
    },
    textInput: {
      'fontSize': 16,
      '&::placeholder': {
        color: theme.color.gray900,
      },
    },
    buttonContainer: {
      position: 'absolute',
      width: 'calc(100% - 30px)',
    },
    button: {
      width: '100%',
      backgroundColor: '#ED6808',
      borderRadius: theme.spacing(0.5),
      height: 50,
      fontSize: 18,
      fontWeight: 600,
    },
  });

interface ITrackRate extends WithStyles<typeof style> {
  setOpenComment: (value: boolean) => void;
}

const TrackFeedback: React.FC<ITrackRate> = ({ classes, setOpenComment }) => {
  const { code } = useParams();
  const [message, setMessage] = useState('');
  const { bottom } = useMobileViewPort();

  const { mutate: updateTrackRating, isLoading } = useUpdateTrackRating({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.track.getTrack]);
      setOpenComment(false);
    },
    onError: () => {
      setOpenComment(false);
    },
  });

  return (
    <div className={clsx(classes.contentContainer, classes.feedbackContainer)}>
      <IconButton
        className={classes.closeFeedback}
        onClick={() => setOpenComment(false)}
      >
        <Close />
      </IconButton>
      <TextInput
        className={classes.textInputContainer}
        sx={{
          '& fieldset': { border: 'none' },
        }}
        name='message'
        placeholder='Send feedback about the delivery'
        fullWidth
        rows={5}
        multiline
        inputProps={{
          className: clsx('customized-scrollbar', classes.textInput),
        }}
        error={message?.length > 300}
        helperText={message?.length > 300 ? 'Max 300 characters' : ''}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        disabled={isLoading}
      />
      <Box maxWidth='sm' className={classes.buttonContainer} style={{ bottom }}>
        <Button
          variant='contained'
          className={classes.button}
          onClick={() => {
            updateTrackRating({
              id: code,
              ...(message && {
                feed_back: message,
              }),
            });
          }}
          disabled={message?.length > 300}
        >
          Done
        </Button>
      </Box>
    </div>
  );
};

export default withStyles(style)(TrackFeedback);
