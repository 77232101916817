import { SxProps } from '@mui/system';
import React from 'react';
import Button from '~/components/common/Button';
import { useWorkspaceNavigate } from '~/hooks/useWorkspaceNavigate';
import themes from '~/themes';

type UpgradePlanButtonProps = {
  isShowButton?: boolean;
  content?: React.ReactNode;
  sxProps?: SxProps;
};

export const UpgradePlanButton: React.FC<UpgradePlanButtonProps> = ({
  isShowButton = true,
  content,
  sxProps,
}) => {
  const navigate = useWorkspaceNavigate();
  if (isShowButton) {
    return (
      <Button
        onClick={() => navigate('/setting/plans')}
        sx={{
          ...sxProps,
          'width': 'fit-content',
          'background': themes.bg.lightYellow,
          'color': themes.color.yellow,
          'fontWeight': 'bold',
          '&:hover': {
            background: themes.bg.lightYellow,
          },
        }}
      >
        Upgrade Plan
      </Button>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{content || <div />}</>;
};
