import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { LinkButton } from '~/components/common/Button';
import {
  ChangeBilled,
  ComparePlans,
  SubscriptionPlans,
} from '~/components/shared/Subscriptions';
import { IBillingFrequency, IPlanTier } from '~/models/plan';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import themes from '~/themes';
import { agreeTermsURL } from '~/constants/auth';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  StyledPlanItemContainer,
  StyledPlanContainer,
  StyledSubscriptionContainer,
} from './style';
import { TaskQuota } from './components/TaskQuota';
import { DialogSubscription } from './components/DialogSubscription';
import { DialogDowngradePlan } from './components/DialogDowngradePlan';

type PlansProps = {};

export const Plans: React.FC<PlansProps> = () => {
  const [billingFrequency, setBillingFrequency] = useState<IBillingFrequency>(
    IBillingFrequency.Month,
  );
  const [openDialogSubscription, setOpenDialogSubscription] = useState(false);
  const [openDialogDowngrade, setOpenDialogDowngrade] = useState(false);
  const [isSeeAllFeatures, setIsSeeAllFeatures] = useState(false);
  const [planSelected, setPlanSelected] = useState(null);
  const [downgradePriceId, setDowngradePriceId] = useState(null);

  const { currentPlan, org } = usePaymentInfo();

  const toggleOpenDialogSubscription = useCallback(
    () => setOpenDialogSubscription((v) => !v),
    [],
  );

  const toggleOpenDialogDowngrade = useCallback(
    () => setOpenDialogDowngrade((v) => !v),
    [],
  );

  const handleDowngradePlan = useCallback((priceId: string) => {
    toggleOpenDialogDowngrade();
    setDowngradePriceId(priceId);
  }, []);
  useEffect(() => {
    if (currentPlan.tier === IPlanTier.Free) {
      setBillingFrequency(IBillingFrequency.Year);
    } else {
      setBillingFrequency(currentPlan?.frequency || IBillingFrequency.Year);
    }
  }, [currentPlan]);

  return (
    <StyledPlanContainer spacing={2}>
      <StyledPlanItemContainer>
        <StyledSubscriptionContainer
          justifyContent='space-between'
          direction='row'
          alignItems='center'
          mb={1.6}
        >
          <Typography
            color={themes.color.violet500}
            fontWeight={500}
            fontSize={18}
          >
            Subscription Plans
          </Typography>
          <ChangeBilled
            billingFrequency={billingFrequency}
            onChange={(e) => {
              if (e.target.checked) {
                setBillingFrequency(IBillingFrequency.Year);
              } else {
                setBillingFrequency(IBillingFrequency.Month);
              }
            }}
          />
        </StyledSubscriptionContainer>

        <SubscriptionPlans
          countryOfOrg={org.country}
          billingFrequency={billingFrequency}
          onChangePlan={(data) => {
            const { tier } = data;
            if (tier === IPlanTier.Free) {
              setDowngradePriceId(null);
              toggleOpenDialogDowngrade();
            } else {
              setPlanSelected(data);
              toggleOpenDialogSubscription();
            }
          }}
        />
        <Box
          width='100%'
          sx={{
            height: '0px',
            overflowY: 'hidden',
            overflowX: 'auto',
            ...(isSeeAllFeatures && {
              height: 'auto',
              overflowY: 'unset',
              mt: 5,
            }),
          }}
        >
          <ComparePlans />
        </Box>
        <LinkButton
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 0.4,
            textAlign: 'center',
            mt: 2,
            textDecoration: 'none',
            fontWeight: 500,
          }}
          onClick={() => setIsSeeAllFeatures((v) => !v)}
        >
          {isSeeAllFeatures ? 'Hide details' : 'See all features'}
          <KeyboardArrowDown
            sx={{
              color: `rgba(51, 54, 61, 1) !important`,
              transform: isSeeAllFeatures ? 'rotate(180deg)' : 'none',
            }}
          />
        </LinkButton>
      </StyledPlanItemContainer>
      <TaskQuota />
      {openDialogSubscription && (
        <DialogSubscription
          planSelected={planSelected}
          frequencySelected={billingFrequency}
          open={openDialogSubscription}
          onClose={toggleOpenDialogSubscription}
          onDowngradePlan={handleDowngradePlan}
          currentPlan={currentPlan}
        />
      )}
      {openDialogDowngrade && (
        <DialogDowngradePlan
          open={openDialogDowngrade}
          onClose={toggleOpenDialogDowngrade}
          downgradePriceId={downgradePriceId}
          currentPlan={currentPlan}
        />
      )}
      <Box display='flex'>
        <a
          href={agreeTermsURL}
          target='_blank'
          rel='noreferrer'
          style={{
            marginLeft: 'auto',
            color: themes.color.violet900,
            fontSize: 12,
          }}
        >
          Agreements and Terms
        </a>
      </Box>
    </StyledPlanContainer>
  );
};
