import { LocationInfo } from '~/pages/LiveTracking/types';
import { IPlanTier } from './plan';

export enum TrackColor {
  Orange = '#FA8C73', // primaryOrange
  Custom = '',
}

export enum TrackImage {
  Organization = 'Organization',
  Driver = 'Driver',
  None = 'None',
}

export enum TrackDetails {
  Transportation = 'Transportation',
  DriverName = 'DriverName',
  DestinationAddress = 'DestinationAddress',
  CompletionReason = 'CompletionReason',
}

export enum TrackCommunication {
  Call = 'Call',
  Message = 'Message',
  Feedback = 'Feedback',
}

export enum TrackDisplay {
  Mobile = 'Mobile',
  Full = 'Full',
}

export interface ITrackConfig {
  color: TrackColor;
  image: TrackImage;
  details: TrackDetails;
  communication: TrackCommunication;
}

export enum TrackStatus {
  OrderIsConfirmed = 'Order confirmed',
  PreparingYourOrder = 'Preparing your order',
  OrderIsOutForDelivery = 'Order for delivery',
  OrderIsArrivingSoon = 'Arriving soon',
  Delivered = 'Delivered',
  Cancelled = 'Cancelled',
  Returned = 'Package has been returned',
}

export interface ITrackData {
  driver_avatar: string | null;
  driver_avatar_type: string | null;
  driver_name: string | null;
  org_name: string | null;
  phone: string | null;
  allowed_call: boolean;
  allowed_sms: boolean;
  message: TrackStatus;
  estimated_delivery_time: number | null;
  progress_bar: number;
  plate_number: string;
  vehicle_body: string;
  address_title: string;
  address_body: string;
  expected_delivery_after: string | null;
  expected_delivery_before: string | null;
  order_number: string;
  rating?: number;
  feed_back?: string;
  address_1: string | null;
  address_2: string | null;
  delivery_name: string | null;
  org_tier?: IPlanTier;
  delivery_completed_time?: string;
  pickup?: LocationInfo;
  delivery?: LocationInfo;
  created_at?: string;
  picked_up_at?: string;
  out_for_delivery_time?: string;
  status?: string;
}

export interface ITrackRating {
  rating?: number;
  feed_back?: string;
}

export enum TaskStatus {
  Completed = 'completed',
  Returned = 'returned',
  Cancelled = 'cancelled',
}
