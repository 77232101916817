import { Divider, Skeleton, Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useEffect, useMemo, useState } from 'react';
import { IDriverInfo, ITeam } from '~/models/shared';
import { useGetTeamsQuery } from '~/services/api/shared';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import themes, { bgColors, colors, styled } from '~/themes';
import DriverIcon from '~/assets/images/icons/driver-white.svg';
import { SearchInput } from '~/components/common/Search';
import {
  useGetDistanceFromDriverToPickupQuery,
  useGetIndependentDrivers,
} from '~/services/api/task';
import {
  LocationInfo as ITaskLocation,
  TaskStatus,
} from '~/pages/LiveTracking/types';
import Tooltip from '~/components/common/Tooltip';
import InfoIcon from '~/assets/images/icons/info.svg';
import RemoveAssignmentIcon from '~/assets/images/icons/remove-assignment.svg';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { random } from 'lodash';
import { useGetDrivers } from '~/pages/LiveTracking/apis';
import { useCommonStyles } from './style';
import { DriverAvatar } from './DriverAvatar';

type DriverDistanceProps = {
  driver: IDriverInfo;
  pickup: any;
  delivery: any;
};

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
    paddingLeft: 0,
  },
}));

export const RemoveAssignmentId = 'remove_assignment';

const DriverDistance: React.FC<DriverDistanceProps> = ({
  driver,
  pickup,
  delivery,
}) => {
  const params = {
    driver_id: driver.id,
    pickup_address_1: pickup.address_1,
    pickup_state: pickup.state,
    pickup_country: pickup.country,
    pickup_postcode: pickup.postcode,
    pickup_city: pickup.city,
    ...(!pickup?.address_1 && {
      pickup_address_1: delivery.address_1,
      pickup_state: delivery.state,
      pickup_country: delivery.country,
      pickup_postcode: delivery.postcode,
      pickup_city: delivery.city,
    }),
  };
  const { data, isFetching } = useGetDistanceFromDriverToPickupQuery({
    params,
    // enabled: driver.driver_status === 'online' && !!pickup?.address_1,
    enabled: driver.driver_status === 'online',
  });

  if (isFetching) return <div>...</div>;
  if (!data.distance.length) return <Typography>-</Typography>;
  return (
    <Typography>
      {data.distance[0] === -1 ? '-' : `${data.distance[0]} km away`}
    </Typography>
  );
};

type AssignTaskProps = {
  pickup: ITaskLocation;
  delivery: ITaskLocation;
  status?: TaskStatus;
  hubId?: string;
  renderHeader?: React.ReactNode;
  onChangeAssigner?: (data: {
    id?: string;
    type?: 'driver' | 'team';
    driverData?: any;
    teamId?: string;
  }) => void;
};

export const AssignTask: React.FC<AssignTaskProps> = ({
  pickup,
  delivery,
  renderHeader,
  status,
  hubId,
  onChangeAssigner,
}) => {
  const classes = useCommonStyles();
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const { isStarterOrStandardPlan } = usePaymentInfo();
  const { data: rawData, isFetching: isFetchingTeam } = useGetTeamsQuery({
    params: {
      hub_id: hubId,
    },
    enabled: !isStarterOrStandardPlan,
  });
  const {
    data: independentDrivers,
    isFetching: isFetchingGetIndependentDrivers,
  } = useGetIndependentDrivers({ enabled: !isStarterOrStandardPlan });

  const { data: drivers, isFetching: isFetchingDriver } = useGetDrivers({
    params: {
      status: 'active',
      expand: 'team',
      roles: 'driver',
    },
    enabled: isStarterOrStandardPlan,
  });

  const [assignerId, setAssignerId] = useState('');
  const [searchDriverQuery, setSearchDriverQuery] = useState('');

  const teams = useMemo(() => rawData || [], [rawData]);

  useEffect(() => {
    if (teams.length) {
      setExpandedIds(teams.map((t) => t.id));
    }
  }, [teams]);

  const expanded = (id: string) => !!expandedIds.find((e) => e === id);
  const handleChangeExpandedIds = (newId: string) => (event, isExpanded) => {
    setExpandedIds((prevIds) =>
      isExpanded
        ? prevIds.concat([newId])
        : prevIds.filter((id) => id !== newId),
    );
  };

  const skeletonLoadingComponent = useMemo(
    () =>
      Array(8)
        .fill('')
        .map(() => (
          <Stack
            key={(Math.random() + 1).toString(36).substring(7)}
            spacing={0.5}
          >
            <Skeleton width='50%' animation={false} />
            {Array(2)
              .fill('')
              .map(() => (
                <>
                  <Stack
                    direction='row'
                    spacing={1}
                    alignItems='center'
                    pl={1.5}
                  >
                    <Skeleton
                      animation={false}
                      variant='circular'
                      width={30}
                      height={30}
                    />
                    <Skeleton
                      animation={false}
                      width={random(50, 120)}
                      height={20}
                    />
                  </Stack>
                  <Divider />
                </>
              ))}
          </Stack>
        )),
    [],
  );

  const renderDriver = (teamId, driver: IDriverInfo) => {
    const {
      id,
      display_name,
      driver_status,
      receive_auto_assign_task,
      idle_at,
    } = driver;
    const isChecked = assignerId === teamId + id;

    return (
      <Stack
        className={classes.driverItem}
        key={teamId + id}
        component='li'
        value={display_name}
        data-value={id}
        data-testid='driver-assign-item'
        direction='row'
        spacing={1}
        justifyContent='space-between'
        alignItems='center'
        onClick={() => {
          if (isChecked) {
            onChangeAssigner({});
            setAssignerId('');
          } else {
            onChangeAssigner({
              id,
              type: 'driver',
              driverData: driver,
              teamId,
            });
            setAssignerId(teamId + id);
          }
        }}
        sx={{
          cursor: 'pointer',
          borderBottom: `1px solid #E9E7F6`,
          p: 1,
          pl: 2,
          ...(isChecked && {
            background: bgColors.midPurple,
            borderRadius: 1,
          }),
        }}
      >
        <Stack direction='row' spacing={1} alignItems='center'>
          <DriverAvatar
            status={driver_status}
            avatar={driver.avatar}
            name={display_name}
            receiveAutoAssignTask={receive_auto_assign_task}
            idleAt={idle_at}
          />
          <Typography color={colors.black}>{display_name}</Typography>
        </Stack>

        {driver.driver_status === 'online' ? (
          <DriverDistance
            driver={driver}
            pickup={pickup || {}}
            delivery={delivery || {}}
          />
        ) : (
          ''
        )}
      </Stack>
    );
  };

  const renderAccordionItem = (team: ITeam, idx) => {
    const { id, driver_info, is_preferred_team } = team;
    const isChecked = assignerId === id;
    const isShowPreferredTeam = hubId && is_preferred_team && idx === 0;
    return (
      <Accordion
        disableGutters
        expanded={expanded(id)}
        key={id}
        onChange={handleChangeExpandedIds(id)}
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
      >
        <StyledAccordionSummary
          sx={{
            'p': 1,
            '& > div': {
              m: '0px !important',
              ...(!expanded(id) && {
                pb: 1,
                borderBottom: '1px solid #E9E7F6',
              }),
            },
            'minHeight': '20px !important',
            ...(isChecked && {
              background: bgColors.midPurple,
              borderRadius: 2,
            }),
            '&.MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper ':
              {
                transform: 'unset',
              },
          }}
          aria-controls={`${id}-content`}
          id={`${id}-header`}
        >
          <Stack direction='row'>
            <ArrowDropDownIcon
              sx={{
                color: colors.violet900,
                ...(expanded(id) && {
                  transform: 'rotate(180deg)',
                }),
              }}
            />
            {isShowPreferredTeam ? (
              <Typography
                fontWeight={500}
                color={colors.primaryOrange}
                marginTop='2px'
              >{`Preferred Team: ${team.name}`}</Typography>
            ) : (
              <Typography
                fontWeight={500}
                color={colors.violet900}
                marginTop='2px'
              >{`Team: ${team.name}`}</Typography>
            )}
          </Stack>
        </StyledAccordionSummary>
        <AccordionDetails
          sx={{
            p: '10px !important',
            ...(expanded(id) && {
              mt: -2,
            }),
          }}
        >
          {driver_info.length ? (
            driver_info
              .filter((e) =>
                e.display_name
                  .toLowerCase()
                  .includes(searchDriverQuery?.toLowerCase()),
              )
              .map((driver) => renderDriver(id, driver))
          ) : (
            <Stack
              p={1.5}
              pl={2}
              sx={{
                ...(expanded(id) && {
                  borderBottom: '1px solid #E9E7F6',
                }),
              }}
            >
              <Typography color={colors.black}>No driver available</Typography>
            </Stack>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderRemoveAssignment = () => (
    <Stack
      direction='row'
      data-testid='remove-assign-item'
      component='li'
      value='remove-assign-item'
      p={1}
      pl={2}
      ml={1}
      spacing={1}
      alignItems='center'
      borderTop={`1px solid ${bgColors.midPurple}`}
      borderBottom={`1px solid ${bgColors.midPurple}`}
      sx={{
        [themes.breakpoints.down('md')]: {
          paddingLeft: themes.spacing(1),
        },
        cursor: 'pointer',
        ...(assignerId === RemoveAssignmentId && {
          background: bgColors.midPurple,
          borderRadius: 1,
        }),
      }}
      onClick={() => {
        onChangeAssigner({
          id: RemoveAssignmentId,
          type: 'driver',
          driverData: {},
          teamId: '',
        });
        setAssignerId(RemoveAssignmentId);
      }}
    >
      <img
        width={30}
        height={30}
        src={RemoveAssignmentIcon}
        alt='remove-assignment'
      />
      <Typography variant='h5'>Remove assignment</Typography>
      <Tooltip
        placement='right'
        title='This task will become unassigned. If you remove this task’s assignment, it will no longer be eligible for auto-assignment in the future. You will need to manually assign it to a driver.'
      >
        <img width={20} height={20} src={InfoIcon} alt='info' />
      </Tooltip>
    </Stack>
  );
  const isShowRemoveAssignment = [
    TaskStatus.PendingPickup,
    TaskStatus.Delivering,
    TaskStatus.Returning,
  ].includes(status);

  const renderDriversForStarterAndStandardPlan = () => {
    if (!isFetchingDriver && !drivers.length) {
      return <Typography color={colors.black}>No driver available</Typography>;
    }
    return drivers
      ?.filter((e) =>
        e.display_name
          ?.toLowerCase()
          .includes(searchDriverQuery?.toLowerCase()),
      )
      .map((driver) => renderDriver('', driver));
  };

  return (
    <>
      {renderHeader || (
        <Stack direction='row' fontWeight={600} alignItems='center'>
          <span className={classes.icon}>
            <img src={DriverIcon} alt='team-info' />
          </span>
          Assign Driver (Optional)
        </Stack>
      )}

      <Stack direction='row' alignItems='center'>
        <SearchInput
          fullWidth
          placeholder='Search Driver'
          value={searchDriverQuery}
          onKeyDown={(event: any) => {
            if (event.keyCode === 13) {
              // detect Enter key press
              event.preventDefault(); // prevent default behavior
            }
          }}
          onChange={(e) => setSearchDriverQuery(e.target.value)}
          onClear={() => setSearchDriverQuery('')}
        />
        {isStarterOrStandardPlan ? (
          <div />
        ) : (
          <Typography
            sx={{
              textDecoration: 'underline',
              minWidth: 100,
              cursor: 'pointer',
            }}
            variant='body1'
            textAlign='right'
            onClick={() =>
              expandedIds.length
                ? setExpandedIds([])
                : setExpandedIds(teams.map((t) => t.id))
            }
          >
            {expandedIds.length ? 'Collapse All' : 'Expand All'}
          </Typography>
        )}
      </Stack>
      {isFetchingTeam || isFetchingGetIndependentDrivers || isFetchingDriver ? (
        skeletonLoadingComponent
      ) : (
        <Stack
          sx={{ height: '100%', overflow: 'auto', width: '100%' }}
          className='customized-scrollbar'
        >
          {isShowRemoveAssignment ? renderRemoveAssignment() : ''}
          {isStarterOrStandardPlan ? (
            renderDriversForStarterAndStandardPlan()
          ) : (
            <Stack>
              {teams.length
                ? teams.map((team, teamIdx) =>
                    renderAccordionItem(team, teamIdx),
                  )
                : ''}
              <Typography
                fontWeight={500}
                color={colors.violet900}
                marginTop='2px'
                marginLeft={1.5}
                mt={0.5}
              >
                Independent Drivers
              </Typography>
              {independentDrivers.length ? (
                <Stack pl={1}>
                  {independentDrivers
                    .filter((e) =>
                      e.display_name
                        ?.toLowerCase()
                        .includes(searchDriverQuery?.toLowerCase()),
                    )
                    .map((driver) => renderDriver('', driver))}
                </Stack>
              ) : (
                <Typography ml={3} my={1} color={colors.black}>
                  No driver available
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};
