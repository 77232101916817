import React from 'react';
import Button, { ButtonProps as _ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import { createStyles, WithStyles, withStyles } from '@mui/styles';

export interface IButton extends _ButtonProps {
  loading?: boolean;
  destructive?: boolean;
  buttonType?:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'text'
    | 'outlined'
    | 'primary-dark';
  className?: string;
  tooltipText?: string;
  outline?: boolean;
  deleteText?: string;
  loadingText?: string;
  noRounder?: boolean;
  rounder10?: boolean;
}

const style = () =>
  createStyles({
    root: {
      minWidth: 125,
      transition: 'none',
    },
    noRounder: {
      borderRadius: 5,
    },
    rounder10: {
      borderRadius: 10,
    },
    primaryDark: {
      'background': '#121142',
      'color': '#fff',
      '&:hover': {
        background: '#121142',
      },
    },
  });

const CircularProgressStyled = withStyles(() => ({
  root: {
    marginLeft: 8,
  },
}))(CircularProgress);

const getVariant = (buttonType: string) => {
  switch (buttonType) {
    case 'primary':
    case 'secondary':
      return 'contained';

    case 'default':
      return 'text';
    default:
      return undefined;
  }
};

const getColor = (buttonType: string) => {
  switch (buttonType) {
    case 'primary':
      return 'primary';
    case 'secondary':
      return 'secondary';
    case 'default':
    default:
      return undefined;
  }
};

const WrappedButton: React.FC<IButton & WithStyles<typeof style>> = ({
  classes,
  loading,
  deleteText,
  tooltipText,
  loadingText,
  buttonType = 'primary',
  outline,
  className,
  children,
  disabled,
  noRounder,
  rounder10,
  ...rest
}) => (
  <Button
    {...rest}
    data-testid={`${
      typeof children === 'string'
        ? children?.toString()?.replace(' ', '-')?.toLowerCase()
        : buttonType
    }-button`}
    className={clsx(
      {
        [classes.root]: true,
        [classes.noRounder]: noRounder,
        [classes.rounder10]: rounder10,
        [classes.primaryDark]: buttonType === 'primary-dark',
        loading,
        text: buttonType === 'text',
      },
      className,
    )}
    disabled={disabled || loading}
    variant={getVariant(buttonType)}
    color={getColor(buttonType)}
  >
    {children}
    {loading && <CircularProgressStyled size={18} color='inherit' />}
  </Button>
);

export default withStyles(style)(WrappedButton);
