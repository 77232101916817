import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import themes, { styled } from '~/themes';
import Button, { CloseButton } from '~/components/common/Button';
import Modal from '~/components/common/Modal';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Field } from '~/components/common/Formik';
import {
  useCheckConnection,
  useGetBreadstackStores,
} from '~/services/api/stores';
import { showAlert, alertParams } from '~/components/common/Alert';
import { getErrorMessage } from '~/components/common/Alert/alertParams';
import { Form, Formik } from 'formik';
import { BreadstackLoginSchema } from '~/utils/schema/stores';
import {
  ConnectionStoreStatus,
  CreateStoreStatus,
  IntegrationType,
} from '~/models/stores';
import { TextLink } from '~/components/common/TextLink';
import { CanFleetPluginURL } from '~/constants/stores';
import BreadstackIcon from '~/assets/images/icons/breadstack-rounded.svg';
import ExportIcon from '~/assets/images/icons/export.svg';
import PlugIcon from '~/assets/images/icons/plug-fill.svg';
import { isEqual } from 'lodash';
import { StoreConnection } from './StoreConnection';
import { AddStoreLayout } from '../layout/AddStoreLayout';

type BreadstackStoreProps = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setPlatform: (p: IntegrationType) => void;
  onClose: () => void;
  createIntegrationMutation: any;
  isLoadingCreateIntegration: boolean;
};

export const StyledTextField = styled(Field.TextInput)(() => ({
  'backgroundColor': '#EDF1FB',
  'borderRadius': '5px !important',
  'minHeight': 35,
  'paddingRight': 5,
  '.MuiInputBase-input': {
    'boxShadow': 'none !important',
    'color': '#2E2E2E',
    '&::placeholder': {
      opacity: 1,
      color: '#74798C',
      fontSize: 14,
    },
  },
  '&, &:not(.Mui-error):hover': {
    '.MuiOutlinedInput-notchedOutline': {
      border: '0.25px solid #2959C5',
    },
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  'marginTop': '30px !important',
  'background': theme.bg.breadstack,
  'width': 'fit-content',
  '&:hover': {
    backgroundColor: `${theme.bg.breadstack} !important`,
  },
  '&.loading': {
    backgroundColor: `${theme.bg.breadstack} !important`,
  },
}));

export const BreadstackStore: React.FC<BreadstackStoreProps> = ({
  step,
  setStep,
  setPlatform,
  onClose,
  createIntegrationMutation,
  isLoadingCreateIntegration,
}) => {
  const formLoginRef = useRef(null);
  const [open, setOpen] = useState(true);
  const [bsStoreSelect, setBsStoreSelect] = useState<{
    store_id: string;
    store_url: string;
    display_name: string;
  }>(null);
  const [bsStoreSelectTemp, setBsStoreSelectTemp] = useState<{
    store_id: string;
    store_url: string;
    display_name: string;
  }>(null);

  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStoreStatus>(null);

  const {
    mutate: checkConnectionMutation,
    isLoading: isLoadingCheckConnection,
  } = useCheckConnection({
    onSuccess: (resp) => {
      if (resp?.connected) {
        setConnectionStatus(ConnectionStoreStatus.Connected);
      } else {
        setConnectionStatus(ConnectionStoreStatus.NotConnected);
      }
    },
    onError: () => setConnectionStatus(ConnectionStoreStatus.NotConnected),
  });

  const {
    mutate: getBreadstackStores,
    data: breadstackStores = [],
    isLoading,
  } = useGetBreadstackStores({
    onSuccess: (data) => {
      if (!data.length) {
        showAlert({
          ...alertParams.warning,
          description: 'No breadstack store data.',
        });
      }
      if (!bsStoreSelectTemp && data.length) {
        const storeList = data
          ?.map((item) => ({
            ...item,
            label: item.store_name,
          }))
          .sort((a, b) =>
            a?.label?.toUpperCase().localeCompare(b?.label?.toUpperCase()),
          );
        setBsStoreSelectTemp({
          store_id: storeList[0]?.store_id || '',
          store_url: storeList[0]?.store_url || '',
          display_name: storeList[0]?.store_name || '',
        });
      }
    },
    onError: (error) => {
      showAlert({
        ...alertParams.warning,
        title: 'Error',
        description:
          getErrorMessage(error) || 'Could not connect to Breadstack',
      });
    },
  });

  const onCloseBsModal = () => {
    if (bsStoreSelect) {
      setOpen(false);
    } else {
      setStep(1);
      setPlatform(null);
    }
  };

  const handleSignInBs = (values) => {
    getBreadstackStores({
      email: values.email,
      password: values.password,
    });
  };

  const onDataChange = (_, newValue: string) => {
    const selectedItem = (breadstackStores || []).find(
      (x) => x.store_url === newValue,
    );
    setBsStoreSelectTemp({
      store_id: selectedItem?.store_id || '',
      store_url: selectedItem?.store_url || '',
      display_name: selectedItem?.store_name || '',
    });
  };

  const handleCreateIntegration = useCallback(() => {
    createIntegrationMutation({
      display_name: bsStoreSelect?.display_name,
      status: CreateStoreStatus?.Deactivated,
      integration_type: IntegrationType.Breadstack,
      store_url: bsStoreSelect?.store_url,
      store_id: bsStoreSelect?.store_id,
    });
  }, [bsStoreSelect, connectionStatus]);

  const loginComponent = useMemo(
    () => (
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSignInBs}
        validationSchema={BreadstackLoginSchema}
        innerRef={formLoginRef}
      >
        {() => (
          <Form style={{ minWidth: 300 }}>
            <Stack spacing={2} alignItems='center'>
              <StyledTextField name='email' legend='Email' type='email' />
              <StyledTextField
                name='password'
                legend='Password'
                type='password'
              />
            </Stack>
          </Form>
        )}
      </Formik>
    ),
    [isLoading],
  );

  const storeListComponent = useMemo(() => {
    const storeOptions = breadstackStores
      ?.map((item) => ({
        value: item.store_url,
        label: item.store_name,
        desc: item.store_url,
      }))
      .sort((a, b) =>
        a?.label?.toUpperCase().localeCompare(b?.label?.toUpperCase()),
      );

    return (
      <Box
        className='customized-scrollbar'
        maxHeight={300}
        sx={{ overflow: 'auto' }}
      >
        <RadioGroup
          sx={{ width: '100%' }}
          onChange={onDataChange}
          value={bsStoreSelectTemp?.store_url}
        >
          {storeOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              sx={{
                '& > span:first-of-type': {
                  color: `#2D91FC !important`,
                  px: 0,
                  mr: 1,
                  py: 0,
                },
                'borderRadius': '4px',
                'p': 0.8,
                'mx': 0,
                'width': '100%',
                'boxSizing': 'border-box',
                'alignItems': 'flex-start',
                ...(option.value === bsStoreSelectTemp?.store_url && {
                  background: '#F4F5F9',
                }),
              }}
              value={option.value}
              control={<Radio />}
              label={
                <Stack direction='column'>
                  <Typography fontWeight='bold' color={themes.color.violet500}>
                    {option.label}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'rgba(51,54,61,0.40)',
                    }}
                  >
                    {option.desc}
                  </Typography>
                </Stack>
              }
            />
          ))}
        </RadioGroup>
      </Box>
    );
  }, [breadstackStores, bsStoreSelectTemp]);

  const contentComponent = useMemo(() => {
    if (step === 2) {
      return (
        <Stack
          justifyContent='center'
          p={1.6}
          sx={{
            backgroundColor: '#F4F5F9',
            borderRadius: '8px',
          }}
        >
          <Stack flexDirection='row' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={0.8}>
              <img
                width={32}
                height={32}
                src={BreadstackIcon}
                alt='platform-icon'
              />
              <Typography fontWeight='bold'>Breadstack</Typography>
            </Stack>
            <Typography
              fontWeight='500'
              onClick={() => {
                setOpen(true);
                setBsStoreSelectTemp(bsStoreSelect);
              }}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Change Store
            </Typography>
          </Stack>
          <Stack
            sx={{
              borderBottom: '1px solid #E2E7F0',
              my: 1.6,
            }}
          />
          <Stack>
            <Typography fontWeight='bold' color={themes.color.violet500}>
              {bsStoreSelect?.display_name}
            </Typography>
            <Typography color='#717D96'>
              URL: {bsStoreSelect?.store_url}
            </Typography>
          </Stack>
        </Stack>
      );
    }
    return (
      <>
        <Stack
          p={1.6}
          sx={{
            backgroundColor: '#F4F5F9',
            borderRadius: '8px',
          }}
        >
          <Stack
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            gap={1}
            flexWrap='wrap-reverse'
          >
            <Typography fontWeight={700} color={themes.color.violet500}>
              {bsStoreSelect?.display_name}
            </Typography>
            <StoreConnection status={connectionStatus} />
          </Stack>
          <Typography color='#717D96'>
            URL: {bsStoreSelect?.store_url}
          </Typography>
        </Stack>
        <Stack mt={2.4} gap={0.5}>
          <Typography color='#717D96'>Set up the store connection</Typography>
          <Typography
            color='#717D96'
            sx={{
              width: '100%',
              ml: 1,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '6px',
            }}
          >
            1. Install the plugin{' '}
            <TextLink
              style={{
                fontWeight: '500',
                textDecoration: 'none',
                color: '#33363D',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '4px',
              }}
              to={CanFleetPluginURL.Breadstack}
            >
              <img src={ExportIcon} alt='download-icon' />
              Breadstack Delivery Plugin
            </TextLink>
          </Typography>
          <Typography
            sx={{
              width: '100%',
              ml: 0.8,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '6px',
            }}
            color='#717D96'
          >
            2. After the plugin is installed{' '}
            <Typography
              sx={{
                fontWeight: '500',
                color: '#33363D',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '1px',
              }}
              onClick={() => {
                setConnectionStatus(ConnectionStoreStatus.Checking);
                checkConnectionMutation({
                  integration_type: IntegrationType.Breadstack,
                  store_url: bsStoreSelect?.store_url,
                  store_id: bsStoreSelect?.store_id,
                });
              }}
            >
              <img src={PlugIcon} alt='check-icon' />
              Check Connection
            </Typography>
          </Typography>
        </Stack>
      </>
    );
  }, [
    step,
    isLoadingCheckConnection,
    isLoadingCreateIntegration,
    connectionStatus,
    bsStoreSelect,
    bsStoreSelectTemp,
  ]);

  return (
    <AddStoreLayout
      isEnabledCloseAndUpdateStore={!!bsStoreSelect?.store_url}
      onClose={onClose}
      step={step}
      loading={isLoadingCreateIntegration}
      onBack={() => {
        setStep((s) => {
          if (s === 2) {
            setPlatform(null);
          }
          return s - 1;
        });
      }}
      onNext={() => {
        if (step === 2) {
          setStep((s) => s + 1);
        } else {
          handleCreateIntegration();
        }
      }}
    >
      {contentComponent}
      <Modal
        disableCloseOutside
        open={open}
        onClose={onCloseBsModal}
        PaperProps={{
          sx: {
            '& .MuiDialogContent-root': {
              p: 0,
            },
            'borderRadius': '0px !important',
            'maxWidth': '480px',
          },
        }}
      >
        <Stack
          sx={{
            pl: themes.spacing(1.6),
            py: themes.spacing(1),
            pr: themes.spacing(0.6),
            borderBottom: '1px solid #E5E3F4',
          }}
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontWeight: 600,
              color: themes.color.violet500,
            }}
          >
            {breadstackStores.length ? 'Select a store' : 'Log into Breadstack'}
          </Typography>
          <CloseButton
            background='white'
            iconSize={11}
            onClick={onCloseBsModal}
          />
        </Stack>
        <Stack
          sx={{
            p: 1.6,
            gap: 1.6,
          }}
        >
          {breadstackStores.length ? storeListComponent : loginComponent}
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '16px',
            }}
          >
            <Button
              buttonType='text'
              noRounder
              sx={{
                minWidth: 'unset !important',
                padding: '4px 16px',
              }}
              onClick={onCloseBsModal}
            >
              <Typography
                sx={{
                  color: themes.color.violet500,
                  fontWeight: 500,
                }}
              >
                Cancel
              </Typography>
            </Button>
            <Button
              noRounder
              sx={{
                'minWidth': 'unset !important',
                'padding': '4px 16px',
                'backgroundColor': '#2D91FC',
                '&:hover': {
                  backgroundColor: '#2D91FC',
                },
                '&.loading': {
                  backgroundColor: '#2D91FC !important',
                },
              }}
              loading={isLoading}
              onClick={() => {
                if (formLoginRef.current) {
                  formLoginRef.current.handleSubmit();
                }
                if (breadstackStores?.length) {
                  setOpen(false);
                }
                if (!isEqual(bsStoreSelectTemp, bsStoreSelect)) {
                  setConnectionStatus(null);
                }
                setBsStoreSelect(bsStoreSelectTemp);
              }}
            >
              <Typography>Continue</Typography>
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </AddStoreLayout>
  );
};
