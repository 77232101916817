import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { IconButton, Stack, Typography } from '@mui/material';
import themes from '~/themes';
import { ElementSelect } from '~/components/common/ElementSelect/ElementSelect';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Icon } from '~/components/common/Icon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { alertParams, showAlert } from '~/components/common/Alert';
import { queryClient } from '~/lib/react-query';
import Button from '~/components/common/Button';
import { useFirstLoadingDetection } from '~/hooks';
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  KeyboardSensor,
  TouchSensor,
  useSensor,
  defaultDropAnimation,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { Virtuoso } from 'react-virtuoso';
import {
  restrictToVerticalAxis,
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers';
import { StyledTaskDetail } from '../../style';
import {
  LiveTrackingQueryKeys,
  useGetDriverDetail,
  useGetDriverRoutes,
  useOfflineDriverMutation,
  useOptimizeRoutes,
  useUpdateDriverRoutes,
} from '../../apis';
import { useLiveMapContext } from '../../hooks/useLiveMapContext';
import { DialogSendAlert } from '../DialogSendAlert';
import {
  convertDriverRoutesToRequestParams,
  groupDriverRoutes,
  isValidLatLngLiteral,
  sortDriverRoutes,
  updateDriverRoutes,
  updateOptimizedRoutesToDriverRoutes,
} from '../../utils';
import { OptimizeRouteLoading } from './OptimizeRouteLoading';
import {
  Driver,
  IDriverRoute,
  IDriverRouteResponse,
  MarkerType,
  TaskStatus,
} from '../../types';
import { FirstLoading } from '../FirstLoading';
import SortableTask, { BorderRouteType } from './components/SortableTask';

const isTouchDevice = () =>
  'ontouchstart' in window || navigator.maxTouchPoints > 0;

type ManageRoutesProps = {};

export const ManageRoutes = forwardRef(
  (props: ManageRoutesProps, ref: React.Ref<any>) => {
    const location = useLocation();
    const queryParams = fromQueryString(location.search);
    const {
      driverRoutes,
      driverId,
      mapBoundsPadding,
      setViewControls,
      handleCloseManageRoutes,
      handleMapFitBounds,
    } = useLiveMapContext();
    const isShowOfflineDriver = useFeatureIsOn('cf-show-offline-driver');

    const [openSendAlert, setOpenSendAlert] = useState(false);
    const [isOnManageRoutes, setIsOnManageRoutes] = useState(false);
    const [reReRenderCount, setReRenderCount] = useState(0);
    const [borderRoute, setBorderRoute] = useState<{
      type: BorderRouteType;
      idx: number;
    } | null>(null);
    const [activeId, setActiveId] = useState<string | null>(null);
    const refetchGetDriverRoutesCountsRef = useRef(1);

    const routes = isOnManageRoutes
      ? driverRoutes.updateRoutes || []
      : driverRoutes?.routes || [];

    const touchSensor = useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 10,
      },
    });

    const pointerSensor = useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 10,
      },
    });

    const keyboardSensor = useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    });

    const isTouch = isTouchDevice();
    const sensors = isTouch
      ? [touchSensor, keyboardSensor]
      : [pointerSensor, keyboardSensor];

    const enabledDropOffViewController = useCallback(() => {
      setViewControls((v: MarkerType[]) => [
        ...Array.from(new Set(v.concat([MarkerType.DropOff]))),
      ]);
    }, []);

    const {
      data: driverRoutesResp,
      refetch: refetchGetDriverRoutes,
      isFetching: isFetchingDriverRoutes,
    } = useGetDriverRoutes({
      params: { id: queryParams?.driver_id },
      onSuccess: (driverRoutesResponse: IDriverRouteResponse[]) => {
        const routesGrouped = groupDriverRoutes(driverRoutesResponse);
        const routesSorted = sortDriverRoutes(routesGrouped);
        const routesUpdated = updateDriverRoutes(routesSorted);

        driverRoutes?.setDriverRoutes(routesUpdated);
        driverRoutes?.setUpdateDriverRoutes(routesUpdated);

        driverRoutes?.clearDriverRoutesMarkers();
        driverRoutes?.clearRoutes();

        if (routesUpdated.length) {
          driverRoutes?.addDriverRoutesMarkers(routesUpdated, {
            isOnManageRoutes: false,
            isOnlyOneRoute: driverRoutesResponse.length === 1,
          });
          driverRoutes?.setFnForceRender(setReRenderCount);
          driverRoutes?.drawRoutes({ isOnManageRoutes: false });
        }

        const driver = driverRoutes?.getDriverSelected();
        const driverRoutesMap = driverRoutes?.getDriverRoutesMap();

        if (isValidLatLngLiteral(driver)) {
          const driverPosition = { lat: driver.lat, lng: driver.lng };
          handleMapFitBounds(
            driverRoutesMap.map((r) => r.position).concat([driverPosition]),
            {
              padding: mapBoundsPadding.DRIVER_ROUTES,
              wait: 250,
            },
          );
        }

        setReRenderCount((v) => v + 1);
      },
    });

    const { mutate: offlineDriverMutation } = useOfflineDriverMutation({
      onSuccess: () => {
        handleCloseManageRoutes();
        queryClient.invalidateQueries([LiveTrackingQueryKeys.DriverList]);
      },
    });

    const {
      mutate: optimizedRoutesMutation,
      isLoading: isLoadingOptimizeRoute,
    } = useOptimizeRoutes({
      onSuccess: (optimizedRoutes: IDriverRouteResponse[]) => {
        setIsOnManageRoutes(true);
        const routesUpdatedOptimizedRoutes =
          updateOptimizedRoutesToDriverRoutes(optimizedRoutes, routes);
        const routesUpdated = updateDriverRoutes(routesUpdatedOptimizedRoutes);
        const routesSorted = sortDriverRoutes(routesUpdated, {
          isOnManageRoutes: true,
        });
        driverRoutes.setUpdateDriverRoutes(routesSorted);

        driverRoutes?.clearDriverRoutesMarkers();
        driverRoutes?.clearRoutes();

        driverRoutes?.addDriverRoutesMarkers(routesSorted, {
          isOnManageRoutes: true,
        });
        driverRoutes.drawRoutes({ isOnManageRoutes: true });
        setReRenderCount((v) => v + 1);
      },
    });

    const {
      mutate: updateDriverRoutesMutation,
      isLoading: isLoadingUpdateRoutes,
    } = useUpdateDriverRoutes({
      onSuccess: () => {
        const routesUpdated = driverRoutes?.updateRoutes.map((r) => ({
          ...r,
          sort_id: r.update_sort_id || null,
          update_sort_id: null,
        }));

        driverRoutes?.setUpdateDriverRoutes(routesUpdated);
        driverRoutes?.setDriverRoutes(routesUpdated);

        driverRoutes?.clearDriverRoutesMarkers();
        driverRoutes?.clearRoutes();

        driverRoutes?.addDriverRoutesMarkers(routesUpdated, {
          isOnManageRoutes: false,
        });
        driverRoutes?.drawRoutes({ isOnManageRoutes: false });

        enabledDropOffViewController();
        setIsOnManageRoutes(false);
        setReRenderCount((v) => v + 1);

        queryClient.invalidateQueries([LiveTrackingQueryKeys.DriverRoutes]);
        showAlert(alertParams.successDark);
      },
    });

    useGetDriverDetail({
      params: { id: driverId },
      onSuccess: (driver: Driver) => {
        if (driver?.id && driverRoutes) {
          driverRoutes.clearDriverInfoWindow();
          driverRoutes.addDriverInfoWindow(driver);
        }
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        forceRerender: () => setReRenderCount((v) => v + 1),
      }),
      [],
    );

    const isFirstLoadingManageRoutes = useFirstLoadingDetection([
      isFetchingDriverRoutes,
    ]);
    const driverData = driverRoutes?.getDriverSelected() || {};

    const isOnlyOneTaskManageRoutes = useMemo(
      () => driverRoutesResp.length === 1,
      [driverRoutesResp],
    );

    useEffect(() => {
      if (driverId) {
        driverRoutes?.clearDriverInfoWindow();
        driverRoutes?.clearDriverRoutesMarkers();
        driverRoutes?.clearRoutes();
        enabledDropOffViewController();
        setIsOnManageRoutes(false);
      }
    }, [driverId, driverRoutes]);

    useEffect(() => {
      const isPendingDriverRoutes = driverRoutesResp?.some(
        (d) => !d.delivery_index && !d.pickup_index && !d.return_index,
      );

      if (
        isPendingDriverRoutes &&
        refetchGetDriverRoutesCountsRef.current >= 3
      ) {
        refetchGetDriverRoutesCountsRef.current += 1;
        refetchGetDriverRoutes();
      } else {
        refetchGetDriverRoutesCountsRef.current = 0;
      }
    }, [driverRoutesResp, refetchGetDriverRoutesCountsRef.current]);

    const handleSetRoute = useCallback(() => {
      const routesParams = convertDriverRoutesToRequestParams(routes);
      updateDriverRoutesMutation({
        driver_id: driverData.id,
        routes: routesParams,
      });
    }, [routes, driverData]);

    const handleReverseRoute = useCallback(() => {
      setIsOnManageRoutes(false);
      driverRoutes?.clearDriverRoutesMarkers();
      driverRoutes?.clearRoutes();

      driverRoutes?.setUpdateDriverRoutes(driverRoutes.routes);
      driverRoutes?.addDriverRoutesMarkers(driverRoutes.routes, {
        isOnManageRoutes: false,
      });

      driverRoutes?.drawRoutes({ isOnManageRoutes: false });
      setReRenderCount((v) => v + 1);
    }, []);

    const handleDragStart = useCallback(
      (event) => {
        const { active } = event;
        setActiveId(active.id);

        const draggingRoute = routes.find((r) => r.id === active.id);
        if (
          !draggingRoute ||
          draggingRoute.task_status !== TaskStatus.PendingPickup
        ) {
          setBorderRoute(null);
          return;
        }

        if (draggingRoute.marker_type === MarkerType.Store) {
          const draggingRouteDropOffIdx = routes.findIndex(
            (r) =>
              r.task_id === draggingRoute.task_id &&
              r.marker_type === MarkerType.DropOff,
          );
          setBorderRoute({
            type: BorderRouteType.Precede,
            idx: draggingRouteDropOffIdx,
          });
        } else if (draggingRoute.marker_type === MarkerType.DropOff) {
          const draggingRouteStoreIdx = routes.findIndex(
            (r) =>
              r.task_id === draggingRoute.task_id &&
              r.marker_type === MarkerType.Store,
          );
          setBorderRoute({
            type: BorderRouteType.Succeed,
            idx: draggingRouteStoreIdx,
          });
        } else {
          setBorderRoute(null);
        }
      },
      [routes],
    );

    const handleDragEnd = useCallback(
      (event) => {
        const { active, over } = event;
        setActiveId(null);

        if (!over) {
          setBorderRoute(null);
          return;
        }

        const startIndex = routes.findIndex((r) => r.id === active.id);
        const endIndex = routes.findIndex((r) => r.id === over.id);

        if (startIndex === endIndex) {
          setBorderRoute(null);
          return;
        }

        const isInvalidEndIndex =
          borderRoute &&
          ((borderRoute.type === BorderRouteType.Precede &&
            endIndex >= borderRoute.idx) ||
            (borderRoute.type === BorderRouteType.Succeed &&
              endIndex <= borderRoute.idx));

        if (isInvalidEndIndex) {
          setBorderRoute(null);
          return;
        }

        const routesReordered = arrayMove(routes, startIndex, endIndex);
        const routesUpdated = routesReordered.map((r, idx) => ({
          ...r,
          update_sort_id: idx + 1,
        }));

        driverRoutes?.setUpdateDriverRoutes(routesUpdated);

        driverRoutes?.clearDriverRoutesMarkers();
        driverRoutes?.clearRoutes();

        driverRoutes?.addDriverRoutesMarkers(routesUpdated, {
          isOnManageRoutes: true,
        });
        driverRoutes?.drawRoutes({ isOnManageRoutes: true });

        setIsOnManageRoutes(true);
        setBorderRoute(null);
        setReRenderCount((v) => v + 1);
      },
      [routes, borderRoute, driverRoutes],
    );

    const getRouteIdx = useCallback(
      (route: IDriverRoute, routeArrIdx: number) => {
        if (isOnlyOneTaskManageRoutes) return '';
        if (isOnManageRoutes) return route.update_sort_id || '';
        return routeArrIdx + 1;
      },
      [isOnlyOneTaskManageRoutes, isOnManageRoutes],
    );

    const headerComponent = useMemo(() => {
      const { display_name } = driverData;
      return (
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          sx={{ background: themes.bg.white, p: 2 }}
        >
          <ToolTipText
            text={display_name || '-'}
            maxLength={25}
            textNode={(v) => (
              <Typography color={themes.color.violet500} variant='h4'>
                {v}
              </Typography>
            )}
          />
          <Stack
            ml='auto !important'
            direction='row'
            maxHeight={themes.spacing(3)}
          >
            <ElementSelect
              paperProps={{ sx: { width: 120, borderRadius: '10px' } }}
              iconButtonProps={{ sx: { width: '30px !important' } }}
              elementSelect={() => (
                <MoreHorizIcon
                  sx={{ color: themes.color.violet500, fontSize: 18 }}
                />
              )}
              onChange={(o) => {
                if (o.value === 'send-alert') {
                  setOpenSendAlert(true);
                }
                if (o.value === 'offline-driver') {
                  showAlert({
                    ...alertParams.warning,
                    description:
                      'You are about to offline this driver.\n Are you sure you want to continue?',
                    cancelText: 'Cancel',
                    onOk: () => offlineDriverMutation({ id: driverData.id }),
                  });
                }
              }}
              options={[
                { label: 'Send Alert', value: 'send-alert' },
                { label: 'Offline Driver', value: 'offline-driver' },
              ].filter((o) =>
                !isShowOfflineDriver ? o.value !== 'offline-driver' : o,
              )}
            />
            <IconButton
              onClick={() => handleCloseManageRoutes()}
              sx={{
                width: '30px',
              }}
            >
              <Icon name='close' size={10} color={themes.color.violet500} />
            </IconButton>
          </Stack>
        </Stack>
      );
    }, [driverData, isOnManageRoutes]);

    const listRoutesComponent = useMemo(
      () => (
        <Stack height='100%' p={1.6}>
          {!isOnlyOneTaskManageRoutes && driverRoutesResp.length ? (
            <Stack
              width='100%'
              sx={{
                background: themes.bg.iceGray,
                borderRadius: '4px',
                marginBottom: '16px !important',
                color: themes.color.violet500,
              }}
              textAlign='center'
            >
              <Typography p={0.4}>
                Drag & drop to put tasks in order or{' '}
                <span
                  role='button'
                  tabIndex={0}
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() =>
                    optimizedRoutesMutation({ driver_id: driverData.id })
                  }
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      optimizedRoutesMutation({
                        driver_id: driverData.id,
                      });
                    }
                  }}
                >
                  Optimize Route
                </span>
              </Typography>
            </Stack>
          ) : (
            ''
          )}
          <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            sensors={sensors}
            modifiers={[
              restrictToVerticalAxis,
              restrictToFirstScrollableAncestor,
            ]}
          >
            <SortableContext
              items={routes.map((r) => r.id)}
              strategy={verticalListSortingStrategy}
            >
              <Stack
                height='100%'
                sx={{
                  background: themes.bg.lightPurple,
                  boxSizing: 'border-box',
                  overflowAnchor: 'none',
                }}
              >
                <Virtuoso
                  style={{
                    height: '100%',
                    boxSizing: 'border-box',
                  }}
                  data={routes}
                  className='customized-scrollbar'
                  itemContent={(index, route) => (
                    <SortableTask
                      key={route.id}
                      route={route}
                      index={index}
                      borderRoute={borderRoute}
                      getRouteIdx={getRouteIdx}
                    />
                  )}
                />
                <DragOverlay
                  dropAnimation={defaultDropAnimation}
                  modifiers={[
                    restrictToVerticalAxis,
                    restrictToFirstScrollableAncestor,
                  ]}
                >
                  {activeId
                    ? (() => {
                        const route = routes.find((r) => r.id === activeId);
                        return (
                          <SortableTask
                            index={route.id}
                            key={route.id}
                            route={route}
                            borderRoute={borderRoute}
                            overlay
                          />
                        );
                      })()
                    : null}
                </DragOverlay>
              </Stack>
            </SortableContext>
          </DndContext>
        </Stack>
      ),
      [
        reReRenderCount,
        isOnManageRoutes,
        borderRoute,
        driverData.id,
        isOnlyOneTaskManageRoutes,
        activeId,
        routes,
      ],
    );

    const bottomButtonsComponent = useMemo(() => {
      if (!isOnManageRoutes) return '';
      return (
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          justifyContent='space-between'
          sx={{ background: themes.bg.white, p: 1.6 }}
        >
          <Button
            buttonType='text'
            fullWidth
            rounder10
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
              alignItems: 'center',
            }}
            onClick={() => {
              showAlert({ ...alertParams.warnDark, onOk: handleReverseRoute });
            }}
          >
            <Icon
              name='arrow-go-back'
              size={16}
              color={themes.bg.darkPurple}
              style={{
                marginBottom: 2,
              }}
            />
            <Typography
              sx={{
                color: themes.color.violet500,
              }}
            >
              Reverse Route
            </Typography>
          </Button>
          <Button
            buttonType='primary-dark'
            fullWidth
            rounder10
            disabled={routes.filter((r) => !!r.update_sort_id).length === 0}
            loading={isLoadingUpdateRoutes}
            onClick={handleSetRoute}
          >
            Update Route
          </Button>
        </Stack>
      );
    }, [routes, isOnManageRoutes, isLoadingUpdateRoutes]);

    return (
      <StyledTaskDetail>
        {headerComponent}
        {isFirstLoadingManageRoutes ? (
          <FirstLoading />
        ) : (
          <>
            {listRoutesComponent}
            {bottomButtonsComponent}
          </>
        )}
        {openSendAlert && (
          <DialogSendAlert
            open={openSendAlert}
            onClose={() => setOpenSendAlert(false)}
          />
        )}
        {isLoadingOptimizeRoute && <OptimizeRouteLoading />}
      </StyledTaskDetail>
    );
  },
);
