import { Stack, Typography } from '@mui/material';
import themes from '~/themes';
import Button, { CloseButton } from '~/components/common/Button';
import { IntegrationType } from '~/models/stores';
import { Stepper } from '../Stepper';

type AddStoreLayoutProps = {
  step: number;
  isEnabledCloseAndUpdateStore?: boolean;
  onClose: () => void;
  onNext?: () => void;
  onBack?: () => void;
  loading?: boolean;
  storeType?: string;
};

export const AddStoreLayout: React.FC<AddStoreLayoutProps> = ({
  step,
  onClose,
  onNext,
  onBack,
  children,
  loading,
  storeType = IntegrationType.Breadstack,
}) => (
  <>
    <Stack
      sx={{
        p: themes.spacing(1.6),
        borderBottom: '1px solid #E5E3F4',
        position: 'sticky',
        top: 0,
        zIndex: 10,
        backgroundColor: 'white',
      }}
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 500,
        }}
      >
        Add store
      </Typography>
      <CloseButton background='white' iconSize={11} onClick={onClose} />
    </Stack>
    <Stack
      sx={{
        p: themes.spacing(1.6),
        gap: themes.spacing(2.4),
      }}
    >
      <Stepper activeStep={step} storeType={storeType} />
      <Stack>{children}</Stack>
    </Stack>
    <Stack
      sx={{
        p: themes.spacing(1.6),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'sticky',
        bottom: 0,
        zIndex: 10,
        backgroundColor: 'white',
        borderTop: '1px solid #E5E3F4',
      }}
    >
      <Stack>
        {step > 1 && (
          <Button
            buttonType='text'
            noRounder
            sx={{
              minWidth: 'unset !important',
              padding: '4px 16px',
            }}
            onClick={onBack}
          >
            <Typography
              sx={{
                color: themes.color.violet500,
                fontWeight: 600,
              }}
            >
              Back
            </Typography>
          </Button>
        )}
      </Stack>
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <Button
          buttonType='text'
          noRounder
          sx={{
            minWidth: 'unset !important',
            padding: '4px 16px',
          }}
        >
          <Typography
            sx={{
              color: themes.color.violet500,
              fontWeight: 500,
            }}
            onClick={onClose}
          >
            Cancel
          </Typography>
        </Button>
        <Button
          noRounder
          buttonType='primary-dark'
          sx={{
            minWidth: 'unset !important',
            padding: '4px 16px',
          }}
          onClick={onNext}
          loading={loading}
        >
          <Typography>{step === 3 ? 'Add Now' : 'Continue'}</Typography>
        </Button>
      </Stack>
    </Stack>
  </>
);
