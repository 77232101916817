import { Box, Grid, Stack, Typography } from '@mui/material';
import themes, { styled } from '~/themes';
import { Icon } from '~/components/common/Icon';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { IPlanTier } from '~/models/plan';
import { StyledPlanItemContainer } from '../style';

type TaskQuotaProps = {};

export const StyledTextValue = styled(Typography)(() => ({
  fontSize: 14,
  color: themes.color.violet500,
}));

export const TaskQuota: React.FC<TaskQuotaProps> = () => {
  const { additionalTask, org, currentPlan, isBundlePlan } = usePaymentInfo();

  return (
    <StyledPlanItemContainer>
      <Stack direction='row' spacing={0.5}>
        <Typography
          fontWeight={500}
          fontSize={18}
          color={themes.color.violet500}
        >
          Task Quota
        </Typography>
      </Stack>
      <Grid
        container
        sx={{
          mt: 1.6,
        }}
      >
        <Grid item xs={12} sm={6}>
          <Stack direction='row'>
            <Box>
              <Typography color='#717D96' fontSize={12} mb={0.4}>
                Included tasks
              </Typography>
              <StyledTextValue>
                {isBundlePlan
                  ? 'Unlimited'
                  : `${org.limit_task || 50} tasks/mo`}
              </StyledTextValue>
            </Box>
            <Box sx={{ ml: 10 }}>
              <Typography color='#717D96' fontSize={12} mb={0.4}>
                Additional tasks
              </Typography>
              {currentPlan.tier === IPlanTier.Free ? (
                <StyledTextValue>Not available</StyledTextValue>
              ) : (
                <StyledTextValue>
                  {isBundlePlan ? '-' : additionalTask || '0'}
                </StyledTextValue>
              )}
            </Box>
          </Stack>
        </Grid>
      </Grid>
      {!isBundlePlan && (
        <Stack
          mt={1.6}
          direction='row'
          alignItems='center'
          spacing={0.5}
          p={0.8}
          sx={{
            borderRadius: '4px',
            backgroundColor: 'rgba(243, 186, 80, 0.20)',
          }}
        >
          <Icon
            size={16}
            name={currentPlan.tier === IPlanTier.Free ? 'info-yellow' : 'info'}
            useBackgroundImg
            color='inherit'
          />
          <Stack flex={1}>
            {currentPlan.tier === IPlanTier.Free ? (
              <Typography color={themes.color.violet500}>
                Upgrade your plan to increase the task limit
              </Typography>
            ) : (
              <Typography color={themes.color.violet500}>
                Additional tasks will be charged at the end of the billing cycle
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
    </StyledPlanItemContainer>
  );
};
