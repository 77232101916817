import { Typography } from '@mui/material';
import { Form } from 'formik';
import { styled } from '~/themes';

export const StyledHeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 'bold',
  color: theme.color.violet900,
  marginTop: theme.spacing(4),
}));

export const StyledStoreForm = styled(Form)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
}));
