import {
  createTheme,
  ThemeOptions as _ThemeOptions,
  Theme as _Theme,
} from '@mui/material/styles';
import { createStyled } from '@mui/system';

interface ThemeOptions extends _ThemeOptions {}
export interface Theme extends _Theme {}

export const colors = {
  primaryOrange: '#FA8C73',
  lightOrange: '#FA8C73',
  violet900: '#160E4D',
  violet700: '#121142',
  violet600: '#BFC3E0',
  violet500: '#33363D',
  violet300: '#E9E7F6',
  darkViolet: '#9C9CE8',
  lightViolet: '#F8F8FC',
  breadstackBlue: '#2834C5',
  red: '#F55073',
  pink: '#F75879',
  borderRed: '#E35069',
  lightRed: '#FFE6EC',
  green: '#73D6BA',
  green300: '#74D6BA',
  greenInsight: '#0FD912',
  lightGreen: '#EAFFF9',
  yellow: '#FFC149',
  orange: '#F7982A',
  lightYellow: '#FFF8ED',
  black: '#000000',
  gray900: '#797979',
  gray600: '#C9C9C9',
  gray300: '#F8F8F8',
  gray500: '#9E9E9E',
  grayPlaceHolder: 'rgba(51, 54, 61, 0.40)',
  white: '#FFFFFF',
  success: '#1CB69A',
  darkButton: '#150E4A',
};

export const bgColors = {
  main: '#FCFCFF',
  white: '#fff',
  midPurple: '#E9E7F6',
  lightPurple: '#F8F8FC',
  lightPink: '#FFF4F6',
  darkPurple: '#160E4D',
  gray900: '#797979',
  gray600: '#C9C9C9',
  gray300: '#F8F8F8',
  green: '#73D6BA',
  greenInsight: '#0FD912',
  lightGreen: '#EAFFF9',
  primaryOrange: '#FA8C73',
  lightOrange: '#FFF7F2',
  midOrange: '#FFE4D6',
  breadstack: '#2933C5',
  purpleOutline: '#BFC3E0',
  yellow: '#FFC149',
  lightYellow: '#FFF8ED',
  red: '#F64066',
  divider: '#e0e0e0',
  image: '#2E2E2E',
  fee: '#E8E7F6',
  greenBackground: '#E3FFF7',
  lightBlue: '#F5F9FF',
  lightRed: '#FFE6EC',
  lightPink500: 'rgba(242, 72, 34, 0.10)',
  iceGray: '#E2E7F0',
};

declare module '@mui/material/styles/createTheme' {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  interface Theme {
    bg: typeof bgColors;
    color?: typeof colors;
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  interface ThemeOptions {
    bg?: any;
    color?: any;
    // breakpoints?: BreakpointsOptions & { smallScreen: number };
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    ss: true;
  }
}

const themes: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 730, // mobile
      lg: 1024, // tablet
      xl: 1536,
      ss: 1440, // small screen (15 inch) breakpoint
    },
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  spacing: 10,
  bg: bgColors,
  color: colors,

  palette: {
    primary: {
      main: colors.primaryOrange,
      light: colors.lightOrange,
      dark: colors.black,
    },
    text: {
      primary: colors.violet900,
    },
    secondary: {
      main: colors.violet900,
      light: colors.lightViolet,
      dark: colors.black,
    },
  },
  typography: {
    fontFamily: ['DM Sans'].join(','),
    h1: {
      color: colors.violet900,
      fontWeight: 'bold',
      fontSize: 28,
    },
    h2: {
      color: colors.violet900,
      fontWeight: 'bold',
      fontSize: 22,
    },
    h3: {
      color: colors.violet900,
      fontWeight: 'bold',
      fontSize: 18,
    },
    h4: {
      color: colors.violet900,
      fontWeight: 'bold',
      fontSize: 16,
    },
    h5: {
      fontWeight: 500,
      fontSize: 14,
      color: colors.violet900,
    },
    subtitle1: {
      fontWeight: 'bold',
    },
    subtitle2: {
      fontWeight: 500,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
    },
    fontWeightExtraBold: 800,
    fontWeightSemiBold: 600,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          'fontSize': 14,
          'lineHeight': 1,
          'borderRadius': 18,
          'minHeight': 35,
          'backgroundColor': bgColors.midPurple,
          'color': colors.violet900,
          'textTransform': 'unset',
          '&.loading': {
            backgroundColor: `${bgColors.midPurple} !important`,
            color: `${colors.violet900} !important`,
            opacity: 0.4,
          },
          '&.text': {
            'backgroundColor': 'transparent',
            'color': colors.primaryOrange,
            ':hover': {
              backgroundColor: 'transparent',
            },
          },
          ':disabled': {
            color: colors.gray600,
            backgroundColor: bgColors.gray300,
          },
          ':hover': {
            backgroundColor: bgColors.midPurple,
          },
        },
        containedPrimary: {
          'backgroundColor': `${bgColors.primaryOrange}`,
          'color': colors.white,
          'fontWeight': 'normal',
          '&.loading': {
            backgroundColor: `${bgColors.primaryOrange} !important`,
            color: `${colors.white} !important`,
            opacity: 0.4,
          },
          ':hover': {
            backgroundColor: bgColors.primaryOrange,
          },
          ':disabled': {
            color: colors.gray600,
            backgroundColor: bgColors.gray300,
          },
        },
        containedSecondary: {
          'backgroundColor': bgColors.midOrange,
          'color': colors.primaryOrange,
          'fontWeight': 'normal',
          ':hover': {
            backgroundColor: bgColors.midOrange,
            color: colors.primaryOrange,
          },
          ':focus': {
            backgroundColor: bgColors.midOrange,
            color: colors.primaryOrange,
          },
          '&.loading': {
            backgroundColor: `${bgColors.midOrange} !important`,
            color: `${colors.primaryOrange} !important`,
            opacity: 0.8,
          },
          ':disabled': {
            color: colors.gray600,
            backgroundColor: bgColors.gray300,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          'alignItems': 'center',
          'minHeight': 35,
          'maxHeight': 35,
          'background': bgColors.lightPurple,
          'borderRadius': '18px !important',
          'paddingRight': '0 !important',
          'boxSizing': 'border-box',
          'color': colors.black,
          'lineHeight': 1,
          'transition': 'border-color 225ms ease-in-out',
          ':disabled': {
            borderColor: bgColors.gray600,
            background: bgColors.gray300,
            color: colors.gray600,
            borderWidth: 1,
          },
        },
        input: {
          '&:-webkit-autofill': {
            borderRadius: 18,
            WebkitBoxShadow: `0 0 0 1000px ${bgColors.lightPurple} inset`,
          },
          'boxSizing': 'border-box',
          'fontSize': 14,
          'padding': 8,
          'paddingLeft': 15,
          'paddingRight': '8px !important',
          '::placeholder': {
            opacity: 1,
          },
          // ':disabled': {
          //   WebkitTextFillColor: colors.black,
          // },
        },
        formControl: {
          'label + &': {
            marginTop: 0,
          },
        },
        multiline: {
          minHeight: 'unset',
          maxHeight: 'unset',
          borderRadius: 10,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ':error': {
            color: colors.red,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
          backgroundColor: bgColors.darkPurple,
          padding: 10,
          textAlign: 'left',
          borderRadius: '10px',
        },
        arrow: {
          color: colors.violet900,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: 10,
          paddingBottom: 10,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthXl: {
          '@media (min-width: 600px)': {
            paddingLeft: 60,
            paddingRight: 60,
            maxWidth: 'unset',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          borderWidth: 0,
          backgroundColor: colors.white,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
          color: colors.black,
        },
        footer: {
          fontSize: 14,
          color: colors.black,
          fontWeight: 'inherit',
          borderBottom: 'none',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        footer: {
          ':last-child': {
            'fontWeight': 600,
            '& > td': {
              paddingTop: 0,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.violet900,
          },
          '&.Mui-error': {
            borderColor: `${colors.borderRed} !important`,
          },
        },
        notchedOutline: {
          borderWidth: '1px !important',
          borderColor: colors.violet300,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          paddingRight: '35px !important',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingTop: '5px !important',
        },
        option: {
          '&.MuiAutocomplete-option[aria-selected="true"]': {
            background: `${bgColors.midPurple} !important`,
          },
          'borderRadius': '15px',
        },
        paper: {
          boxShadow: '0px 1px 6px #160E4D14',
          borderRadius: '15px',
          background: colors.white,
          padding: '0px 10px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: bgColors.midPurple,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: `${colors.black} !important`,
          fontSize: 12,
          marginBottom: 5,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 1px 6px #160E4D14',
          borderRadius: '18px',
          background: colors.white,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 10,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          'borderRadius': '18px',
          '&.Mui-selected': {
            'backgroundColor': bgColors.midPurple,
            '&:focus': {
              backgroundColor: bgColors.midPurple,
            },
            '&:hover': {
              backgroundColor: bgColors.midPurple,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          paddingRight: '30px !important',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          width: 20,
          height: 20,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          background: '#E5E5E5',
        },
        text: {
          borderRadius: '5px',
          fontSize: 12,
          lineHeight: 1.43,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          'color': `${colors.violet900} !important`,
          '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path': {
            color: colors.violet600,
            stroke: colors.violet600,
            strokeWidth: 0.1,
          },
        },
      },
    },
  },
  foreground: {
    black: '',
    black4: '',
    grey: '',
    white: '',
    white8: '',
  },
  background: {
    pageBg: '',
    sectionBg: '',
    outlineDivider: '',
    sectionBg5: '',
  },
  accent: {
    blue: '',
    green: '',
    red: '',
    orange: '',
    yellow: '',
    purple: '',
    teal: '',
  },
  componentColors: {
    disabled: '',
    checkboxBorder: '',
    iconDropdown: '',
    listScrollBar: '',
    horizontalLine: '',
    lightGrey: '',
    optionBg: '',
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getTypoProperties(fontSize: number): {
    fontSize: string;
    lineHeight: string;
    letterSpacing: string;
  } {
    throw new Error('Function not implemented.');
  },
};

export const styled = createStyled({ defaultTheme: createTheme(themes) });

export default createTheme(themes);
