import React, { useCallback, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { IconButton, Stack, Typography } from '@mui/material';
import Button from '~/components/common/Button';
import { Field } from '~/components/common/Formik';
import { fromQueryString } from '~/utils/queryString';
import themes, { styled } from '~/themes';
import { debounce, isEqual } from 'lodash';
import SearchIcon from '~/assets/images/icons/search-line.svg';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  'width': theme.spacing(2.2),
  'height': theme.spacing(2.2),
  'marginRight': theme.spacing(-0.8),
  '& > img': {
    width: 16,
    height: 16,
  },
}));

export const StyledFilterStack = styled(Stack)(({ theme }) => ({
  'flexDirection': 'row',
  'alignItems': 'center',
  'flexWrap': 'wrap',
  'rowGap': theme.spacing(2),
  'columnGap': theme.spacing(0.8),
  '& > div': {
    '&:first-of-type': {
      width: 300,
    },
    'width': 160,
  },
  [theme.breakpoints.down('md')]: {
    '& > *': {
      '&:not(:first-of-type)': {
        display: 'none',
      },
    },
    '& > button': {
      display: 'none',
    },
    '& > div': {
      '&:first-of-type': {
        width: '100%',
        marginRight: 0,
      },
    },
  },
}));

interface AddressFilterFormProps {
  onSubmit?: (values: any) => void;
  onClear?: () => void;
  onClearAll?: () => void;
  stateCities: {
    city?: string[];
    state_info?: {
      state?: string;
      state_name?: string;
    };
  }[];
}

const defaultValues = {
  search: '',
  city: '',
  state: '',
};

export const StyledForm = styled(Form)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const AddressFilterForm: React.FC<AddressFilterFormProps> = ({
  stateCities,
  onSubmit,
  onClear,
  onClearAll,
}) => {
  const formRef = useRef<any>(null);
  const location = useLocation();

  const { search, city, state } = fromQueryString(location.search);

  const initialValues = {
    search: search || '',
    city: city || '',
    state: state || '',
  };

  const handleSearch = useCallback(
    debounce(() => {
      formRef.current.handleSubmit();
    }, 600), // Delay 500ms
    [],
  );

  const statesOptions = useMemo(
    () =>
      stateCities.map(({ state_info }) => ({
        label: state_info.state_name,
        value: state_info.state,
      })),
    [stateCities],
  );

  const renderState = useCallback(
    (setFieldValue) => (
      <Field.Select
        key='state'
        placeholder='Province/State'
        name='state'
        options={statesOptions}
        sxPlaceholder={{
          color: `${themes.color.grayPlaceHolder} !important`,
        }}
        onChange={() => {
          setFieldValue('city', '');
          formRef.current.handleSubmit();
        }}
      />
    ),
    [statesOptions],
  );

  const renderCity = useCallback(
    (values) => {
      let citiesOptions = [];
      const cities = stateCities.find(
        (e) => e.state_info?.state === values.state,
      )?.city;
      if (cities?.length)
        citiesOptions = cities.map((e) => ({
          label: e,
          value: e,
        }));

      return (
        <Field.Select
          placeholder='City'
          name='city'
          options={citiesOptions}
          sxPlaceholder={{
            color: `${themes.color.grayPlaceHolder} !important`,
          }}
          onChange={() => formRef.current.handleSubmit()}
        />
      );
    },
    [stateCities],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {({ values, setFieldValue, resetForm }) => (
        <StyledForm>
          <StyledFilterStack>
            <Field.SearchInput
              name='search'
              placeholder='Search'
              onClear={() => {
                onClear();
                setFieldValue('search', '');
              }}
              onChange={(e) => {
                setFieldValue('search', e.target.value);
                handleSearch();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  formRef.current.handleSubmit();
                }
              }}
              prefix={
                <StyledIconButton>
                  <img
                    alt='search-icon'
                    src={SearchIcon}
                    width={22}
                    height={22}
                  />
                </StyledIconButton>
              }
              sx={{
                'paddingLeft': '8px !important',
                '& .MuiOutlinedInput-input': {
                  '&::placeholder': {
                    color: 'rgba(51, 54, 61, 0.40) !important',
                  },
                },
                '& .MuiButtonBase-root': {
                  marginRight: '4px !important',
                },
                '& .MuiInputAdornment-root': {
                  marginRight: '0px !important',
                  marginLeft: '0px !important',
                },
                '& input': {
                  paddingRight: '0 !important',
                },
                'backgroundColor': '#F8F8FC',
              }}
              showSeachIcon={false}
            />
            {renderState(setFieldValue)}
            {renderCity(values)}
            {!isEqual(values, defaultValues) && (
              <Button
                buttonType='text'
                onClick={() => {
                  resetForm();
                  onClearAll();
                }}
                sx={{
                  '&.MuiButton-text': {
                    minWidth: 'unset !important',
                  },
                }}
              >
                <Typography
                  sx={{
                    color: themes.color.violet500,
                    fontWeight: 500,
                  }}
                >
                  Clear
                </Typography>
              </Button>
            )}
          </StyledFilterStack>
        </StyledForm>
      )}
    </Formik>
  );
};
