import { useMutation, useQuery } from '@tanstack/react-query';
import httpClient from '~/lib/client/httpClient';
import { toQueryString } from '~/utils/queryString';
import { TaskLogCreatedBy, TaskStatus } from '~/pages/LiveTracking/types';
import { ITabValue } from '~/models/shared';
import endpoints from './endpoints';
import { queryKeys } from './queryKeys';

export const getDistanceFromDriverToPickup = (
  params,
): Promise<{ distance: number[] }> =>
  httpClient.json.get(`distance_to_pickup?${toQueryString(params)}`);

export const getTaskLog = ({
  id,
}: {
  id: string;
}): Promise<TaskLogCreatedBy[]> =>
  httpClient.json.get(`${endpoints.task.common}/${id}/logs`);

export const getIndependentDrivers = (): Promise<any> =>
  httpClient.json.get('ind_drivers');

export const getSuggestionAddress = (
  query: string,
  include_address_book: boolean = false,
): Promise<any> =>
  httpClient.json.get(
    `places/search?query=${query}${
      include_address_book ? '&include_address_book=True' : ''
    }`,
  );

export const getSuggestionAddressDetail = (placeId: string): Promise<any> =>
  httpClient.json.get(
    `/places/detail?place_id=${placeId}&fields=place_id&fields=address_component&fields=vicinity&fields=name&fields=international_phone_number`,
  );

export const uploadTasks = (fileTask: any): Promise<any> =>
  httpClient.formData.post('upload_task', fileTask);

export const exportTasks = (params: any): Promise<any> => {
  if (params.statuses === ITabValue.Ongoing) {
    params.statuses = [
      TaskStatus.Unassigned,
      TaskStatus.PendingPickup,
      TaskStatus.Delivering,
      TaskStatus.Returning,
    ];
  }
  if (params.statuses === ITabValue.Completed) {
    params.statuses = [
      TaskStatus.Cancelled,
      TaskStatus.Completed,
      TaskStatus.Returned,
    ];
  }

  if (params.reassigned_status) {
    params.statuses = [];
  }

  return httpClient.json.post('/tasks/export', params);
};

export const getTaskUploadedInfo = (
  id: string,
): Promise<{
  status?: string;
  created_at?: string;
  updated_at?: string;
  progress?: string;
  id?: string;
  failed_info?: Array<{ [k: string]: any }>;
}> => httpClient.json.get(`upload_task/${id}`);

export const useGetDistanceFromDriverToPickupQuery = ({ params, enabled }) =>
  useQuery({
    queryKey: [queryKeys.task.getDistanceFromDriverToPickup, params.driver_id],
    queryFn: () => getDistanceFromDriverToPickup(params),
    initialData: { distance: [] },
    keepPreviousData: true,
    cacheTime: 1 * 60 * 1000,
    enabled,
  });

export const useGetIndependentDrivers = (data = { enabled: true }) => {
  const { enabled } = data;
  return useQuery({
    queryKey: [queryKeys.task.getIndependentDrivers],
    queryFn: () => getIndependentDrivers(),
    initialData: [],
    enabled,
  });
};

export const useUploadTasks = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: uploadTasks,
    onSuccess,
    onError,
  });
};

export const useGetTaskUploadedInfo = (params) => {
  const { id, onSuccess, onError } = params;
  return useQuery({
    queryKey: [queryKeys.task.getIndependentDrivers, id],
    queryFn: () => getTaskUploadedInfo(id),
    enabled: !!id,
    initialData: { failed_info: [] },
    refetchInterval: 2000,
    onSuccess,
    onError,
  });
};

export const useExportTasks = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: exportTasks,
    onSuccess,
    onError,
  });
};
