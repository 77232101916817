/* eslint-disable no-nested-ternary */
import { Box, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { styled } from '~/themes';
import AvailableTaskIcon from '~/assets/images/icons/available-task.svg';
import AdditionalTaskChargeIcon from '~/assets/images/icons/additional-task-charge.svg';
import AvailableUserIcon from '~/assets/images/icons/available-user.svg';
import AvailableDriverIcon from '~/assets/images/icons/available-driver.svg';
import Button from '~/components/common/Button';
import { useAuth } from '~/providers/AuthProvider';
import { USER_ROLE } from '~/constants/userManagement';
import { useWorkspaceNavigate } from '~/hooks/useWorkspaceNavigate';
import { has } from 'lodash';
import { IPlanTier } from '~/models/plan';

const StyledContainer = styled(Stack)(({ theme }) => ({
  fontFamily: "'Lato', sans-serif",
  flexDirection: 'column',
  background: theme.color.white,
  borderRadius: theme.spacing(0.6),
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  gap: theme.spacing(0.5),
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: '#717D96',
  fontSize: 12,
}));

const StyledItem = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: theme.color.black,
  fontWeight: 'bold',
  alignItems: 'center',
}));

export const StyledItemNumber = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isAlerted',
})<{ isAlerted?: boolean }>(({ theme, isAlerted }) => ({
  gap: theme.spacing(1),
  color: '#33363D',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: 14,
  ...(isAlerted && {
    color: '#F24822',
  }),
}));

interface ITierUsage {}

const TIER_COST = {
  0: 0,
  1: 0.08,
  2: 0.06,
};

const TierUsage: React.FC<ITierUsage> = () => {
  const {
    org,
    isAdvancePlan,
    isStandardOrAdvancePlan,
    currentPlan,
    setIsOpenUpSellDialog,

    isLoadingPlans,
    isFetchingOrg,
  } = usePaymentInfo();

  const isStarterPlan = has(org, 'id') && !has(org, 'price_id');
  const isBundlePlan =
    org?.tier === IPlanTier.Advance && !has(org, 'subscription_id');

  const { account } = useAuth();
  const navigate = useWorkspaceNavigate();

  const { driver_count: driverCount, user_count: userCount } = org;

  const onClickUpgrade = () => {
    if (org.tier === 0) {
      setIsOpenUpSellDialog(true);
    } else {
      navigate('/setting/plans');
    }
  };

  const starterPlanComponent = useMemo(() => {
    const availableTask = Math.max(50 - org.monthly_created_task, 0);

    return (
      <Stack spacing={1}>
        <StyledTitle>Available no. of tasks</StyledTitle>
        <StyledItem>
          <StyledItemNumber isAlerted={availableTask === 0}>
            <img
              width={16}
              height={16}
              src={AvailableTaskIcon}
              alt='available_task_icon'
            />
            {availableTask || 0}
          </StyledItemNumber>
        </StyledItem>

        <StyledTitle>Available no. of drivers</StyledTitle>
        <StyledItem>
          <StyledItemNumber isAlerted={driverCount > 0}>
            <img
              width={16}
              height={16}
              src={AvailableDriverIcon}
              alt='AvailableDriverIcon'
            />
            {driverCount > 0 ? 0 : 1}
          </StyledItemNumber>
        </StyledItem>
        <StyledTitle>Available no. of users</StyledTitle>
        <StyledItem>
          <StyledItemNumber isAlerted={userCount > 0}>
            <img width={16} height={16} src={AvailableUserIcon} alt='User' />
            {userCount > 0 ? 0 : 1}
          </StyledItemNumber>
        </StyledItem>
      </Stack>
    );
  }, [driverCount, org.monthly_created_task, org.limit_task, userCount]);

  const standardOrAdvancePlanComponent = useMemo(
    () => (
      <Stack spacing={1}>
        <StyledTitle>Delivery task usage</StyledTitle>
        <StyledItem>
          <StyledItemNumber>
            <img
              width={16}
              height={16}
              src={AvailableTaskIcon}
              alt='AvailableTaskIcon'
            />
            {org.monthly_created_task || 0}
          </StyledItemNumber>
        </StyledItem>
        <StyledTitle>Additional tasks charge</StyledTitle>
        <StyledItem>
          <StyledItemNumber>
            <img
              width={16}
              height={16}
              src={AdditionalTaskChargeIcon}
              alt='AdditionalTaskChargeIcon'
            />
            $
            {(
              Math.max(
                (org.monthly_created_task || 0) - (org.limit_task || 0),
                0,
              ) * (TIER_COST[currentPlan.tier] || 0)
            ).toFixed(2)}
          </StyledItemNumber>
        </StyledItem>
      </Stack>
    ),
    [org.monthly_created_task, org.limit_task, currentPlan],
  );

  if (isBundlePlan || isFetchingOrg || isLoadingPlans) return null;

  return (
    <StyledContainer>
      {isStarterPlan && starterPlanComponent}
      {isStandardOrAdvancePlan && standardOrAdvancePlanComponent}
      {!isAdvancePlan && (
        <Button
          disabled={account?.role === USER_ROLE.DISPATCHER}
          sx={{
            'borderRadius': '6px',
            'backgroundColor': '#2D91FC',
            'marginTop': 1,
            '&:hover': {
              backgroundColor: '#2D91FC',
            },
          }}
          onClick={onClickUpgrade}
        >
          Upgrade
        </Button>
      )}
    </StyledContainer>
  );
};

export default TierUsage;
