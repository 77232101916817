import {
  Cluster,
  MarkerClusterer,
  Renderer,
} from '@googlemaps/markerclusterer';
import { NavigateFunction } from 'react-router-dom';
import { BaseMarkers } from './BaseMarkers';
import { LocationInfo } from '../types';
import {
  DropOffInfoWindowContent,
  DropOffMarkerContent,
} from '../components/Markers/MarkerContents';
import { isInfoWindowOpen, isValidLatLngLiteral } from '../utils';

export class DropOffMarkers {
  public markerClusterer: MarkerClusterer;

  public markers: BaseMarkers;

  private map: google.maps.Map | null;

  private navigate: NavigateFunction;

  private dropOffMarkersEvents: {
    el: HTMLElement;
    handler: () => void;
  }[] = [];

  private googleMapEvents: google.maps.MapsEventListener[] = [];

  private fitBoundsPadding: google.maps.Padding;

  constructor(
    map: google.maps.Map | null,
    renderer: Renderer,
    configs: { fitBoundsPadding: google.maps.Padding },
    navigate: NavigateFunction,
  ) {
    this.map = map;
    this.markers = new BaseMarkers(map);
    this.fitBoundsPadding = configs?.fitBoundsPadding;

    this.markerClusterer = new MarkerClusterer({
      map: this.map,
      renderer,
      onClusterClick: (
        event: google.maps.MapMouseEvent,
        cluster: Cluster,
        m: google.maps.Map,
      ) => {
        m.fitBounds(cluster.bounds, this.fitBoundsPadding);
      },
    });

    this.navigate = navigate;
  }

  setFitBoundsPadding(fitBoundsPadding: google.maps.Padding) {
    this.fitBoundsPadding = fitBoundsPadding;
  }

  addMarkers(locations: LocationInfo[], isAddMarkerToCluster?: boolean) {
    if (!locations.length) return;
    const { lat, lng, id, task_id } = locations[0];
    if (!isValidLatLngLiteral({ lat, lng })) return;

    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: {
        lat,
        lng,
      },
      content: DropOffMarkerContent({ locations }),
    });

    this.markers.add({
      id,
      marker,
      locations,
    });

    if (isAddMarkerToCluster) {
      this.markerClusterer.addMarker(marker);
    }

    if (locations.length > 1) {
      const dropOffInfoWindow = new google.maps.InfoWindow({
        content: DropOffInfoWindowContent({
          locations,
          closeBtnId: `close_button_${id}`,
        }),
      });

      marker.addListener('click', () => {
        if (isInfoWindowOpen(dropOffInfoWindow)) {
          dropOffInfoWindow.close();
        } else {
          dropOffInfoWindow.open(this.map, marker);
        }
      });

      dropOffInfoWindow.addListener('domready', () => {
        const closeBtnEl = document.getElementById(`close_button_${id}`);
        const closeBtnClickHandler = () => {
          dropOffInfoWindow.close();
        };
        if (locations.length > 1) {
          closeBtnEl.addEventListener('click', closeBtnClickHandler);
          this.dropOffMarkersEvents = [
            ...this.dropOffMarkersEvents,
            { el: closeBtnEl, handler: closeBtnClickHandler },
          ];
        }

        locations.forEach((l) => {
          const dropOffItemEl = document.getElementById(l.id) as HTMLLIElement;
          const dropOffClickHandler = () => {
            this.navigate(`?task_id=${dropOffItemEl.getAttribute('data-id')}`);
            dropOffInfoWindow.close();
          };

          dropOffItemEl.addEventListener('click', dropOffClickHandler);
          this.dropOffMarkersEvents = [
            ...this.dropOffMarkersEvents,
            { el: dropOffItemEl, handler: dropOffClickHandler },
          ];
        });
      });

      const googleMapClickEventListener = google.maps.event.addListener(
        this.map,
        'click',
        () => {
          dropOffInfoWindow.close();
        },
      );
      this.googleMapEvents = [
        ...this.googleMapEvents,
        googleMapClickEventListener,
      ];
    } else {
      const markerClickHandler = () => {
        this.navigate(`?task_id=${task_id}`);
      };
      marker.addListener('click', markerClickHandler);
      this.dropOffMarkersEvents = [
        ...this.dropOffMarkersEvents,
        { el: marker, handler: markerClickHandler },
      ];
    }
  }

  showMarkers() {
    this.markerClusterer.addMarkers(this.markers.getAll().map((e) => e.marker));
  }

  hideMarkers() {
    this.dropOffMarkersEvents.forEach((e) =>
      e?.el?.removeEventListener('click', e.handler),
    );
    this.googleMapEvents.forEach((e) => google.maps.event.removeListener(e));
    this.googleMapEvents = [];
    this.dropOffMarkersEvents = [];
    this.markerClusterer.clearMarkers();
  }
}
