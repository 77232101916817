import { Stack } from '@mui/material';
import { styled } from '~/themes';

export const StyledPlanItemContainer = styled(Stack)(({ theme }) => ({
  boxShadow: '0px 2px 10px #EDF1FB',
  background: theme.color.white,
  height: 'fit-content',
  padding: theme.spacing(2.4),
}));

export const StyledPlanContainer = styled(Stack)(() => ({
  maxWidth: 1000,
  width: '100%',
  height: 'fit-content',
}));

export const StyledSubscriptionContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));
