import { CircularProgress, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { ConnectionStoreStatus } from '~/models/stores';
import themes, { styled } from '~/themes';
import NotConnectedIcon from '~/assets/images/icons/not-connected.svg';
import ConnectedIcon from '~/assets/images/icons/connected.svg';

type StoreConnectionProps = { status: ConnectionStoreStatus };
export const StyledConnectedIcon = styled('img')(() => ({
  width: 14,
  height: 14,
}));

export const StoreConnection: React.FC<StoreConnectionProps> = ({ status }) => {
  const contentComponent = useMemo(() => {
    if (status === ConnectionStoreStatus.Checking) {
      return (
        <>
          <CircularProgress
            sx={{
              width: '14px !important',
              height: '14px !important',
              color: themes.color.violet600,
            }}
            thickness={5}
          />
          <Typography>Checking connection…</Typography>
        </>
      );
    }
    if (status === ConnectionStoreStatus.Connected) {
      return (
        <>
          <StyledConnectedIcon alt='connection-icon' src={ConnectedIcon} />
          <Typography color='#74D6BA'>Connected</Typography>
        </>
      );
    }

    if (status === ConnectionStoreStatus.NotConnected) {
      return (
        <>
          <StyledConnectedIcon alt='connection-icon' src={NotConnectedIcon} />
          <Typography color='#F75879'>Not Connected</Typography>
        </>
      );
    }
    return '';
  }, [status]);

  return (
    <Stack spacing={0.5} direction='row' alignItems='center'>
      {contentComponent}
    </Stack>
  );
};
