import React, { useCallback, useMemo, useState } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Table, { TColumn } from '~/components/common/Table/Table';
import themes, { styled } from '~/themes';
import {
  Task as ITask,
  LocationInfo as ITaskLocation,
  TaskStatus,
} from '~/pages/LiveTracking/types';
import { convertedSortParams } from '~/utils/common';
import {
  formatDateTimeUTC,
  formatTaskDateTime,
  convertShortString,
} from '~/utils/formatter';
import InfoIcon from '~/assets/images/icons/info-gray.svg';
import Tag from '~/components/common/Tag';
import {
  addToQueryString,
  fromQueryString,
  omitFromQueryString,
} from '~/utils/queryString';
import { Rating } from '~/components/common/Rating';
import { ITabValue } from '~/models/shared';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import BackgroundImage from '~/assets/images/background/setup-task.png';
import clsx from 'clsx';
import moment from 'moment';
import CheckBox from '~/components/common/CheckBox';
import { uniqBy } from 'lodash';
import CloseIcon from '~/assets/images/icons/close-white.svg';
import CheckboxIcon from '~/assets/images/icons/checkbox.svg';
import Button from '~/components/common/Button';
import { useExportTasks } from '~/services/api/task';
import { StyledPanelDetailContainer } from '~/themes/style';
import { IStore } from '~/models/stores';
import { AssignDriver } from '../LiveTracking/components/TaskDetail/components/AssignDriver';

const EmptyStateData = (tabValue: ITabValue) => [
  {
    name: 'C-1010',
    status: tabValue === ITabValue.Completed ? 'completed' : 'delivering',
    expected_delivery_after: moment().set({ hour: 13, minute: 0, second: 0 }),
    expected_delivery_before: moment().set({ hour: 14, minute: 0, second: 0 }),
    executor: {
      display_name: 'Jane Doe',
    },
    pickup: {
      address_1: '1010 Kingsway',
      city: 'Burnaby',
      state: 'BC',
      postcode: 'V0H 0B0',
    },
    delivery: {
      address_1: '1010 W Georgia St',
      city: 'Vancouver',
      state: 'BC',
      postcode: 'V0Y 0G0',
    },
    rating: 3,
    updated_at: moment()
      .subtract({ day: 2 })
      .set({ hour: 10, minute: 10, second: 0 }),
  },
  { id: '_noData' },
];

export const StyledTaskId = styled(Stack)(({ theme }) => ({
  'flexDirection': 'row',
  'alignItems': 'center',
  '& > span': {
    marginRight: theme.spacing(0.5),
  },
}));

export const StyledAllTaskCompletedContainer = styled('div')(({ theme }) => ({
  'position': 'absolute',
  'display': 'flex',
  'flexDirection': 'column',
  'alignItems': 'center',
  'justifyContent': 'center',
  'padding': theme.spacing(2),
  'height': `calc(100% - ${theme.spacing(30)})`,
  'width': `calc(100% - ${theme.spacing(5)})`,
  'zIndex': 2,
  '& > h1': {
    marginTop: theme.spacing(3),
    fontSize: 14,
    paddingTop: 0,
    fontWeight: 400,
  },
  [theme.breakpoints.down('md')]: {
    '& > img:first-of-type': {
      maxWidth: '100%',
    },
  },
}));

interface ITaskTableProps {
  data?: ITask[];
  stores?: IStore[];
  isMultiStore?: boolean;
  isFullEmpty?: boolean;
  isLoading?: boolean;
  handleSort: (sortId: string, nextArrange: number) => void;
  isEmptyState?: boolean;
  renderEmpty?: React.ReactNode;
  // isOpenTask?: boolean;
}

export const TaskTable: React.FC<ITaskTableProps> = ({
  data = [],
  stores = [],
  isMultiStore,
  isLoading,
  handleSort,
  isFullEmpty,
  isEmptyState,
  renderEmpty,
  // isOpenTask,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isStarterPlan } = usePaymentInfo();
  const [tasksSelected, setTasksSelected] = useState<ITask[]>([]);
  const [openBulkAssign, setOpenBulkAssign] = useState(false);

  const taskIdsSelected = useMemo(
    () => tasksSelected?.map((task) => task.id),
    [tasksSelected],
  );

  const multiTaskIds = useMemo(() => {
    const TASK_STATUS = [
      TaskStatus.Unassigned,
      TaskStatus.PendingPickup,
      TaskStatus.Delivering,
      TaskStatus.Returning,
    ];
    return tasksSelected
      ?.filter((task) => TASK_STATUS?.includes(task.status))
      ?.map((task) => task.id);
  }, [tasksSelected]);

  const handleDownloadCSV = (csvString: string) => {
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Export Tasks.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const { mutate: exportTaskMutation, isLoading: isLoadingExport } =
    useExportTasks({
      onSuccess: (csvData) => {
        handleDownloadCSV(csvData?.csv);
        setTasksSelected([]);
      },
    });

  const { id: taskId, sort, sort_by, tab } = fromQueryString(location.search);
  const tabValue = (tab || ITabValue.Ongoing) as ITabValue;
  const dateFormats = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    nextWeek: 'MM/DD/YYYY',
    lastWeek: 'MM/DD/YYYY',
    sameElse: 'MM/DD/YYYY',
  };

  const onRowClick = useCallback(
    (item: { id: string }) => {
      let search;
      if (item.id === taskId) {
        search = omitFromQueryString(location.search, ['id']);
      } else {
        search = addToQueryString(location.search, {
          id: item.id,
        });
      }
      navigate({
        pathname: location.pathname,
        search,
      });
    },
    [location],
  );

  const clickableRowId = useMemo(
    () => taskId && data.findIndex((x) => x.id === taskId),
    [data, taskId],
  );

  const checkAllTask = useMemo(
    () => data.every((x) => taskIdsSelected.includes(x.id)),
    [data, taskIdsSelected],
  );

  const renderTaskId = useCallback(
    (item: ITask) => {
      const storeName =
        stores?.find((store) => store?.id === item.created_by)?.display_name ||
        '';
      const tooltipTitle = storeName ? `Storefront: ${storeName}` : '';
      return (
        <StyledTaskId>
          <CheckBox
            sxContainer={{
              marginRight: 0,
            }}
            checked={taskIdsSelected?.includes(item.id)}
            onChange={(event) => {
              const newVal = event.target.checked;
              if (newVal) {
                setTasksSelected((prev) => [...prev, item]);
              } else {
                setTasksSelected((prev) =>
                  prev.filter((task) => task.id !== item.id),
                );
              }
            }}
          />
          <Tooltip
            title={isMultiStore && tooltipTitle}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: themes.color.violet500,
                  borderRadius: '4px',
                  fontSize: 14,
                  fontWeight: 400,
                  padding: '7px 5px',
                },
              },
            }}
          >
            <Typography
              sx={{
                textDecorationLine:
                  item?.integration_type && tooltipTitle && isMultiStore
                    ? 'underline'
                    : '',
              }}
              data-testid={`task-id-${item.name}`}
            >
              {item.name}
            </Typography>
          </Tooltip>
        </StyledTaskId>
      );
    },
    [taskIdsSelected, setTasksSelected, stores, isMultiStore],
  );

  const renderPickup = useCallback(
    (item: ITask) => {
      const { status, skip_pickup } = item;
      if (skip_pickup)
        return <Typography color='rgba(51, 54, 61, 0.40)'>N/A</Typography>;

      if (
        status === TaskStatus.PendingPickup ||
        status === TaskStatus.Unassigned
      )
        return 'ASAP';

      if (status === TaskStatus.Delivering || status === TaskStatus.Returning)
        return item.picked_up_at ? (
          <>
            <div>{formatDateTimeUTC(item.picked_up_at)}</div>
            <div>{formatTaskDateTime(item.picked_up_at, dateFormats)}</div>
          </>
        ) : (
          <Typography color='rgba(51, 54, 61, 0.40)'>-</Typography>
        );

      return '';
    },
    [isStarterPlan, isFullEmpty],
  );

  const renderDeliveryBefore = useCallback(
    (item: ITask) => {
      const {
        status,
        expected_delivery_after,
        expected_delivery_before,
        is_active,
        estimated_delivery_time,
        delivery_arr_time,
      } = item;

      if (
        status === TaskStatus.PendingPickup ||
        status === TaskStatus.Unassigned
      ) {
        if (!expected_delivery_after && !expected_delivery_before)
          return 'ASAP';
        if (!expected_delivery_after && expected_delivery_before)
          return (
            <>
              <div>ASAP</div>
              <div>
                {formatTaskDateTime(expected_delivery_before, dateFormats)}
              </div>
            </>
          );
        return (
          <>
            <div>{formatDateTimeUTC(expected_delivery_before)}</div>
            <div>
              {formatTaskDateTime(expected_delivery_before, dateFormats)}
            </div>
          </>
        );
      }

      if (status === TaskStatus.Returning) return 'ASAP';
      if (status === TaskStatus.Delivering) {
        const formatDate = {
          sameDay: 'h:mm a[\n][Today]',
          nextDay: 'h:mm a[\n][Tomorrow]',
          lastDay: 'h:mm a[\n][Yesterday]',
          nextWeek: 'h:mm a[\n]MM/DD/YYYY',
          lastWeek: 'h:mm a[\n]MM/DD/YYYY',
          sameElse: 'h:mm a[\n]MM/DD/YYYY',
        };
        if (is_active)
          return estimated_delivery_time ? (
            <div style={{ whiteSpace: 'pre' }}>
              {moment()
                .add(estimated_delivery_time, 'm')
                .calendar(null, formatDate)}
            </div>
          ) : (
            '-'
          );
        return delivery_arr_time ? (
          <div style={{ whiteSpace: 'pre' }}>
            {moment().add(delivery_arr_time, 's').calendar(null, formatDate)}
          </div>
        ) : (
          '-'
        );
      }

      return '';
    },
    [isStarterPlan, isFullEmpty],
  );

  const renderDriver = useCallback((item: ITask) => {
    if (item.executor) {
      const maxLength = 22;
      const isShowTooltip = item?.executor?.display_name?.length > maxLength;
      return (
        <Tooltip title={isShowTooltip ? item.executor.display_name : ''}>
          <span>
            {convertShortString(item?.executor?.display_name || '', maxLength)}
          </span>
        </Tooltip>
      );
    }
    return <Typography color='rgba(51, 54, 61, 0.40)'>-</Typography>;
  }, []);

  const renderStatus = useCallback(
    (item: ITask) => (
      <Tag
        size='small'
        style={{ width: 'auto' }}
        tag={item.status}
        sx={{
          ...(isFullEmpty
            ? {
                background: 'rgba(0,0,0,0.1) !important',
                color: 'rgba(0,0,0,0.8) !important',
              }
            : {}),
          'fontWeight': 500,
          '& .MuiChip-label': {
            padding: '4px 8px',
          },
        }}
      />
    ),
    [isFullEmpty],
  );

  const renderLocation = useCallback((taskLocation: ITaskLocation) => {
    if (taskLocation) {
      const maxLength = 30;
      const locationLine1 = taskLocation?.address_1;
      const locationLine2 = `${taskLocation.city}, ${taskLocation.state} ${taskLocation.postcode}`;
      const isShowTooltip =
        locationLine1.length > maxLength || locationLine2.length > maxLength;

      return (
        <Tooltip
          title={
            isShowTooltip ? (
              <>
                <div>{locationLine1}</div>
                <div>{locationLine2}</div>
              </>
            ) : (
              ''
            )
          }
        >
          <div>
            <div>{convertShortString(locationLine1, maxLength)}</div>
            <div>{convertShortString(locationLine2, maxLength)}</div>
          </div>
        </Tooltip>
      );
    }
    return <Typography color='rgba(51, 54, 61, 0.40)'>N/A</Typography>;
  }, []);

  const renderRecipient = useCallback((customerName?: string, item?: ITask) => {
    const maxLength = 12;
    const isShowTooltip = customerName?.length > maxLength;

    return (
      <Stack
        flexDirection='row'
        gap={0.5}
        sx={{
          width: 120,
        }}
      >
        <Tooltip title={isShowTooltip ? <div>{customerName}</div> : ''}>
          <div>
            {customerName && (
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  wordBreak: 'break-all',
                  maxWidth: 100,
                }}
              >
                {customerName}
              </Typography>
            )}
          </div>
        </Tooltip>
        {item?.note && (
          <Tooltip
            title={item?.note}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: themes.color.violet500,
                  borderRadius: '4px',
                  fontSize: 14,
                  fontWeight: 400,
                  padding: '7px 5px',
                },
              },
            }}
          >
            <img
              width={16}
              height={16}
              src={InfoIcon}
              alt='info'
              style={{
                paddingTop: '2px',
              }}
            />
          </Tooltip>
        )}
      </Stack>
    );
  }, []);

  const renderDeliveryTime = useCallback((item: ITask) => {
    const { status, completed_at, returned_at, delivered_at } = item;

    let time = '';
    if (status === TaskStatus.Returned) {
      time = returned_at;
    }
    if (status === TaskStatus.Completed) {
      time = delivered_at;
    }
    if (status === TaskStatus.Cancelled) {
      time = completed_at;
    }

    return (
      <>
        <div>{formatDateTimeUTC(time)}</div>
        <div>{formatTaskDateTime(time, dateFormats)}</div>
      </>
    );
  }, []);

  const renderScheduledDeliveryTime = useCallback(
    (item: ITask) => {
      if (isFullEmpty) {
        return '1:00 pm - 2:00 pm Today';
      }
      if (isStarterPlan) {
        return '-';
      }
      const { expected_delivery_after, expected_delivery_before } = item;
      if (!expected_delivery_after && !expected_delivery_before) {
        return 'ASAP';
      }
      if (!expected_delivery_after && expected_delivery_before) {
        return (
          <>
            <div>ASAP</div>
            <div>
              {formatTaskDateTime(expected_delivery_before, dateFormats)}
            </div>
          </>
        );
      }

      return (
        <>
          <div>{`${formatDateTimeUTC(
            expected_delivery_after,
          )} - ${formatDateTimeUTC(expected_delivery_before)}`}</div>
          <div>{formatTaskDateTime(expected_delivery_before, dateFormats)}</div>
        </>
      );
    },
    [isStarterPlan, isFullEmpty],
  );

  const columns: TColumn[] = useMemo(() => {
    const d = [
      {
        id: 'integration_type',
        title: (
          <StyledTaskId>
            <CheckBox
              sxContainer={{
                marginRight: 0,
              }}
              checked={checkAllTask}
              onChange={(event) => {
                const newVal = event.target.checked;
                if (newVal) {
                  setTasksSelected((prev) => uniqBy([...prev, ...data], 'id'));
                } else {
                  setTasksSelected((prev) =>
                    prev.filter(
                      (current) =>
                        data.findIndex((x) => x.id === current.id) === -1,
                    ),
                  );
                }
              }}
            />
            Task/Order
          </StyledTaskId>
        ),
        sortable: true,
        renderComponent: renderTaskId,
        cellStyle: {
          height: 41,
          width: 120,
        },
      },
      {
        id: 'status',
        title: 'Status',
        sortable: true,
        cellStyle: { width: 120 },
        renderComponent: renderStatus,
      },
      {
        id: 'expected_delivery_after',
        title: 'Scheduled time',
        sortable: !isStarterPlan,
        cellStyle: { maxWidth: 180 },
        renderComponent: renderScheduledDeliveryTime,
      },
      {
        id: 'delivery_time',
        title: 'Delivery time',
        cellStyle: { maxWidth: 180 },
        renderComponent: renderDeliveryTime,
      },
      {
        id: 'executor_id',
        title: 'Driver',
        sortable: true,
        cellStyle: { maxWidth: 120 },
        renderComponent: renderDriver,
      },
      {
        id: 'delivery_name',
        title: 'Pickup location',
        sortable: true,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 170,
        },
        renderComponent: (item) => renderLocation(item.pickup),
      },
      {
        id: 'picked_up_at',
        title: 'Picked up time',
        cellStyle: { maxWidth: 180 },
        renderComponent: renderPickup,
      },
      {
        id: 'recipient',
        title: 'Recipient',
        sortable: true,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: 120,
        },
        renderComponent: (item) => renderRecipient(item.delivery?.name, item),
      },
      {
        id: 'delivery_address',
        title: 'Dropoff location',
        sortable: true,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 170,
        },
        renderComponent: (item) => renderLocation(item.delivery),
      },
      {
        id: 'delivery_arr_time',
        title: 'Dropoff ETA',
        cellStyle: { maxWidth: 180 },
        renderComponent: renderDeliveryBefore,
      },
      {
        id: 'rating',
        title: 'Rating',
        sortable: !isStarterPlan,
        cellStyle: {
          width: 100,
        },
        renderComponent: (item) => {
          if (isStarterPlan && !isFullEmpty) {
            return '-';
          }
          if (item?.rating) {
            return <Rating value={item?.rating} readOnly />;
          }
          return '-';
        },
      },
    ];
    if (tabValue === ITabValue.Ongoing) {
      return d.filter((c) => !['rating', 'delivery_time'].includes(c.id));
    }

    return d.filter(
      (c) =>
        ![
          'picked_up_at',
          'delivery_arr_time',
          'expected_delivery_after',
        ].includes(c.id),
    );
  }, [
    tabValue,
    isStarterPlan,
    isFullEmpty,
    taskIdsSelected,
    checkAllTask,
    data,
    stores,
    isMultiStore,
  ]);

  const isAllTaskOngoingDone = tabValue === ITabValue.Ongoing && isEmptyState;

  const renderTableEmptyState = useMemo(() => {
    if (isFullEmpty) {
      return (
        <StyledAllTaskCompletedContainer>
          {renderEmpty}
        </StyledAllTaskCompletedContainer>
      );
    }
    if (isAllTaskOngoingDone) {
      return (
        <StyledAllTaskCompletedContainer>
          <img width={400} src={BackgroundImage} alt='setup-your-store' />
          <Typography variant='h1'>All tasks are completed!</Typography>
        </StyledAllTaskCompletedContainer>
      );
    }
    return null;
  }, [tabValue, isAllTaskOngoingDone, isFullEmpty, renderEmpty]);

  const renderTaskSelected = () => (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: '2px',
        backgroundColor: themes.color.violet700,
        padding: 1.5,
        position: 'absolute',
        bottom: 24,
        left: 24,
        right: 24,
        // right: isOpenTask || openBulkAssign ? 480 : 24,
        transition: 'right 0.15s ease',
        zIndex: 10,
        [themes.breakpoints.down('lg')]: {
          bottom: 15,
          left: 15,
          right: 15,
        },
        [themes.breakpoints.down('sm')]: {
          padding: 0.8,
        },
      }}
    >
      <Stack
        flexDirection='row'
        alignItems='center'
        gap={0.5}
        px={1.6}
        sx={{
          color: 'white',
          fontWeight: 500,
          cursor: 'pointer',
          [themes.breakpoints.down('md')]: {
            px: 0.8,
          },
        }}
        onClick={() => {
          setTasksSelected([]);
          setOpenBulkAssign(false);
        }}
      >
        <img
          src={CloseIcon}
          alt=''
          style={{
            width: 9,
            height: 9,
          }}
        />
        Discard
      </Stack>
      <Stack
        flexDirection='row'
        alignItems='center'
        gap={0.8}
        pl={1.6}
        sx={{
          color: 'white',
          fontWeight: 500,
          cursor: 'pointer',
          [themes.breakpoints.down('sm')]: {
            paddingLeft: 0.8,
          },
        }}
      >
        <Typography
          px={1.6}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 0.8,
            [themes.breakpoints.down('md')]: {
              px: 0.2,
            },
          }}
        >
          <img src={CheckboxIcon} alt='' />
          {taskIdsSelected.length} selected
        </Typography>
        <Button
          noRounder
          buttonType='text'
          onClick={() =>
            exportTaskMutation({
              ids: taskIdsSelected,
            })
          }
          sx={{
            [themes.breakpoints.down('md')]: {
              minWidth: 'unset !important',
              padding: '4px 8px',
            },
            minWidth: '76px !important',
            backgroundColor: 'white !important',
            color: `${themes.color.violet700} !important`,
            padding: '4px 16px',
            borderRadius: '8px !important',
          }}
          loading={isLoadingExport}
        >
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            Export
          </Typography>
        </Button>
        <Button
          noRounder
          buttonType='text'
          onClick={() => {
            setOpenBulkAssign(true);
          }}
          sx={{
            [themes.breakpoints.down('md')]: {
              minWidth: 'unset !important',
              padding: '4px 8px',
            },
            'minWidth': '76px !important',
            'backgroundColor': 'white !important',
            'color': `${themes.color.violet700} !important`,
            'padding': '4px 16px',
            'borderRadius': '8px !important',
            '&.Mui-disabled': {
              color: ' #C9C9C9 !important',
              backgroundColor: '#F8F8F8 !important',
            },
          }}
          loading={isLoadingExport}
          disabled={multiTaskIds?.length === 0}
        >
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            Assign
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );

  const renderAssignDriver = () => {
    if (!openBulkAssign) return '';
    return (
      <AssignDriver
        taskId={null}
        hubId=''
        multiTaskIds={multiTaskIds}
        isAssignMultiTask
        setMultiTaskIds={() => {
          setOpenBulkAssign(false);
        }}
        onBackToTaskDetail={() => setOpenBulkAssign(false)}
        handleCloseTaskDetail={() => {
          setTasksSelected([]);
          setOpenBulkAssign(false);
        }}
      />
    );
  };

  return (
    <>
      <Table
        customClassName={clsx({
          ongoing: tabValue === ITabValue.Ongoing,
          completed: tabValue === ITabValue.Completed,
        })}
        containerSxProps={{
          ...(isFullEmpty
            ? {
                '& td': {
                  filter: 'grayscale(1)',
                  opacity: 0.25,
                },
              }
            : {}),
          [themes.breakpoints.down('md')]: {
            '& table': {
              '& .MuiChip-root': {
                width: '100px !important',
              },
            },
          },
        }}
        headerCellSxProps={{
          color: '#8C95BA',
          fontSize: 12,
          paddingTop: '8px !important',
          paddingBottom: '8px !important',
        }}
        cellSxProps={{
          paddingTop: '8px !important',
          paddingBottom: '8px !important',
        }}
        columns={columns}
        dataProps={isFullEmpty ? EmptyStateData(tabValue) : data}
        sortBy={convertedSortParams(sort, sort_by)}
        onSort={handleSort}
        clickableRowId={clickableRowId}
        onRowClick={onRowClick}
        striped={false}
        noResultText={null}
        customEmptyState={renderTableEmptyState}
        loading={isLoading}
        sxNoData={{ padding: '35px !important' }}
      />
      {taskIdsSelected?.length !== 0 && renderTaskSelected()}
      <StyledPanelDetailContainer
        role='presentation'
        isOpen={openBulkAssign}
        fixedWidth={480}
      >
        {renderAssignDriver()}
      </StyledPanelDetailContainer>
    </>
  );
};
