import Hotjar from '@hotjar/browser';
import { HOTJAR_SITE_ID, HOTJAR_VERSION } from '~/config';
import { IAccount } from '~/models/adminOptions';
import { IOrg } from '~/models/org';
import { IPlan, IPlanTier } from '~/models/plan';

export const initializeHotjar = () => {
  const siteId = parseInt(HOTJAR_SITE_ID, 10);
  const hotjarVersion = parseInt(HOTJAR_VERSION, 10);
  Hotjar.init(siteId, hotjarVersion);
};

export const identifyHotjar = (
  userId,
  data: {
    account: IAccount;
    org: IOrg;
    currentPlan: IPlan;
  },
) => {
  const { account, org, currentPlan } = data;
  const { id, email, display_name, role } = account;
  const { id: orgId, name: orgName, tier } = org;
  const isPaying = tier !== IPlanTier.Free;
  const { name, unit_amount } = currentPlan;
  Hotjar.identify(userId, {
    id,
    email,
    full_name: display_name,
    org_id: orgId,
    org_name: orgName,
    role,
    is_paying: isPaying,
    ...(currentPlan && {
      planLevel: name, // Optional
      planPrice: unit_amount, // Optional
    }),
  });
};
