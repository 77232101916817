import { ClassNameMap } from '@mui/styles';
import React from 'react';
import { Container, Grid, Stack, SxProps } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import BackgroundImage from '~/assets/images/background/authentication.png';
import Logo from '~/assets/images/logo/logo.svg';
import { useLocation } from 'react-router-dom';
import themes, { Theme } from '~/themes';
import { publicLayoutStyle } from './style';

export interface PublicLayoutProps {
  classes: ClassNameMap;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const PublicLayout = ({ classes, children, sx }: PublicLayoutProps) => {
  const location = useLocation();
  const isUserOnboarding = location.pathname.includes('/user-onboarding');

  return (
    <Grid container sx={{ height: '100%', ...sx }}>
      <Grid item xs={12} lg={6}>
        <Stack className={classes.contentContainer}>
          <Container
            maxWidth={false}
            className={classes.content}
            sx={{
              maxWidth: 400,
              ...(isUserOnboarding && {
                maxWidth: 720,
              }),
            }}
          >
            <div>
              <img
                src={Logo}
                data-testid='logo'
                height={24}
                alt='logo'
                className={classes.logo}
              />
            </div>
            {children}
          </Container>
          <div
            className={classes.footer}
            style={{
              ...(isUserOnboarding && {
                maxWidth: 720,
              }),
            }}
          >
            © 2025 Breadstack Delivery, All rights reserved.
          </div>
        </Stack>
      </Grid>

      <Grid
        item
        xs={0}
        lg={6}
        sx={{
          [themes.breakpoints.down('lg')]: {
            display: 'none',
          },
          backgroundImage: `url(${BackgroundImage})`,
        }}
      />
    </Grid>
  );
};

export default withStyles(publicLayoutStyle)(PublicLayout);
