import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getNewSort } from '~/utils/common';
import {
  formatPhoneNumber,
  updateLocationSearchParams,
} from '~/utils/formatter';
import Button from '~/components/common/Button';
import {
  cleanDeep,
  fromQueryString,
  omitFromQueryString,
  toQueryString,
} from '~/utils/queryString';
import { useAuth } from '~/providers/AuthProvider';
import { useFirstLoadingDetection } from '~/hooks';
import { DEFAULT_PAGE_SIZE } from '~/constants/common';
import { IAddress } from '~/models/address';
import { PageLayout } from '~/layout/PageLayout';
import { Stack, Typography } from '@mui/material';
import { StyledFilterContainer } from '~/themes/style';
import { Pagination } from '~/components/common/Pagination';
import themes from '~/themes';
import AddIcon from '~/assets/images/icons/add-white.svg';
import { AddressFilterForm } from './components/FilterForm';
import { AddressBookTable } from './components/AddressTable';
import { AddressForm, EmptyState } from './components/AddressForm';
import { useGetAddressQuery } from './apis';
import { getCountryCodePhone } from '../LiveTracking/utils';

interface IAddressBookProps {}

export const AddressBook: React.FC<IAddressBookProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { account } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IAddress>(null);

  const itemPerPage: number = account?.items_per_page || DEFAULT_PAGE_SIZE;
  const {
    search,
    city,
    state,
    page: p,
    sort = 1,
    sort_by = 'name',
  } = fromQueryString(location.search);

  const page = (p || 1) as string;

  const {
    data,
    isFetching: isFetchingAddress,
    isPreviousData,
  } = useGetAddressQuery({
    search,
    city,
    state,
    page,
    limit: itemPerPage,
    sort,
    sort_by,
  });

  const isFirstLoading = useFirstLoadingDetection([isFetchingAddress]);
  const isShowTaskListLoading = !(!isFetchingAddress || !isPreviousData);

  const { items: addresses = [], total_pages, state_city_list } = data || {};

  const addressesConverted: IAddress[] = useMemo(() => {
    if (!addresses.length) return [];

    return addresses.map((address) => {
      const newAddress = { ...address };
      newAddress.country_code = getCountryCodePhone(address.phone);

      newAddress.phone =
        newAddress?.phone?.replace(/\+1|\+84|\+61/g, '')?.trim() || '';

      return {
        ...newAddress,
        phone_formatted: address.phone ? formatPhoneNumber(address.phone) : '-',
      };
    });
  }, [addresses]);

  const onSubmit = useCallback(
    (values: any) => {
      cleanDeep(values, { excludeKeys: ['search'], emptyString: true });
      navigate({
        pathname: location.pathname,
        search: toQueryString({ ...values, page: 1 }),
      });
    },
    [location],
  );

  const onClear = useCallback(() => {
    navigate({
      pathname: location.pathname,
      search: omitFromQueryString(location.search, ['search']),
    });
  }, [location]);

  const onClearAll = useCallback(() => {
    navigate({
      pathname: location.pathname,
    });
  }, [location]);

  const handleSort = useCallback(
    (sortId: string, nextArrange: number) => {
      const sortParams = getNewSort(sortId, nextArrange);
      navigate({
        pathname: location.pathname,
        search: `?${updateLocationSearchParams(location, sortParams)}`,
      });
    },
    [location, sort, sort_by],
  );

  const isEmptyState = !data?.items?.length && !location.search.length;

  const renderAddressListBody = () => (
    <>
      <StyledFilterContainer
        sx={{
          marginBottom: themes.spacing(1.6),
        }}
      >
        <AddressFilterForm
          onSubmit={onSubmit}
          onClear={onClear}
          onClearAll={onClearAll}
          stateCities={state_city_list}
        />
        <Pagination totalPages={total_pages} currentPage={parseInt(page, 10)} />
      </StyledFilterContainer>
      <AddressBookTable
        data={addressesConverted}
        handleSort={handleSort}
        loading={isShowTaskListLoading}
        onEdit={(selected: IAddress) => {
          setIsOpen(true);
          setSelectedItem(selected);
        }}
        renderEmpty={<EmptyState setOpen={setIsOpen} />}
        isEmptyState={isEmptyState}
      />
      {!isEmptyState && (
        <Stack sx={{ pt: 2, alignItems: 'flex-end' }}>
          <Pagination
            totalPages={total_pages}
            currentPage={parseInt(page, 10)}
          />
        </Stack>
      )}
    </>
  );

  return (
    <>
      <PageLayout
        headerTitle='Address Book'
        renderHeaderButtons={
          isFirstLoading ? (
            ''
          ) : (
            <Button
              noRounder
              buttonType='primary-dark'
              onClick={() => {
                setSelectedItem(null);
                setIsOpen(true);
              }}
            >
              <Typography
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 0.4,
                }}
              >
                <img src={AddIcon} alt='add-icon' />
                Add Contact
              </Typography>
            </Button>
          )
        }
        sxHeaderContainer={{
          [themes.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
          },
          pt: themes.spacing(2.4),
          px: themes.spacing(2.4),
        }}
        sxContentContainer={{
          pt: themes.spacing(2.4),
          px: themes.spacing(2.4),
        }}
        isFistLoading={isFirstLoading}
      >
        {renderAddressListBody()}
      </PageLayout>
      {isOpen && (
        <AddressForm
          open={isOpen}
          onClose={() => {
            setSelectedItem(null);
            setIsOpen(false);
          }}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
};
