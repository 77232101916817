import { makeStyles } from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { Notifications } from '~/components/shared/Notifications/Notifications';
import { TaskMessage } from '~/components/shared/Notifications/TaskMessage';
import AdminOptions from '~/pages/AdminOptions';
import { TaskList } from '~/pages/TaskList';
import { PaymentProvider } from '~/providers/PaymentProvider';
import { DialogUpSellUpgradePlan } from '~/components/shared/UpgradePlan/DialogUpgradePlan';
import Dashboard from '~/pages/Dashboard';
import { FailPaymentController } from '~/components/shared/Subscriptions/FailPaymentController';
import { AddressBook } from '~/pages/AddressBook';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AppLayout } from 'bs-unified-ui';
import { UnifiedNavbar } from '~/layout/UnifiedNavbar/UnifiedNavbar';
import { LiveTracking } from '~/pages/LiveTracking';
import { GoogleMapWrapper } from '~/providers/GoogleMapWrapper';
import CreateWorkspace from '~/pages/CreateWorkspace';
import { memoryStorage } from '~/utils/localStorage';

const useStyles = makeStyles({
  root: {
    right: 0,
    bottom: 80,
  },
  root1: {
    // minWidth: 270,
    // maxWidth: 270,
  },
});

const App = () => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <PaymentProvider>
      <SnackbarProvider
        maxSnack={3}
        classes={{
          containerRoot: classes.root,
          anchorOriginBottomRight: classes.root1,
        }}
        Components={{
          task: TaskMessage,
        }}
      >
        <GoogleMapWrapper>
          <AppLayout
            renderSideBar={
              location.pathname === '/create-workspace' ? '' : <UnifiedNavbar />
            }
          >
            <DialogUpSellUpgradePlan />
            <FailPaymentController />
            <Notifications />
            <Outlet />
          </AppLayout>
        </GoogleMapWrapper>
      </SnackbarProvider>
    </PaymentProvider>
  );
};

const WorkspaceWrapper = () => {
  const { id, '*': splat } = useParams();
  const location = useLocation();

  if (splat === '' && !location.pathname.endsWith('/')) {
    return <Navigate to={`/ws/${id}/`} replace />;
  }

  return <Outlet context={{ workspaceId: id }} />;
};

const RedirectToWorkspace = () => {
  const workspaceId = memoryStorage.getOrgId();
  if (workspaceId) return <Navigate to={`/ws/${workspaceId}/`} replace />;
  return <Navigate to='/ws/' replace />;
};

export const protectedRoutes = () => {
  const routers = [
    {
      path: '/ws/:id/*',
      element: <WorkspaceWrapper />,
      children: [
        {
          path: '',
          element: <App />,
          children: [
            {
              path: '',
              element: <LiveTracking />,
            },
            {
              path: 'insight',
              element: <Dashboard />,
            },
            {
              path: 'tasks',
              element: <TaskList />,
            },
            {
              path: 'address',
              element: <AddressBook />,
            },
            {
              path: 'setting',
              element: <AdminOptions />,
              children: [{ path: ':source', element: <AdminOptions /> }],
            },
            { path: '*', element: <Navigate to='' replace /> },
          ],
        },
      ],
    },
    {
      path: '/create-workspace',
      element: <App />,
      children: [
        {
          path: '',
          element: <CreateWorkspace />,
        },
      ],
    },
    { path: '*', element: <RedirectToWorkspace /> },
  ];

  return routers;
};
