import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Form, Formik } from 'formik';
import Modal from '~/components/common/Modal';
import { Theme } from '~/themes';
import { useInviteUserMutation } from '~/services/api/userManagement';
import { alertParams, showAlert } from '~/components/common/Alert';
import { USER_ROLE_OPTION } from '~/constants/userManagement';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import { InviteUserSchema } from '~/utils/schema/user';

import InviteUserForm from './InviteUserForm';

const style = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.8),
    },
    modal: {
      width: 650,
    },
    link: {
      color: theme.color.primaryOrange,
    },
  });

interface IProps extends WithStyles<typeof style> {
  open: boolean;
  onClose: () => void;
}

const DialogInviteUser = ({ classes, open, onClose }: IProps) => {
  const { mutate: inviteUserMutation, isLoading } = useInviteUserMutation({
    onSuccess: () => {
      onClose();
      showAlert({
        ...alertParams.success,
        title: 'Invitation Sent Successfully',
        description: <>The invitation email has been sent.</>,
      });
      queryClient.invalidateQueries([queryKeys.userManagement.getUsers]);
    },
    onError: () => {
      onClose();
    },
  });

  return (
    <Modal
      title='Add User'
      open={open}
      onClose={onClose}
      className={classes.modal}
      disableCloseOutside
      actions={[
        {
          title: 'Cancel',
          onClick: () => onClose(),
          buttonType: 'default',
        },
        {
          title: 'Add',
          buttonType: 'primary',
          type: 'submit',
          form: 'invite-user',
          loading: isLoading,
        },
      ]}
    >
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          role: USER_ROLE_OPTION[0].value,
        }}
        onSubmit={(values) =>
          inviteUserMutation({ ...values, email: values.email?.toLowerCase() })
        }
        validationSchema={InviteUserSchema}
      >
        {() => (
          <Form id='invite-user' className={classes.container}>
            <InviteUserForm />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default withStyles(style)(DialogInviteUser);
