/* eslint-disable no-nested-ternary */
import { Box, Grid, Stack, Typography } from '@mui/material';
import Button from '~/components/common/Button';
import themes, { styled } from '~/themes';
import { useGetPlans } from '~/services/api/plan';
import {
  IBillingFrequency,
  IPlanMetadata,
  IPlanTier,
  PlanTierWithNumberOfTask,
} from '~/models/plan';
import { useMemo } from 'react';
import { plansJsonData } from '~/constants/plan';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { StyledCheckIcon } from './ComparePlans';

type SubscriptionPlansProps = {
  billingFrequency: IBillingFrequency;
  numberOfTasks?: string;
  countryOfOrg?: string;
  onChangePlan?: (planSelect?: IPlanMetadata) => void;
};

export const StyledPlanItem = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '380px',
  textAlign: 'center',
  gap: '24px',
  padding: '16px',
  border: '1px solid #E2E7F0',
  borderRadius: '4px',
  background: theme.bg.white,
  position: 'relative',
}));

export const StyledMostPopular = styled(Stack)(({ theme }) => ({
  background: theme.bg.darkPurple,
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  height: 26,
  position: 'absolute',
  color: 'white',
  fontSize: 11,
  borderRadius: '4px 4px 0px 0px',
  fontWeight: '500',
  top: -1,
  left: -1,
  right: -1,
}));

export const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({
  billingFrequency,
  numberOfTasks,
  countryOfOrg,
  onChangePlan,
}) => {
  const { data } = useGetPlans({});

  const { currentPlan, isBundlePlan } = usePaymentInfo();

  const plans = useMemo(() => {
    if (countryOfOrg) {
      return data.filter(
        (p) => p.tier === IPlanTier.Free || p.country === countryOfOrg,
      );
    }
    return data;
  }, [data, countryOfOrg]);

  const tierWithNumberOfTask = numberOfTasks
    ? PlanTierWithNumberOfTask[numberOfTasks?.toString()]
    : null;

  const plansConverted = useMemo(() => {
    const filteredPlans = plansJsonData
      .map((p) => ({
        ...p,
        priceId: plans.find(
          (e) =>
            e.frequency === p.frequency &&
            p.tier === e.tier &&
            // eslint-disable-next-line no-underscore-dangle
            e.type_ === 'plan',
        )?.price_id,
      }))
      .filter((p) => p.frequency === billingFrequency);
    const freePlan = plansJsonData.find((p) => p.tier === IPlanTier.Free);
    const bundlePlan = plansJsonData.find((p) => p.tier === IPlanTier.Bundle);
    const standardPlan = filteredPlans.find(
      (p) => p.tier === IPlanTier.Standard,
    );
    const advancePlan = filteredPlans.find((p) => p.tier === IPlanTier.Advance);
    return [freePlan, standardPlan, advancePlan, bundlePlan].filter((t) => !!t);
  }, [plans, billingFrequency]);

  return (
    <Grid container spacing={0.8} color={themes.color.violet500}>
      {plansConverted.map((item) => {
        const isCurrentPlan = currentPlan
          ? (currentPlan.tier === IPlanTier.Free &&
              item.tier === IPlanTier.Free) ||
            (!isBundlePlan && item.priceId === currentPlan.price_id)
          : false;

        return (
          <Grid item xs={12} sm={6} md={3} key={item.id}>
            <StyledPlanItem
              sx={{
                ...((isCurrentPlan ||
                  (isBundlePlan && item.tier === IPlanTier.Bundle)) && {
                  background: '#EDF0F7',
                  border: '1px solid #121142',
                }),
              }}
            >
              {item.tier === tierWithNumberOfTask && (
                <StyledMostPopular>RECOMMEND FOR YOU</StyledMostPopular>
              )}
              {!numberOfTasks && item.tier === IPlanTier.Standard && (
                <StyledMostPopular>MOST POPULAR</StyledMostPopular>
              )}
              <Stack gap={1.6} alignItems='center' pt={2}>
                <Typography color={themes.color.primaryOrange} fontWeight='500'>
                  {item?.title}
                </Typography>
                <Stack
                  fontSize={28}
                  color={themes.color.violet500}
                  fontWeight='700'
                  direction='row'
                  alignItems='center'
                  minHeight={36}
                >
                  {item.tier !== IPlanTier.Free ? item.price : 'Free'}
                  {item.tier !== IPlanTier.Free &&
                    item.tier !== IPlanTier.Bundle && (
                      <Stack ml={0.5}>
                        {billingFrequency === IBillingFrequency.Year ? (
                          <span
                            style={{
                              fontSize: 12,
                              fontWeight: 'normal',
                              textDecoration: 'line-through',
                            }}
                          >
                            {
                              plansJsonData.find(
                                (p) =>
                                  p.tier === item.tier &&
                                  p.frequency === IBillingFrequency.Month,
                              )?.price
                            }
                          </span>
                        ) : (
                          <Box height={8} />
                        )}

                        <span style={{ fontSize: 12, fontWeight: 'normal' }}>
                          {' '}
                          /mo
                        </span>
                      </Stack>
                    )}
                </Stack>
                <Typography fontSize={14}>
                  {item.tier !== IPlanTier.Bundle
                    ? billingFrequency === IBillingFrequency.Month
                      ? 'billed monthly'
                      : 'billed annually'
                    : ''}
                  {item.tier === IPlanTier.Bundle
                    ? 'with Breadstack suite'
                    : ''}
                </Typography>
              </Stack>
              <Stack>
                {isCurrentPlan ||
                (isBundlePlan && item.tier === IPlanTier.Bundle) ? (
                  <Typography sx={{ lineHeight: '35px', fontWeight: '500' }}>
                    Current Plan
                  </Typography>
                ) : (
                  <Button
                    fullWidth
                    onClick={() => {
                      if (item.tier === IPlanTier.Bundle) {
                        window.open('https://breadstack.com/contact', '_blank');
                        return null;
                      }
                      if (item.tier === IPlanTier.Free) {
                        return onChangePlan({ ...item, priceId: 'price_free' });
                      }
                      return onChangePlan(item);
                    }}
                    disabled={isBundlePlan}
                  >
                    {item.tier === IPlanTier.Bundle
                      ? 'Contact Us'
                      : 'Choose Plan'}
                  </Button>
                )}
              </Stack>
              <Stack flex={1}>
                <Typography fontWeight='bold' textAlign='left'>
                  Plan includes
                </Typography>
                <Stack>
                  {item.checks.map((check, idx) => (
                    <Stack
                      // eslint-disable-next-line react/no-array-index-key
                      key={check + idx}
                      direction='row'
                      spacing={1}
                      alignItems='center'
                      lineHeight='2'
                    >
                      {check ? (
                        <>
                          <StyledCheckIcon name='check' useBackgroundImg />
                          <span>{check}</span>
                        </>
                      ) : (
                        <Typography
                          fontWeight='bold'
                          color={themes.color.gray900}
                          ml={0.5}
                        >
                          -
                        </Typography>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </StyledPlanItem>
          </Grid>
        );
      })}
    </Grid>
  );
};
