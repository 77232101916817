import { Breakpoint } from '@mui/system';

export const CHANGE_PAGE_OPTION = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 25,
    label: '25 (Default)',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 75,
    label: '75',
  },
];

export const ORG_CATEGORY_OPTIONS = [
  {
    value: 'Animals & Pet Supplies',
    label: 'Animals & Pet Supplies',
  },
  {
    value: 'Apparel & Accessories',
    label: 'Apparel & Accessories',
  },
  {
    value: 'Arts & Entertainment',
    label: 'Arts & Entertainment',
  },
  {
    value: 'Baby & Toddler',
    label: 'Baby & Toddler',
  },
  {
    value: 'Business & Industrial',
    label: 'Business & Industrial',
  },
  {
    value: 'Cameras & Optics',
    label: 'Cameras & Optics',
  },
  {
    value: 'Electronics',
    label: 'Electronics',
  },
  {
    value: 'Food, Beverages & Tobacco',
    label: 'Food, Beverages & Tobacco',
  },
  {
    value: 'Furniture',
    label: 'Furniture',
  },
  {
    value: 'Hardware',
    label: 'Hardware',
  },
  {
    value: 'Health & Beauty',
    label: 'Health & Beauty',
  },
  {
    value: 'Home & Garden',
    label: 'Home & Garden',
  },
  {
    value: 'Luggage & Bags',
    label: 'Luggage & Bags',
  },
  {
    value: 'Mature',
    label: 'Mature',
  },
  {
    value: 'Media',
    label: 'Media',
  },
  {
    value: 'Office Supplies',
    label: 'Office Supplies',
  },
  {
    value: 'Religious & Ceremonial',
    label: 'Religious & Ceremonial',
  },
  {
    value: 'Software',
    label: 'Software',
  },
  {
    value: 'Sporting Goods',
    label: 'Sporting Goods',
  },
  {
    value: 'Toys & Games',
    label: 'Toys & Games',
  },
  {
    value: 'Vehicles & Parts',
    label: 'Vehicles & Parts',
  },
];

export const DEFAULT_PAGE_SIZE = 25;

export const DEFAULT_TIMEZONE = 'America/Vancouver';

export const LIMIT_COUNT: number = 1000;

export const COMMON_API_LABEL = ['/preferred-areas', '/vehicles'];

export const COUNT_OF_ORDER_CODE = 8;
export const MAX_COUNT_OF_ORDER_CODE_TOOLTIP = 20;

export const SPECIAL_CHAR = '!"#$%&\'()*+,-./:;<=>?@^_{|}~';
export const RegexValidPassword = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${SPECIAL_CHAR}])[A-Za-z\\d${SPECIAL_CHAR}]{8,}$`,
);

export const ORG_ID_HEADER = 'canfleet-org-id';

export const DEFAULT_DOWN_SIZE: Breakpoint = 'md';

export const DEFAULT_DOWN_SIZE_PX: number = 900;

export const DEFAULT_MOBILE_SIZE_PX: number = 768;

export const HEIGHT_HEIGHT_PX: number = 60;
