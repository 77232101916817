import { Box, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import { DriverAvatar } from '~/components/shared/AssignTask/DriverAvatar';
import {
  StyledItemContainer,
  StyledSelectedItem,
} from '~/pages/LiveTracking/style';
import { Driver } from '~/pages/LiveTracking/types';
import themes from '~/themes';
import { formatPhoneNumber } from '~/utils/formatter';
import AllDriverOfflineImg from '~/assets/images/background/add-driver.png';
import { useLiveMapContext } from '~/pages/LiveTracking/hooks/useLiveMapContext';
import { FirstLoading } from '../../FirstLoading';

type DriverListProps = { data: Driver[]; isFirstLoading?: boolean };

export const DriverList: React.FC<DriverListProps> = memo(
  ({ data, isFirstLoading }) => {
    const { driverId, handleOpenManageRoutes } = useLiveMapContext();

    if (isFirstLoading) return <FirstLoading />;

    if (!data.length)
      return (
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
          spacing={1}
        >
          <img src={AllDriverOfflineImg} width={400} alt='all-driver-offline' />
          <Typography color={themes.color.violet900}>
            All drivers are currently offline.
          </Typography>
        </Stack>
      );

    return (
      <Stack className='customized-scrollbar' sx={{ overflow: 'auto' }}>
        {data.map(
          ({
            id,
            total_task,
            display_name,
            avatar,
            driver_status,
            phone,
            eta_alerted,
            idle_at,
            receive_auto_assign_task,
          }) => (
            <StyledItemContainer
              spacing={0.5}
              key={id}
              isSelected={driverId === id}
              direction='row'
              onClick={() => handleOpenManageRoutes(id)}
              sx={{
                background: themes.bg.white,
                borderRadius: '10px',
                p: 1.5,
                cursor: 'pointer',
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <StyledSelectedItem className='selected-task' isDriver />
              <Stack spacing={1} direction='row' alignItems='center' flex={1}>
                <DriverAvatar
                  avatar={avatar}
                  size={40}
                  status={driver_status}
                  name={display_name}
                  receiveAutoAssignTask={receive_auto_assign_task}
                  idleAt={idle_at}
                />
                <Stack
                  direction='column'
                  flex={1}
                  justifyContent='space-between'
                  gap={0.4}
                >
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    spacing={0.5}
                  >
                    <Box
                      display='inline-flex'
                      justifyContent='space-between'
                      flex={1}
                    >
                      <ToolTipText
                        text={display_name}
                        textNode={(v) => <Typography>{v}</Typography>}
                        maxLength={30}
                      />
                    </Box>
                    <Box>
                      {total_task ? (
                        <Typography color='#8C95BA'>
                          {total_task} {total_task > 1 ? 'tasks' : 'task'}
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Stack>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    spacing={1}
                  >
                    <Typography minHeight={20}>
                      {formatPhoneNumber(phone)}
                    </Typography>
                    {eta_alerted ? (
                      <Typography color='#F75879'>late deliveries</Typography>
                    ) : (
                      ''
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </StyledItemContainer>
          ),
        )}
      </Stack>
    );
  },
);
