import { Route, Routes, Navigate } from 'react-router-dom';
import { UserOnboarding } from '~/pages/Auth';
import { UnifiedForgotPassword, UnifiedLogin } from '~/pages/UnifiedAuth';

export const AuthRoutes = () => (
  <Routes>
    <Route path='login' element={<UnifiedLogin />} />
    <Route path='reset-password' element={<UnifiedForgotPassword />} />
    <Route path='user-onboarding' element={<UserOnboarding />} />
    <Route path='*' element={<Navigate to='login' />} />
  </Routes>
);

export const authRoutes = [
  {
    path: '*',
    element: <AuthRoutes />,
  },
];
