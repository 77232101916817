import { styled } from '@mui/material';
import { Form, Formik } from 'formik';
import { Field } from '~/components/common/Formik';
import Modal from '~/components/common/Modal';
import Tooltip, { TooltipPasswordContent } from '~/components/common/Tooltip';
import { ChangePwdSchema } from '~/utils/schema/auth';
import InfoIcon from '~/assets/images/icons/info.svg';
import { Stack } from '@mui/system';
import { LinkButton } from '~/components/common/Button';
import { useChangePwd, useLogout } from '~/services/api/auth';
import { useAuth } from '~/providers/AuthProvider';
import { makeStyles } from '@mui/styles';
import { use2Fa } from '~/hooks/use2Fa';
import { useEffect, useState } from 'react';
import { formatFirebaseError, updatePwd } from '~/services/firebase';
import DialogVerifyCode2FA from '~/pages/Auth/DialogVerifyCode2FA';
import VerifyCode2FA from '~/pages/Auth/VerifyCode2FA';

const StyledModal = styled(Modal)(() => ({
  maxWidth: 370,
}));

const StyledImage = styled('img')(({ theme }) => ({
  marginTop: 30,
  marginRight: theme.spacing(-3),
  marginLeft: theme.spacing(1),
}));

const StyledForm = styled(Form)(({ theme }) => ({
  'width': 'calc(100% - 30px)',
  '& > div': {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.5),
    },
  },
}));

const useStyles = makeStyles(() => ({
  tooltipContent: {
    width: 220,
  },
}));
interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePwdModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { account } = useAuth();
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [storeAuth, setStoreAuth] = useState<any>({
    email: '',
    password: '',
    newPassword: '',
  });
  const [loadingUpdatePw, setLoadingUpdatePw] = useState(false);

  const formikIndex = isOpen ? 1 : 0;

  const { mutate: logoutMutation } = useLogout();

  const handleLoginSuccess = async () => {
    try {
      await updatePwd(storeAuth);
    } catch (error) {
      const errorMessage = formatFirebaseError(error);
      setErrorMsg(errorMessage);
    }
    setLoadingUpdatePw(false);
  };

  const {
    handleEnable2FA,
    handleSendCode,
    loadingSendCode,
    phone,
    handleVerifyCode,
    loadingVerify,
    enable2FA,
    handleCancel,
    errorMsg: errorMsg2Fa,
    clearErrorMsg,
  } = use2Fa({
    recaptchaId: 'recaptcha-container-id',
    onLoginSuccess: async () => {
      await handleLoginSuccess();
      onClose();
      logoutMutation(null, {
        onSuccess: () => {
          window.location.reload();
        },
      });
    },
  });

  const { mutate: inviteUserMutation, isLoading } = useChangePwd({
    onSuccess: () => {
      setLoadingUpdatePw(false);
      onClose();
      logoutMutation(null, {
        onSuccess: () => {
          window.location.reload();
        },
      });
    },
    onError: (error) => {
      if (error?.code === 'auth/multi-factor-auth-required') {
        handleEnable2FA(error);
      } else {
        const errorMessage = formatFirebaseError(error);
        setErrorMsg(errorMessage);
        setLoadingUpdatePw(false);
      }
    },
  });

  const handleForgotPassword = () => {
    logoutMutation(null, {
      onSuccess: () => {
        window.location.href = '/reset-password';
      },
    });
  };

  const onSubmit = async (formData) => {
    setErrorMsg('');
    setStoreAuth({ ...formData, email: account?.email || '' });
    setLoadingUpdatePw(true);
    inviteUserMutation({
      ...formData,
      email: account?.email || '',
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setErrorMsg('');
      setLoadingUpdatePw(false);
    }
  }, [isOpen]);

  return (
    <div>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
          newPassword: '',
        }}
        onSubmit={onSubmit}
        validationSchema={ChangePwdSchema}
        key={formikIndex}
      >
        {({ isValid, dirty }) => (
          <StyledModal
            title='Change Password'
            open={isOpen}
            onClose={onClose}
            disableCloseOutside
            actions={[
              {
                title: 'Cancel',
                onClick: () => onClose(),
                buttonType: 'default',
              },
              {
                title: 'Save',
                buttonType: 'primary',
                type: 'submit',
                form: 'change-pwd',
                loading: isLoading || loadingUpdatePw,
                disabled: !isValid || !dirty,
              },
            ]}
          >
            <StyledForm
              id='change-pwd'
              onKeyPress={(e) => {
                if (e.code === 'Enter') {
                  e.preventDefault();
                }
              }}
            >
              <Field.TextInput
                name='password'
                legend='Current Password'
                type='password'
                error={!!errorMsg}
                helperText={errorMsg || ''}
              />
              <Stack width='100%' alignItems='flex-end' mt={-1}>
                <LinkButton onClick={handleForgotPassword}>
                  Forgot Password?
                </LinkButton>
              </Stack>
              <Field.TextInput
                name='newPassword'
                legend='New Password'
                type='password'
              />
              <Tooltip
                title={<TooltipPasswordContent />}
                sx={{
                  cursor: 'pointer',
                }}
                classes={{ tooltip: classes.tooltipContent }}
              >
                <StyledImage width={20} height={20} src={InfoIcon} alt='info' />
              </Tooltip>
              <Field.TextInput
                name='confirmPassword'
                legend='Confirm New Password'
                type='password'
              />
            </StyledForm>
          </StyledModal>
        )}
      </Formik>
      {enable2FA && (
        <DialogVerifyCode2FA open={enable2FA} onClose={handleCancel}>
          <VerifyCode2FA
            phone={phone}
            onComplete={handleVerifyCode}
            onClickReSend={handleSendCode}
            loading={loadingSendCode || loadingVerify}
            error={errorMsg2Fa}
            clearError={clearErrorMsg}
            hasAlertReLogin
          />
        </DialogVerifyCode2FA>
      )}
      <div
        id='recaptcha-container-id'
        style={{ visibility: isOpen ? 'visible' : 'hidden' }}
      />
    </div>
  );
};

export default ChangePwdModal;
