export const FIREBASE_CONFIG = import.meta.env.VITE_FIREBASE_CONFIG as string;
export const GOOGLE_MAPS_API_KEY = import.meta.env
  .VITE_GOOGLE_MAPS_API_KEY as string;
export const ENDPOINTS = import.meta.env.VITE_ENDPOINTS as string;
export const DOMAIN = import.meta.env.VITE_DOMAIN as string;
export const VAPID_KEY = import.meta.env.VITE_VAPID_KEY as string;
export const PENDO_KEY = import.meta.env.VITE_PENDO_KEY as string;
export const GA4_ID = import.meta.env.VITE_GA4_ID as string;
export const GTAG_ID = import.meta.env.VITE_GTAG_ID as string;
export const GROWTH_BOOK_CLIENT_KEY = import.meta.env
  .VITE_GROWTH_BOOK_CLIENT_KEY as string;
export const RECAPTCHA_KEY = import.meta.env.VITE_RECAPTCHA_KEY as string;
export const META_PIXEL_ID = import.meta.env.VITE_META_PIXEL_ID as string;
export const COOKIES_CROSS_DOMAIN = import.meta.env
  .VITE_COOKIES_CROSS_DOMAIN as string;
export const URLS = import.meta.env.VITE_URLS as string;
export const LIVE_MAP_ID = import.meta.env.VITE_LIVE_MAP_ID as string;
export const SSO_AUTH_ENDPOINT = import.meta.env
  .VITE_SSO_AUTH_API_URL as string;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;
export const SENTRY_AUTH_TOKEN = import.meta.env
  .VITE_SENTRY_AUTH_TOKEN as string;
export const SSE_ENDPOINT = import.meta.env.VITE_SSE_ENDPOINTS as string;
export const HOTJAR_SITE_ID = import.meta.env.VITE_HOTJAR_SITE_ID as string;
export const HOTJAR_VERSION = import.meta.env.VITE_HOTJAR_VERSION as string;
export const UNIFIED_NAVIGATION_ENDPOINT = import.meta.env
  .VITE_UNIFIED_NAVIGATION_ENDPOINTS as string;
