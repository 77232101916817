import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Tabs from '~/components/common/Tabs';
import { AdminOptionTabs } from '~/models/adminOptions';
import {
  ADMIN_OPTIONS_OPTIONS,
  AdminOptionTutorial,
} from '~/constants/adminOptions';
import themes from '~/themes';
import { useAuth } from '~/providers/AuthProvider';
import { USER_ROLE } from '~/constants/userManagement';
import Tutorial from '~/components/common/Tutorial';
import { PageLayout } from '~/layout/PageLayout';
import { useWorkspaceNavigate } from '~/hooks/useWorkspaceNavigate';
import { UserList } from './Users/UserList';
import { DriverList } from './Drivers/List';
import General from './General';
import Teams from './Teams';
import Organization from './Organization';
import { Billing } from './Billing';
import { Stores } from './Stores';
import { Plans } from './Plans';

export interface IProps {}

const AdminOptions: React.FC<IProps> = () => {
  const params = useParams();
  const navigate = useWorkspaceNavigate();
  const { account, getTooltipOpen, updateUserTooltip } = useAuth();

  const source = (
    account?.role === USER_ROLE.ADMIN || account?.role === USER_ROLE.OWNER
      ? params?.source || AdminOptionTabs.General
      : AdminOptionTabs.General
  ) as AdminOptionTabs;

  const handleChangeTab = useCallback((val: string) => {
    navigate(`/setting${val === AdminOptionTabs.General ? '' : `/${val}`}`);
  }, []);

  const tabsData = useMemo(() => {
    if (
      account?.role === USER_ROLE.ADMIN ||
      account?.role === USER_ROLE.OWNER
    ) {
      return ADMIN_OPTIONS_OPTIONS;
    }
    return ADMIN_OPTIONS_OPTIONS.slice(0, 1);
  }, [source, account?.role]);

  const tabs = useMemo(
    () =>
      tabsData.map((item) => ({
        ...item,
        label:
          source === item.value && AdminOptionTutorial[item.value] ? (
            <Tutorial
              isOpen={getTooltipOpen(AdminOptionTutorial[item.value].id)}
              onClose={() =>
                updateUserTooltip({
                  [AdminOptionTutorial[item.value].id]: true,
                })
              }
              {...AdminOptionTutorial[item.value]}
            >
              {item.label}
            </Tutorial>
          ) : (
            item.label
          ),
      })),
    [tabsData, source, account],
  );

  const contentComponent = useMemo(() => {
    switch (source) {
      case AdminOptionTabs.Users:
        return <UserList />;
      case AdminOptionTabs.Drivers:
        return <DriverList />;
      case AdminOptionTabs.Teams:
        return <Teams />;
      case AdminOptionTabs.Organization:
        return <Organization />;
      case AdminOptionTabs.Billing:
        return <Billing />;
      case AdminOptionTabs.Stores:
        return <Stores />;
      case AdminOptionTabs.Plans:
        return <Plans />;
      case AdminOptionTabs.General:
      default:
        return <General />;
    }
  }, [source]);

  return (
    <PageLayout
      headerTitle='Settings'
      sxHeaderLeftContainer={{
        [themes.breakpoints.down('md')]: {
          width: '100%',
        },
        'h2': {
          color: themes.color.violet700,
          lineHeight: '35px',
        },
        '& .MuiTabs-root': {
          '& .MuiTabs-indicator': {
            backgroundColor: `${themes.color.violet700} !important`,
          },
        },
        '& .MuiTab-root': {
          'padding': 0,
          '& .MuiTabs-indicator': {
            backgroundColor: `${themes.color.violet700} !important`,
          },
        },
        '& .MuiTabs-flexContainer': {
          gap: 2,
        },
        '& .MuiButtonBase-root': {
          'textTransform': 'unset',
          '& span': {
            color: `rgba(51, 54, 61, 0.40)`,
            fontWeight: 500,
          },
        },
        '& .Mui-selected': {
          '& span': {
            color: `${themes.color.violet700}`,
            fontWeight: 500,
          },
        },
      }}
      sxContentContainer={{
        alignItems: 'center',
      }}
      sxHeaderContainer={{
        px: 2.4,
        pt: 2.4,
      }}
      renderTabs={
        <Tabs
          tabs={tabs}
          countWithoutBackground
          allowScrollButtonsMobile
          variant='scrollable'
          value={source}
          onChange={handleChangeTab}
        />
      }
    >
      {contentComponent}
    </PageLayout>
  );
};
export default AdminOptions;
