import { NavigateFunction } from 'react-router-dom';
import { BaseMarkers } from './BaseMarkers';
import { isValidLatLngLiteral } from '../utils';

export class DriverMarkers {
  public markers: BaseMarkers;

  public assignDriverMarkers: BaseMarkers;

  public directionsService = new google.maps.DirectionsService();

  private map: google.maps.Map | null;

  private navigate: NavigateFunction;

  constructor(map: google.maps.Map | null, navigate: NavigateFunction) {
    this.map = map;
    this.markers = new BaseMarkers(map);
    this.navigate = navigate;
  }

  addMarker(params: {
    id: string;
    position: google.maps.LatLngLiteral;
    content: Node;
    isAddMarkerToCluster?: boolean;
  }) {
    const { id, position, content, isAddMarkerToCluster } = params;
    if (!isValidLatLngLiteral(position)) return;

    const marker = new google.maps.marker.AdvancedMarkerElement({
      position,
      content,
      zIndex: 9999,
    });

    this.markers.add({ id, marker });

    marker.addListener('click', () => {
      this.navigate(`?driver_id=${id}`);
    });

    if (isAddMarkerToCluster) {
      marker.map = this.map;
    }
  }

  updateMarker(params: {
    id: string;
    content: Node;
    isAddMarkerToCluster?: boolean;
  }) {
    const { id, content, isAddMarkerToCluster } = params;
    this.markers.updateContent(id, content, isAddMarkerToCluster);
  }

  showMarkers() {
    this.markers.showAll();
  }

  hideMarkers() {
    this.markers.hideAll();
  }
}
