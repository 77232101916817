import { useMutation, useQuery } from '@tanstack/react-query';
import httpClient from '~/lib/client/httpClient';
import { IAccount } from '~/models/adminOptions';
import { convertGeneralSettingsResponses } from '~/utils/settings';
import {
  IBillingInfo,
  INewAccountFormParam,
  INewPasswordParam,
  IResetPasswordParam,
  ISetDefaultPaymentParam,
} from '~/models/auth';
import { convertNewPasswordParams } from '~/utils/auth';
import { memoryStorage } from '~/utils/localStorage';
import { queryClient } from '~/lib/react-query';
import { BroadcastEvents, channel } from '~/lib/broadcast';
import Cookies from 'js-cookie';
import {
  CUSTOM_TOKEN_COOKIES_KEY,
  RF_CUSTOM_TOKEN_COOKIES_KEY,
  CookieOptions,
} from '~/constants/auth';
import { errorAlert, showAlert } from '~/components/common/Alert';
import { jwtDecode } from 'jwt-decode';
import { FirebaseError } from 'firebase/app';
import { SSO_AUTH_ENDPOINT } from '~/config';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { queryKeys } from './queryKeys';
import endpoints from './endpoints';
import {
  changePwd,
  getCurrentUser,
  logout,
  login,
  register,
  formatFirebaseError,
} from '../firebase';

export const getAccounts = (
  params,
): Promise<{
  has_fb_account?: boolean;
  has_app?: boolean;
  has_bs?: boolean;
  users?: IAccount[];
}> =>
  httpClient.json.get('accounts', {
    params,
  });

export const passwordReset = async (data: IResetPasswordParam) =>
  httpClient.json.post(endpoints.auth.passwordReset, data);

export const passwordResetConfirm = async (data: INewPasswordParam) =>
  httpClient.json.post(
    endpoints.auth.passwordResetConfirm,
    convertNewPasswordParams(data),
  );

export const signUp = async ({
  email,
  password,
  invitation_code,
}: INewAccountFormParam) =>
  httpClient.json.post(endpoints.auth.signup, {
    email,
    password,
    invitation_code,
  });

export const signUpFb = async ({ email, password }: INewAccountFormParam) =>
  register({ email, password });

export const signIn = async ({ email, password }) => login({ email, password });

export const signOut = async () => logout();

export const signOutSSO = async () => {
  try {
    await httpClient.json.post(
      `${endpoints.sso.logout}`,
      {},
      {
        baseURL: SSO_AUTH_ENDPOINT,
        withCredentials: true,
      },
    );
  } catch (error) {
    console.log(error);
  }
  return logout();
};

export const checkAuth = async () =>
  httpClient.json.post(
    `${endpoints.sso.checkAuth}`,
    {},
    {
      baseURL: SSO_AUTH_ENDPOINT,
      withCredentials: true,
    },
  );

export const logRequestSignIn = async () =>
  httpClient.json.post(endpoints.auth.logRequestSignIn);

export const setCustomClaim = async () =>
  httpClient.json.post(endpoints.auth.setCustomClaim);

export const verifyReCaptcha = async (token: string) =>
  httpClient.json.post(endpoints.auth.verifyReCaptcha, {
    captcha_token: token,
  });

export const getBillingInfo = async (): Promise<IBillingInfo> =>
  httpClient.json.post(endpoints.auth.billingInfo, {});

export const activeAccount = async (params): Promise<IBillingInfo> =>
  httpClient.json.post(endpoints.auth.activeAccount, params);

export const verifyAccount = async (code: string): Promise<any> =>
  httpClient.json.post(`${endpoints.auth.verifyAccount}/${code}`);

export const resentEmail = async (email: string): Promise<any> =>
  httpClient.json.get(endpoints.auth.resendEmail, {
    params: {
      email,
    },
  });

export const setDefaultPaymentMethod = async (
  data: ISetDefaultPaymentParam,
): Promise<{
  billing_details: {
    address: {
      country?: string;
    };
  }[];
}> => httpClient.json.post(endpoints.auth.setDefaultPayment, data);

export const verifyEmail = async ({ email }) =>
  httpClient.json.post(endpoints.auth.verifyEmail, {
    email,
  });

export const sendResetPasswordEmail = async ({ email }) =>
  httpClient.json.post(endpoints.auth.sendResetPasswordEmail, {
    email,
  });
export const setNewPassword = async ({ email, code, password }) =>
  httpClient.json.post(endpoints.auth.setNewPassword, {
    email,
    code,
    password,
  });

export const generateCustomToken = (
  rfToken,
): Promise<{
  custom_token?: string;
  id_token?: string;
  refresh_token?: string;
}> =>
  httpClient.json.post(endpoints.auth.generateCustomToken, {
    refresh_token: rfToken,
  });

export const generateCustomTokenSSO = () =>
  httpClient.json.post(
    `${endpoints.sso.auth}`,
    {},
    {
      baseURL: SSO_AUTH_ENDPOINT,
      withCredentials: true,
    },
  );

export const verifyAdminChangePassword = (data: {
  email: string;
  code: string;
}): Promise<any> =>
  httpClient.json.post(endpoints.auth.verifyAdminChangePassword, data);

export const useGetAccounts = ({
  enabled = true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSuccess = (resp: {
    has_fb_account?: boolean;
    has_app?: boolean;
    has_bs?: boolean;
    users?: IAccount[];
  }) => {},
  onError = () => {},
  params = {},
}) =>
  useQuery({
    queryKey: [queryKeys.settings.account, params],
    queryFn: () => getAccounts(params),
    initialData: { users: [] },
    onSuccess,
    onError,
    enabled,
    retry: false,
    select: (data) => ({
      ...data,
      users: convertGeneralSettingsResponses(data?.users),
    }),
  });

export const handleLoginSuccess = async (
  isNewSSO,
  options?: {
    isInvalidateQueries?: boolean;
    isSetCustomClaim?: boolean;
    isValidateCustomClaim?: boolean;
    isShowAlert?: boolean;
  },
) => {
  const user: any = await getCurrentUser();

  // check custom claim for user.
  if (options?.isValidateCustomClaim) {
    const { is_canfleet } = jwtDecode<{ is_canfleet: string }>(
      user.accessToken,
    );

    if (!is_canfleet) {
      showAlert(
        errorAlert(
          "User's account has not been registered with Breadstack Delivery.",
        ),
      );
      return;
    }
  }

  if (options?.isSetCustomClaim) {
    await setCustomClaim();
  }
  await logRequestSignIn();

  if (isNewSSO) await generateCustomTokenSSO();
  else {
    const stsTokenManager = user?.stsTokenManager || {};
    const tokenResult = await generateCustomToken(stsTokenManager.refreshToken);
    Cookies.set(CUSTOM_TOKEN_COOKIES_KEY, tokenResult?.custom_token, {
      ...CookieOptions,
    });

    Cookies.set(
      RF_CUSTOM_TOKEN_COOKIES_KEY,
      tokenResult?.refresh_token,
      CookieOptions,
    );
  }

  memoryStorage.setIdToken(user.accessToken);

  if (options.isInvalidateQueries) {
    queryClient.invalidateQueries([queryKeys.settings.account]);
  }
  channel.postMessage({
    type: BroadcastEvents.RefreshOtherPageWhenLogged,
  });
};

export const useLogin = ({
  options = {
    isInvalidateQueries: true,
    isSetCustomClaim: false,
    isValidateCustomClaim: false,
    isShowAlert: true,
  },
  onSuccess,
  onError,
}: {
  onSuccess?: (resp: any) => void;
  onError?: (errMsg: any, error?: any) => void;
  options?: {
    isInvalidateQueries?: boolean;
    isSetCustomClaim?: boolean;
    isValidateCustomClaim?: boolean;
    isShowAlert?: boolean;
  };
}) => {
  const isNewSSO = useFeatureIsOn(
    'acd-11071-cookie-attributes-not-set-optimal-security',
  );

  if (isNewSSO) {
    Cookies.remove(RF_CUSTOM_TOKEN_COOKIES_KEY, CookieOptions);
    Cookies.remove(CUSTOM_TOKEN_COOKIES_KEY, CookieOptions);
  }

  return useMutation({
    mutationFn: signIn,
    onSuccess: async () => {
      const user: any = await getCurrentUser();
      if (!user?.emailVerified) {
        await signOut();
        window.location.href = `/register/sent?email=${encodeURIComponent(
          user?.email,
        )}`;
        return;
      }

      await handleLoginSuccess(isNewSSO, options);
      onSuccess?.(user);
    },
    onError: (errors: FirebaseError) => {
      const errorMsg = formatFirebaseError(errors);
      if (options.isShowAlert) {
        showAlert(errorAlert(errorMsg));
      }
      onError?.(errorMsg, errors);
    },
  });
};

export const useLogout = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (resp: any) => void;
  onError?: (err: any) => void;
} = {}) => {
  const isNewSSO = useFeatureIsOn(
    'acd-11071-cookie-attributes-not-set-optimal-security',
  );
  const fn = isNewSSO ? signOutSSO : signOut;
  return useMutation({
    mutationFn: fn,
    onSuccess: (data) => {
      httpClient.json.clearToken();
      memoryStorage.setOrgId(null);
      memoryStorage.setIdToken(null);
      if (!isNewSSO) {
        Cookies.remove(CUSTOM_TOKEN_COOKIES_KEY, CookieOptions);
        Cookies.remove(RF_CUSTOM_TOKEN_COOKIES_KEY, CookieOptions);
      }
      channel.postMessage({
        type: BroadcastEvents.RefreshOtherPageWhenLogout,
      });
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError,
  });
};

export const useGetBillingInfo = (params) => {
  const { onSuccess, onError } = params;
  return useQuery({
    queryKey: ['getBillingInfo'],
    queryFn: getBillingInfo,
    onSuccess,
    onError,
  });
};

export const useSetDefaultPaymentMethod = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: setDefaultPaymentMethod,
    onSuccess,
    onError,
  });
};

export const useActiveAccount = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: activeAccount,
    onSuccess,
    onError,
  });
};

export const useChangePwd = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (resp: any) => void;
  onError?: (err: any) => void;
}) =>
  useMutation({
    mutationFn: changePwd,
    onSuccess,
    onError,
  });

export const useVerifyAccount = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: verifyAccount,
    onSuccess,
    onError,
  });
};

export const useRegisterFb = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (resp: any) => void;
  onError?: (err: any) => void;
}) =>
  useMutation({
    mutationFn: signUpFb,
    onSuccess: async (resp) => {
      onSuccess?.(resp);
    },
    onError: (errors: FirebaseError) => {
      const errorMsg = formatFirebaseError(errors);
      showAlert(errorAlert(errorMsg));
      onError?.(errors);
    },
  });

export const useRegister = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (resp: any) => void;
  onError?: (err: any) => void;
}) =>
  useMutation({
    mutationFn: signUp,
    onSuccess,
    onError,
  });

export const useVerifyEmail = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: verifyEmail,
    onSuccess,
    onError,
  });
};

export const useGenerateCustomToken = (params) => {
  const { rfToken, onSuccess, onError, enabled } = params;
  return useQuery({
    queryKey: ['generateCustomToken', rfToken],
    queryFn: () => generateCustomToken(rfToken),
    refetchInterval: 50 * 60 * 1000,
    enabled: !!rfToken && enabled,
    initialData: {},
    onSuccess: (resp: any) => {
      Cookies.set(CUSTOM_TOKEN_COOKIES_KEY, resp?.custom_token, {
        ...CookieOptions,
      });
      Cookies.set(
        RF_CUSTOM_TOKEN_COOKIES_KEY,
        resp?.refresh_token,
        CookieOptions,
      );
      onSuccess?.(resp);
    },
    onError: () => {
      Cookies.remove(CUSTOM_TOKEN_COOKIES_KEY, CookieOptions);
      Cookies.remove(RF_CUSTOM_TOKEN_COOKIES_KEY, CookieOptions);
      onError?.();
    },
  });
};

export const useCheckSessionSSO = (params) => {
  const { onSuccess, onError, enabled } = params;
  return useQuery(['generateCustomToken'], () => checkAuth(), {
    refetchInterval: 50 * 60 * 1000,
    refetchOnWindowFocus: true,
    enabled,
    onSuccess: (resp: any) => {
      onSuccess?.(resp);
    },
    onError: () => {
      onError?.();
    },
  });
};

export const useSendResetPasswordEmail = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: sendResetPasswordEmail,
    onSuccess,
    onError,
  });
};

export const useSetNewPassword = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: setNewPassword,
    onSuccess,
    onError,
  });
};

export const useVerifyAdminChangePassword = (params) => {
  const { onSuccess, onError, data } = params;
  return useQuery({
    queryKey: ['verifyAdminChangePassword', data],
    queryFn: () => verifyAdminChangePassword(data),
    enabled: !!(data?.email && data?.code),
    onSuccess,
    onError,
  });
};

export const useVerifyReCaptcha = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: verifyReCaptcha,
    onSuccess,
    onError,
  });
};

export const useResendVerificationEmail = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: resentEmail,
    onSuccess,
    onError,
  });
};
