import React, { useCallback, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import themes, { styled } from '~/themes';
import { Formik, Form } from 'formik';
import { IconButton, Stack, Typography } from '@mui/material';
import Button from '~/components/common/Button';
import { Field } from '~/components/common/Formik';
import {
  ONGOING_STATUS_OPTIONS,
  COMPLETED_STATUS_OPTIONS,
} from '~/constants/task';
import { fromQueryString } from '~/utils/queryString';
import { ITabValue } from '~/models/shared';
import SearchIcon from '~/assets/images/icons/search-line.svg';
import { debounce, isEqual } from 'lodash';
import { useGetDriverListQuery } from '../AdminOptions/Drivers/apis';
import { UserRole, UserStatus } from '../AdminOptions/Users/types';

export const StyledForm = styled(Form)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  'width': theme.spacing(2.2),
  'height': theme.spacing(2.2),
  'marginRight': theme.spacing(-0.8),
  '& > img': {
    width: 16,
    height: 16,
  },
}));

export const StyledFilterStack = styled(Stack)(({ theme }) => ({
  'flexDirection': 'row',
  'alignItems': 'center',
  'flexWrap': 'wrap',
  'rowGap': theme.spacing(2),
  'columnGap': theme.spacing(0.8),
  '& > div': {
    '&:first-of-type': {
      width: 300,
    },
    'width': 160,
  },
  [theme.breakpoints.down('md')]: {
    '& > *': {
      '&:not(:first-of-type)': {
        display: 'none',
      },
    },
    '& > button': {
      display: 'none',
    },
    '& > div': {
      '&:first-of-type': {
        width: '100%',
        marginRight: 0,
      },
    },
  },
}));

interface IFilterFormProps {
  onSubmit?: (values: any) => void;
  onClear?: () => void;
  onClearAll?: () => void;
}

const defaultValues = {
  search: '',
  executor_ids: '',
  statuses: '',
};

export const FilterForm: React.FC<IFilterFormProps> = ({
  onSubmit,
  onClear,
  onClearAll,
}) => {
  const location = useLocation();
  const formRef = useRef<any>(null);

  const { tab, search, statuses, executor_ids } = fromQueryString(
    location.search,
  );
  const tabValue = (tab || ITabValue.Ongoing) as ITabValue;

  const initialValues = {
    search: search || '',
    executor_ids: executor_ids || '',
    statuses: statuses || '',
  };

  const { data: listDrivers } = useGetDriverListQuery({
    roles: UserRole.Driver,
    status: UserStatus.Active,
  });

  const handleSearch = useCallback(
    debounce(() => {
      formRef.current.handleSubmit();
    }, 600), // Delay 500ms
    [],
  );

  const renderDriverList = useMemo(
    () => (
      <Field.Select
        key='driver'
        placeholder='Driver'
        name='executor_ids'
        options={
          listDrivers && listDrivers.length > 0
            ? [
                ...listDrivers.map((driver) => ({
                  label:
                    driver.display_name.length > 16
                      ? `${driver.display_name.slice(0, 16)}...`
                      : driver.display_name,
                  value: driver.id,
                  key: driver.id,
                })),
              ]
            : []
        }
        sxPlaceholder={{
          color: `${themes.color.grayPlaceHolder} !important`,
        }}
        onChange={() => formRef.current.handleSubmit()}
      />
    ),
    [listDrivers],
  );

  const renderTaskStatus = useMemo(
    () => (
      <Field.Select
        placeholder='Status'
        name='statuses'
        options={
          tabValue === ITabValue.Ongoing
            ? ONGOING_STATUS_OPTIONS
            : COMPLETED_STATUS_OPTIONS
        }
        sxPlaceholder={{
          color: `${themes.color.grayPlaceHolder} !important`,
        }}
        onChange={() => formRef.current.handleSubmit()}
      />
    ),
    [tabValue],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {({ setFieldValue, resetForm, values }) => (
        <StyledForm>
          <StyledFilterStack>
            <Field.SearchInput
              name='search'
              placeholder='Search Task'
              onClear={() => {
                onClear();
                setFieldValue('search', '');
              }}
              onChange={(e) => {
                setFieldValue('search', e.target.value);
                handleSearch();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  formRef.current.handleSubmit();
                }
              }}
              prefix={
                <StyledIconButton>
                  <img
                    alt='search-icon'
                    src={SearchIcon}
                    width={22}
                    height={22}
                  />
                </StyledIconButton>
              }
              sx={{
                'paddingLeft': '8px !important',
                '& .MuiOutlinedInput-input': {
                  '&::placeholder': {
                    color: 'rgba(51, 54, 61, 0.40) !important',
                  },
                },
                '& .MuiButtonBase-root': {
                  marginRight: '4px !important',
                },
                '& .MuiInputAdornment-root': {
                  marginRight: '0px !important',
                  marginLeft: '0px !important',
                },
                '& input': {
                  paddingRight: '0 !important',
                },
                'backgroundColor': '#F8F8FC',
              }}
              showSeachIcon={false}
            />
            {renderTaskStatus}
            {renderDriverList}
            {!isEqual(values, defaultValues) && (
              <Button
                buttonType='text'
                onClick={() => {
                  resetForm();
                  onClearAll();
                }}
                sx={{
                  '&.MuiButton-text': {
                    minWidth: 'unset !important',
                  },
                }}
              >
                <Typography
                  sx={{
                    color: themes.color.violet500,
                    fontWeight: 500,
                  }}
                >
                  Clear
                </Typography>
              </Button>
            )}
          </StyledFilterStack>
        </StyledForm>
      )}
    </Formik>
  );
};
