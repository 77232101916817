import { Box, Stack, SxProps } from '@mui/system';
import { Theme } from '~/themes';
import { Icon } from '../../Icon';
import { TextLink } from '../../TextLink';

export interface IPieLegendItem {
  color: string;
  title: string | number;
  titleSx?: SxProps<Theme>;
  description: string;
  externalLink?: string;
}

interface IPieLegend {
  items: IPieLegendItem[];
  bigTitle?: string | number;
  bigDescription?: string;
}

const PieLegend: React.FC<IPieLegend> = ({
  items,
  bigTitle,
  bigDescription,
}) => (
  <Stack direction='column' gap={2}>
    {bigTitle && (
      <Stack direction='column' pb={1}>
        <Box fontSize={24} fontWeight='bold'>
          {bigTitle}
        </Box>
        <Box fontSize={12} color='#9B9B9B'>
          {bigDescription}
        </Box>
      </Stack>
    )}
    {items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Stack direction='row' key={`${item.title}_${index}`} gap={1}>
        <Box
          width={12}
          height={12}
          borderRadius={12}
          sx={{
            backgroundColor: item.color,
          }}
          mt={0.7}
        />
        <Stack direction='column'>
          <Box fontSize={20} fontWeight='bold' sx={item.titleSx || {}}>
            {item.title}
            {item.externalLink && (
              <TextLink style={{ marginLeft: 8 }} to={item.externalLink}>
                <Icon name='open-external-link' useBackgroundImg size={14} />
              </TextLink>
            )}
          </Box>
          <Box fontSize={12} color='#9B9B9B'>
            {item.description}
          </Box>
        </Stack>
      </Stack>
    ))}
  </Stack>
);

export default PieLegend;
