import { useMemo } from 'react';
import { Avatar, Badge, Typography } from '@mui/material';
import { DriverStatus } from '~/pages/LiveTracking/types';
import themes from '~/themes';
import Tooltip from '~/components/common/Tooltip';
import { formattedIdleTime } from '~/pages/LiveTracking/utils';
import PauseIcon from '~/assets/images/icons/pause.svg';
import { Stack } from '@mui/system';

type Props = {
  status?: string;
  avatar: string;
  size?: number;
  name?: string;
  receiveAutoAssignTask?: boolean;
  idleAt?: string;
};

export const stringToAvatar = (s: string) => {
  const trimmedString = s.trim();
  return trimmedString[0]?.toUpperCase() || 'D';
};

export const genAvatarSrc = (avatar: string) => {
  if (!avatar) return '';
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  const isBase64Avatar = base64regex.test(avatar);
  return isBase64Avatar ? `data:image/png;base64, ${avatar}` : avatar;
};

export const DriverAvatar = (props: Props) => {
  const {
    status,
    avatar,
    size = 30,
    name,
    receiveAutoAssignTask,
    idleAt,
  } = props;
  const isOnlineDriver = status === DriverStatus.Online;
  const isIdleDriver = status === DriverStatus.Idle;
  const isOfflineDriver = status === DriverStatus.Offline;
  const isHiddenBadge = !status;
  const avatarSrc = genAvatarSrc(avatar);

  const tooltipTitle = useMemo(() => {
    if (status === DriverStatus.Offline) return 'Offline';
    if (status === DriverStatus.Idle)
      return `Idle - Since ${formattedIdleTime(idleAt)} ago`;
    if (status === DriverStatus.Online)
      return `Online${
        !receiveAutoAssignTask ? ' - Pause auto-assign tasks' : ''
      }`;
    return '';
  }, [status, receiveAutoAssignTask, idleAt]);

  const avatarComponent = (
    <Avatar
      src={avatarSrc}
      alt='driver-avatar'
      sx={{ width: size, height: size }}
      {...(!avatar &&
        name?.trim() && {
          children: stringToAvatar(name),
          sx: {
            width: size,
            height: size,
            backgroundColor: '#bdbdbd',
            fontWeight: 500,
            ...(size < 30 && {
              fontSize: size / 1.5,
            }),
          },
        })}
    />
  );

  if (isHiddenBadge) {
    return avatarComponent;
  }

  return (
    <Tooltip
      title={
        <Typography sx={{ whiteSpace: 'break-spaces', color: 'white' }}>
          {tooltipTitle}
        </Typography>
      }
      placement='bottom-start'
      arrow
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -6],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#33363D',
            borderRadius: '3px',
          },
        },
        arrow: {
          sx: {
            color: '#33363D',
          },
        },
      }}
    >
      <Stack
        sx={{
          position: 'relative',
        }}
      >
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: themes.color.gray600,
              color: themes.color.gray600,
              border: '1px solid white',
              height: 12,
              width: 12,
              borderRadius: '50%',
              ...(isOnlineDriver && {
                backgroundColor: themes.color.green300,
              }),
              ...(isIdleDriver && {
                backgroundColor: themes.color.orange,
              }),
              ...(isOfflineDriver && {
                backgroundColor: themes.color.gray500,
              }),
            },
          }}
          overlap='circular'
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          variant='dot'
        >
          {avatarComponent}
          {!receiveAutoAssignTask && status === DriverStatus.Online && (
            <Stack
              sx={{
                position: 'absolute',
                bottom: -1,
                right: -1,
              }}
            >
              <img src={PauseIcon} alt='receive_auto_assign_task' />
            </Stack>
          )}
        </Badge>
      </Stack>
    </Tooltip>
  );
};
