import { URLS } from '~/config';
import { IOptionItem } from '~/models/common';
import { transformTypeOptions } from '~/utils/common';

export const STORES_DRAWER_TAB_VALUE = {
  General: 'General',
  Teams: 'Teams',
  TaskSettings: 'Task Settings',
  DeliveryHours: 'Hours',
  DeliveryZoneFee: 'Zones & Fees',
  OrderLimit: 'Order Limit',
  Notify: 'Notify Customers',
};

export const STORES_DRAWER_TABS: IOptionItem[] = transformTypeOptions(
  STORES_DRAWER_TAB_VALUE,
);

export const GAP_TIME_OPTIONS = [
  {
    value: 15,
    label: '15 minutes',
  },
  {
    value: 30,
    label: '30 minutes',
  },
  {
    value: 60,
    label: '1 hour',
  },
  {
    value: 90,
    label: '1.5 hours',
  },
  {
    value: 120,
    label: '2 hours',
  },
  {
    value: 150,
    label: '2.5 hours',
  },
  {
    value: 180,
    label: '3 hours',
  },
  {
    value: 210,
    label: '3.5 hours',
  },
  {
    value: 240,
    label: '4 hours',
  },
  {
    value: 270,
    label: '4.5 hours',
  },
  {
    value: 300,
    label: '5 hours',
  },
];

export const deliveryHoursDefaultData = {
  monday: {
    from_time: '09:00',
    to_time: '21:00',
  },
  tuesday: {
    from_time: '09:00',
    to_time: '21:00',
  },
  wednesday: {
    from_time: '09:00',
    to_time: '21:00',
  },
  thursday: {
    from_time: '09:00',
    to_time: '21:00',
  },
  friday: {
    from_time: '09:00',
    to_time: '21:00',
  },
  saturday: {
    from_time: '09:00',
    to_time: '21:00',
  },
  sunday: {
    from_time: '09:00',
    to_time: '21:00',
  },
};

export const weekdays = [
  { label: 'Sun', value: 'sunday' },
  { label: 'Mon', value: 'monday' },
  { label: 'Tue', value: 'tuesday' },
  { label: 'Wed', value: 'wednesday' },
  { label: 'Thu', value: 'thursday' },
  { label: 'Fri', value: 'friday' },
  { label: 'Sat', value: 'saturday' },
];
const urls = URLS ? JSON.parse(URLS) : {};

export const CanFleetPluginURL = {
  Woo: urls.wooPlugIn,
  Breadstack: urls.breadstackPlugin,
};

export const importFileUrl = {
  file: urls.importFilePostalCodeUrl,
};

export const TIME_SLOT_TYPE = {
  NONE: 'none',
  AUTO: 'auto',
  CUSTOM: 'custom',
};

export const TIME_SLOT_OPTIONS = [
  {
    value: TIME_SLOT_TYPE.AUTO,
    label: 'Generate time slots',
  },
  {
    value: TIME_SLOT_TYPE.CUSTOM,
    label: 'Customize individual time slots',
  },
  {
    value: TIME_SLOT_TYPE.NONE,
    label:
      'No time slot. The date slot is only available before the last order time.',
  },
];
