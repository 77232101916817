import {
  useRef,
  createContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { delay, isEqual, uniqWith, unionBy, isEmpty } from 'lodash';
import { usePreviousValue } from '~/utils/hooks/usePreviousValue';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import useMediaQuery from '@mui/material/useMediaQuery';
import themes from '~/themes';
import { StoreMarkers } from '../libs/StoreMarkers';
import { DropOffMarkers } from '../libs/DropOffMarkers';
import {
  MAP_BOUNDS_PADDING_DESKTOP,
  MAP_BOUNDS_PADDING_MOBILE,
  MAP_BOUNDS_PADDING_SMALL_SCREEN,
  mapOptions,
} from '../constants';
import {
  DriverMarkerContent,
  DropOffMarkerContent,
  StoreMarkerContent,
} from '../components/Markers/MarkerContents';
import {
  Driver,
  LocationInfo,
  MapBoundsPadding,
  MarkerType,
  Task,
} from '../types';
import { DriverMarkers } from '../libs/DriverMarkers';
import {
  groupStoreLocations,
  groupTasksByPosition,
  isValidLatLngLiteral,
} from '../utils';
import { TaskRoutes } from '../libs/TaskRoutes';
import { DriverRoutes } from '../libs/DriverRoutes';
import { DriverRoutesWithOutDragDrop } from '../libs/DriverRoutesWithOutDragDrop';

export interface LiveMapProviderProps {}
export interface LiveMapContextProps {
  map: google.maps.Map | null;
  mapRef: any;
  dropOffMarkers: DropOffMarkers;
  storeMarkers: StoreMarkers;
  driverMarkers: DriverMarkers;
  taskRoutes: TaskRoutes;
  driverRoutes: DriverRoutes | DriverRoutesWithOutDragDrop;
  multiTaskIds: string[];
  isAssignMultiTask?: boolean;
  isOpenTaskDetail?: boolean;
  isOpenManageRoutes?: boolean;
  taskId?: string;
  driverId?: string;
  viewControls: MarkerType[];
  manageRoutesRef: React.Ref<any>;
  expandedLiveMonitoring: boolean;
  mapBoundsPadding: MapBoundsPadding;
  setExpandedLiveMonitoring: React.Dispatch<React.SetStateAction<boolean>>;
  setViewControls: React.Dispatch<React.SetStateAction<MarkerType[]>>;
  addMarkerWithTasks: (
    tasks: Task[],
    opts?: { isAddMarkerToCluster: boolean },
  ) => void;
  addMarkerWithDrivers: (
    drivers: Driver[],
    opts?: { isAddMarkerToCluster: boolean },
  ) => void;
  addMarkerWithStoreLocations: (locations: LocationInfo[]) => void;
  showMarkers: (
    markerTypes: MarkerType[],
    mode?: {
      taskList?: boolean;
      taskDetail?: boolean;
      manageRoute?: boolean;
    },
  ) => void;
  hideMarkers: (
    markerTypes: MarkerType[],
    mode?: {
      taskList?: boolean;
      taskDetail?: boolean;
      manageRoute?: boolean;
    },
  ) => void;
  handleCloseTaskDetail: () => void;
  handleOpenTaskDetail: (id: string) => void;
  handleOpenManageRoutes: (id: string) => void;
  handleCloseManageRoutes: () => void;
  setMultiTaskIds: (v: any) => any;
  handleMapFitBounds: (
    positions: google.maps.LatLngLiteral[],
    options: {
      padding: number | google.maps.Padding;
      zoom?: number;
      panByX?: number;
      wait?: number;
    },
  ) => void;
}
interface IAllPositions {
  tasks?: google.maps.LatLngLiteral[];
  drivers?: google.maps.LatLngLiteral[];
  stores?: google.maps.LatLngLiteral[];
}

export const LiveMapContext = createContext({} as LiveMapContextProps);
export const LiveMapProvider: React.FC<LiveMapProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const matchesSmallScreen = useMediaQuery(
    themes.breakpoints.between('lg', 'ss'),
  );

  const isEnabledManageRouteDragDrop = useFeatureIsOn(
    'cf-enabled-manage-route-drag-drop',
  );
  const [map, setMap] = useState<google.maps.Map | null>();
  const mapRef = useRef();
  const dropOffMarkersRef = useRef<DropOffMarkers>(null);
  const prevTasksRef = useRef<Task[]>([]);
  const storeMarkersRef = useRef<StoreMarkers>(null);
  const driverMarkerRef = useRef<DriverMarkers>(null);
  const taskRoutesRef = useRef<TaskRoutes>(null);
  const driverRoutesRef = useRef<DriverRoutes | DriverRoutesWithOutDragDrop>(
    null,
  );
  const manageRoutesRef = useRef(null);
  const timerHandleRef = useRef({});
  const trafficLayerRef = useRef<google.maps.TrafficLayer | null>(null);

  const [allPositions, setAllPositions] = useState<IAllPositions>({
    tasks: [],
    drivers: [],
    stores: [],
  });

  const previousAllPositions = usePreviousValue<{
    tasks?: google.maps.LatLngLiteral[];
    drivers?: google.maps.LatLngLiteral[];
    stores?: google.maps.LatLngLiteral[];
  }>(allPositions);

  const [multiTaskIds, setMultiTaskIds] = useState<string[]>([]);
  const [viewControls, setViewControls] = useState<MarkerType[]>([
    MarkerType.Driver,
    MarkerType.Store,
    MarkerType.DropOff,
    MarkerType.Unassigned,
    MarkerType.Pending,
    MarkerType.Inprogress,
  ]);

  const searchQueries = fromQueryString(location.search);
  const taskId = (searchQueries.task_id as string) || '';
  const driverId = (searchQueries.driver_id as string) || '';

  const isOpenTaskDetail = !!taskId;
  const isOpenManageRoutes = !!driverId;
  const isAssignMultiTask = !!multiTaskIds.length;

  const [expandedLiveMonitoring, setExpandedLiveMonitoring] = useState(false);
  const [hasTaskId, setHasTaskId] = useState(false);
  const [hasDriverId, setHasDriverId] = useState(false);

  const mapBoundsPadding: MapBoundsPadding = useMemo(() => {
    if (matchesSmallScreen) {
      return MAP_BOUNDS_PADDING_SMALL_SCREEN;
    }
    if (isTablet && !isDesktop) {
      return MAP_BOUNDS_PADDING_SMALL_SCREEN;
    }
    if (isMobile && !isDesktop) {
      return MAP_BOUNDS_PADDING_MOBILE;
    }
    if (isDesktop) {
      return MAP_BOUNDS_PADDING_DESKTOP;
    }
    return MAP_BOUNDS_PADDING_DESKTOP;
  }, [
    expandedLiveMonitoring,
    matchesSmallScreen,
    isMobile,
    isTablet,
    isDesktop,
  ]);

  const DEFAULT_MIN_ZOOM = 3;
  const DEFAULT_ONE_POINT_ZOOM = 13;
  const ROUTES_ONE_POINT_ZOOM = 16;
  const DEFAULT_PAN_BY_X = -mapBoundsPadding.DEFAULT.left / 3;
  const ROUTES_PAN_BY_X = -mapBoundsPadding.DRIVER_ROUTES.left / 2;

  const addMarkerWithTasks = useCallback(
    (
      tasks: Task[],
      opts: {
        isAddMarkerToCluster?: boolean;
      } = {},
    ) => {
      dropOffMarkersRef?.current?.hideMarkers();
      dropOffMarkersRef?.current?.markers.removeAll();

      prevTasksRef.current = [...tasks];
      const filterTask = tasks?.filter((task) =>
        viewControls?.includes(task?.status as any),
      );

      if (map && filterTask.length) {
        const allPos: google.maps.LatLngLiteral[] = filterTask
          .map((t) => ({ lat: t.delivery?.lat, lng: t.delivery?.lng }))
          .filter((p) => isValidLatLngLiteral(p));

        setAllPositions((prev) => ({
          ...prev,
          tasks: allPos,
        }));

        const tasksGrouped = groupTasksByPosition(filterTask);
        Object.keys(tasksGrouped).forEach((positionKey) => {
          const dropOffLocations = tasksGrouped[positionKey].filter(
            (l) => l.marker_type === MarkerType.DropOff,
          );

          dropOffMarkersRef?.current.addMarkers(
            dropOffLocations,
            opts.isAddMarkerToCluster,
          );
        });
      } else {
        setAllPositions((prev) => ({
          ...prev,
          tasks: [],
        }));
      }
    },
    [map, viewControls],
  );

  useEffect(() => {
    setMap(new window.google.maps.Map(mapRef.current, mapOptions));
  }, []);

  useEffect(() => {
    if (!map) return;
    if (!trafficLayerRef.current) {
      trafficLayerRef.current = new google.maps.TrafficLayer();
    }
    const isTrafficLayerAttached = trafficLayerRef.current.getMap() !== null;
    if (viewControls.includes(MarkerType.Traffic)) {
      if (!isTrafficLayerAttached) trafficLayerRef.current.setMap(map);
    } else {
      trafficLayerRef.current.setMap(null);
    }
    if (!isOpenTaskDetail && !isOpenManageRoutes)
      addMarkerWithTasks(prevTasksRef.current, {
        isAddMarkerToCluster: true,
      });
  }, [viewControls, map]);

  useEffect(() => {
    if (!map) return;

    if (dropOffMarkersRef.current) {
      dropOffMarkersRef.current?.setFitBoundsPadding(mapBoundsPadding.DEFAULT);
    } else {
      dropOffMarkersRef.current = new DropOffMarkers(
        map,
        {
          render({ count, position }) {
            const content = DropOffMarkerContent({ clustersCount: count });

            return new google.maps.marker.AdvancedMarkerElement({
              content,
              position,
              // adjust zIndex to be above other markers
              zIndex: count,
            });
          },
        },
        {
          fitBoundsPadding: mapBoundsPadding.DEFAULT,
        },
        navigate,
      );
    }

    if (storeMarkersRef.current) {
      storeMarkersRef.current?.setFitBoundsPadding(mapBoundsPadding.DEFAULT);
    } else {
      storeMarkersRef.current = new StoreMarkers(
        map,
        {
          render({ count, position }) {
            const content = StoreMarkerContent({ clustersCount: count });

            return new google.maps.marker.AdvancedMarkerElement({
              content,
              position,
              // adjust zIndex to be above other markers
              zIndex: count,
            });
          },
        },
        {
          fitBoundsPadding: mapBoundsPadding.DEFAULT,
        },
      );
    }

    if (!driverMarkerRef.current) {
      driverMarkerRef.current = new DriverMarkers(map, navigate);
    }
    if (!taskRoutesRef.current) {
      taskRoutesRef.current = new TaskRoutes(map);
    }
  }, [map, mapBoundsPadding]);

  useEffect(() => {
    if (map) {
      if (isEnabledManageRouteDragDrop) {
        driverRoutesRef.current = new DriverRoutes(map);
      } else {
        driverRoutesRef.current = new DriverRoutesWithOutDragDrop(map);
      }
    }
  }, [map, isEnabledManageRouteDragDrop]);

  const showMarkers = useCallback(
    (
      markerTypes: MarkerType[],
      mode: {
        default?: boolean;
        taskDetail?: boolean;
        manageRoute?: boolean;
      } = {},
    ) => {
      const markerRefs = {
        [MarkerType.DropOff]: dropOffMarkersRef,
        [MarkerType.Store]: storeMarkersRef,
        [MarkerType.Driver]: driverMarkerRef,
      };

      if (mode.taskDetail) {
        taskRoutesRef?.current?.showMarkers(markerTypes);
      }

      if (mode.default) {
        markerTypes.forEach((markerType) => {
          markerRefs[markerType]?.current?.showMarkers();
        });
      }

      if (mode.manageRoute) {
        markerTypes.forEach((markerType) => {
          if (markerType === MarkerType.Store) {
            markerRefs[MarkerType.Store]?.current?.showMarkers();
          } else {
            driverRoutesRef?.current?.showMarkers(markerTypes);
          }
        });
      }
    },
    [],
  );

  const hideMarkers = useCallback(
    (
      markerTypes: MarkerType[],
      mode: {
        default?: boolean;
        taskDetail?: boolean;
        manageRoute?: boolean;
      } = {},
    ) => {
      const markerRefs = {
        [MarkerType.DropOff]: dropOffMarkersRef,
        [MarkerType.Store]: storeMarkersRef,
        [MarkerType.Driver]: driverMarkerRef,
      };

      if (mode.taskDetail) {
        taskRoutesRef?.current?.hideMarkers(markerTypes);
      }

      if (mode.default) {
        markerTypes.forEach((markerType) => {
          markerRefs[markerType]?.current?.hideMarkers();
        });
      }

      if (mode.manageRoute) {
        markerTypes.forEach((markerType) => {
          if (markerType === MarkerType.Store) {
            markerRefs[MarkerType.Store]?.current?.hideMarkers();
          } else {
            driverRoutesRef?.current?.hideMarkers(markerTypes);
          }
        });
      }
    },
    [],
  );

  const handleMapFitBounds = useCallback(
    (
      positions: google.maps.LatLngLiteral[],
      options: {
        padding: number | google.maps.Padding;
        zoom?: number;
        panByX?: number;
        wait?: number; // disabled if wait = -1
      },
    ) => {
      if (!map) return;
      const { padding, zoom, panByX, wait = 100 } = options;
      const bounds = new google.maps.LatLngBounds();
      const positionFiltered = positions.filter((p) => !!p);

      positionFiltered.forEach((pos) => {
        if (pos) {
          bounds.extend(pos);
        }
      });

      const onePoint = uniqWith(positionFiltered, isEqual).length === 1;
      const currentZoom = map.getZoom();
      if (wait !== -1) {
        delay(() => {
          map.setZoom(
            currentZoom < DEFAULT_MIN_ZOOM ? DEFAULT_MIN_ZOOM : currentZoom,
          );
          map.fitBounds(bounds, padding);
          if (onePoint) {
            map.setZoom(zoom || ROUTES_ONE_POINT_ZOOM);
            map.panBy(panByX || ROUTES_PAN_BY_X, 0);
          }
        }, wait);
      } else {
        map.setZoom(
          currentZoom < DEFAULT_MIN_ZOOM ? DEFAULT_MIN_ZOOM : currentZoom,
        );
        map.fitBounds(bounds, padding);
        if (onePoint) {
          map.setZoom(zoom || ROUTES_ONE_POINT_ZOOM);
          map.panBy(panByX || ROUTES_PAN_BY_X, 0);
        }
      }
    },
    [map],
  );

  useEffect(() => {
    const params = fromQueryString(location.search);
    const tId = params.task_id;

    if (tId && !hasTaskId) {
      setHasTaskId(true);
    } else if (!tId && hasTaskId) {
      setHasTaskId(false);
      // Trigger action task_id has been removed from the query parameters
      const pos = Object.values(allPositions).flat();
      handleMapFitBounds(pos, {
        padding: mapBoundsPadding.DEFAULT,
        zoom: DEFAULT_ONE_POINT_ZOOM,
        panByX: DEFAULT_PAN_BY_X,
      });
    }
  }, [location, hasTaskId, allPositions, map, mapBoundsPadding]);

  useEffect(() => {
    const params = fromQueryString(location.search);
    const dId = params.driver_id;

    if (dId && !hasDriverId) {
      setHasDriverId(true);
    } else if (!dId && hasDriverId) {
      setHasDriverId(false);
      // Trigger action driver_id has been removed from the query parameters
      const pos = Object.values(allPositions).flat();
      handleMapFitBounds(pos, {
        padding: mapBoundsPadding.DEFAULT,
        zoom: DEFAULT_ONE_POINT_ZOOM,
        panByX: DEFAULT_PAN_BY_X,
      });
    }
  }, [location, hasDriverId, allPositions, map, mapBoundsPadding]);

  useEffect(() => {
    setViewControls([
      MarkerType.Driver,
      MarkerType.DropOff,
      MarkerType.Store,
      MarkerType.Unassigned,
      MarkerType.Pending,
      MarkerType.Inprogress,
    ]);
    if (isOpenTaskDetail) {
      hideMarkers([MarkerType.Driver, MarkerType.DropOff, MarkerType.Store], {
        default: true,
      });
    } else if (isOpenManageRoutes) {
      hideMarkers([MarkerType.Driver, MarkerType.DropOff], {
        default: true,
      });
    } else {
      taskRoutesRef?.current?.hideMarkers();
      taskRoutesRef?.current?.clearRoute();

      driverRoutesRef?.current?.clearDriverRoutes();
      driverRoutesRef?.current?.clearDriverInfoWindow();
      driverRoutesRef?.current?.clearDriverRoutesMarkers();

      showMarkers([MarkerType.Driver, MarkerType.DropOff, MarkerType.Store], {
        default: true,
      });
    }
  }, [map, isOpenTaskDetail, isOpenManageRoutes]);

  useEffect(() => {
    if (!isOpenTaskDetail && !isOpenManageRoutes && previousAllPositions) {
      const pos = Object.values(allPositions).flat();
      if (!pos.length) {
        // the map will default to Vancouver
        map?.setCenter(mapOptions.center);
      }
      const { tasks, stores } = allPositions;
      const { tasks: prevTasks, stores: prevStores } = previousAllPositions;
      if (
        (isEmpty(prevTasks) && !isEmpty(tasks)) ||
        (isEmpty(prevStores) && !isEmpty(stores))
      ) {
        handleMapFitBounds(pos, {
          padding: mapBoundsPadding.DEFAULT,
          zoom: DEFAULT_ONE_POINT_ZOOM,
          panByX: DEFAULT_PAN_BY_X,
        });
      }
    }
  }, [
    map,
    isOpenTaskDetail,
    isOpenManageRoutes,
    allPositions,
    previousAllPositions,
    mapBoundsPadding,
  ]);

  const handleOpenTaskDetail = useCallback((id: string) => {
    navigate(`?task_id=${id}`);
    setMultiTaskIds([]);
  }, []);

  const handleCloseTaskDetail = useCallback(() => {
    setMultiTaskIds([]);
    navigate('');
  }, []);

  const handleOpenManageRoutes = useCallback((id: string) => {
    navigate(`?driver_id=${id}`);
  }, []);

  const handleCloseManageRoutes = useCallback(() => {
    setMultiTaskIds([]);
    navigate('');
  }, []);

  const goToPointMarker = useCallback(
    (
      id: string,
      marker: google.maps.marker.AdvancedMarkerElement,
      points: google.maps.LatLngLiteral[],
      m_s: number,
      delayTime = 200,
    ) => {
      let pointNum = 0;

      function goToPoint() {
        const { lat: startLat, lng: startLng } = points[pointNum];
        const { lat: destLat, lng: destLng } = points[pointNum + 1];

        const step = (m_s * delayTime) / 1000;

        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          points[pointNum],
          points[pointNum + 1],
        ); // in meters

        const numStep = distance / step;
        let currentDistance = 0;
        const deltaLat = (destLat - startLat) / numStep;
        const deltaLng = (destLng - startLng) / numStep;
        let lat = startLat;
        let lng = startLng;

        function moveMarker() {
          lat += deltaLat;
          lng += deltaLng;
          currentDistance += step;
          if (currentDistance < distance) {
            marker.position = { lat, lng };
            timerHandleRef.current[id] = setTimeout(moveMarker, delayTime);
          } else {
            marker.position = points[pointNum + 1];
            if (pointNum < points.length - 2) {
              pointNum += 1;
              goToPoint();
            }
          }
        }
        moveMarker();
      }
      goToPoint();
    },
    [],
  );

  const animateMarker = useCallback(
    (
      marker: google.maps.marker.AdvancedMarkerElement,
      dest: google.maps.LatLngLiteral,
      directionsService: google.maps.DirectionsService,
      id: string,
    ) => {
      let current: google.maps.LatLngLiteral;
      const { position } = marker;

      if (position instanceof google.maps.LatLng) {
        current = { lat: position.lat(), lng: position.lng() };
      } else {
        current = position;
      }

      if (current.lat === dest.lat && current.lng === dest.lng) return;

      directionsService.route(
        {
          origin: current,
          destination: dest,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            const { legs } = response.routes[0];
            const distance = legs?.[0]?.distance?.value || 0;
            if (!distance) return;
            let points =
              legs?.[0]?.steps?.map((loc) => ({
                lat: loc?.end_location.lat(),
                lng: loc?.end_location.lng(),
              })) || [];
            points = unionBy(
              [current, ...points, dest],
              (item) => `${item.lat}-${item.lng}`,
            );
            const m_s = distance / 10;
            goToPointMarker(id, marker, points, m_s);
          } else {
            const points = [current, dest];
            const distance =
              google.maps.geometry.spherical.computeDistanceBetween(
                current,
                dest,
              );
            if (!distance) return;
            const m_s = distance / 10;
            goToPointMarker(id, marker, points, m_s);
          }
        },
      );
    },
    [],
  );

  const addMarkerWithDrivers = useCallback(
    (
      drivers: Driver[],
      opts: {
        isAddMarkerToCluster?: boolean;
      } = {},
    ) => {
      if (!map || !drivers.length) return;

      const currentMarkers = driverMarkerRef.current?.markers?.getAll();
      currentMarkers?.forEach(({ id }) => {
        if (timerHandleRef.current[id]) {
          clearTimeout(timerHandleRef.current[id]);
          timerHandleRef.current[id] = null;
        }

        if (!drivers?.find((driver) => driver.id === id)) {
          driverMarkerRef.current?.markers?.remove(id);
        }
      });

      drivers.forEach(
        ({ id, avatar, lat, lng, driver_status, display_name }) => {
          if (!lat || !lng) return;

          const marker = driverMarkerRef?.current?.markers.get(id);

          const driverContent = DriverMarkerContent({
            avatar,
            driverStatus: driver_status,
            name: display_name,
          });
          const position = { lat, lng };

          if (!marker) {
            driverMarkerRef.current.addMarker({
              id,
              position,
              content: driverContent,
              isAddMarkerToCluster: opts.isAddMarkerToCluster,
            });
          } else {
            driverMarkerRef.current.updateMarker({
              id,
              content: driverContent,
              isAddMarkerToCluster: opts.isAddMarkerToCluster,
            });
            animateMarker(
              marker.marker,
              { lat, lng },
              driverMarkerRef?.current?.directionsService,
              id,
            );
          }
        },
      );
    },
    [map],
  );

  const addMarkerWithStoreLocations = useCallback(
    (locations: LocationInfo[]) => {
      setAllPositions((prev) => ({
        ...prev,
        stores: locations.length
          ? locations
              .map((d) => ({ lat: d.lat, lng: d.lng }))
              .filter((p) => isValidLatLngLiteral(p))
          : [],
      }));
      if (locations.length === 0) return;
      storeMarkersRef?.current?.hideMarkers();

      const isAddMarkerToCluster =
        !isOpenTaskDetail && viewControls.includes(MarkerType.Store);
      const locationGrouped = groupStoreLocations(locations);
      Object.keys(locationGrouped).forEach((key) => {
        storeMarkersRef?.current?.addMarkers(
          locationGrouped[key],
          isAddMarkerToCluster,
        );
      });
    },
    [map],
  );

  const contextValue = useMemo(
    () => ({
      map,
      mapRef,
      dropOffMarkers: dropOffMarkersRef.current,
      storeMarkers: storeMarkersRef.current,
      driverMarkers: driverMarkerRef.current,
      taskRoutes: taskRoutesRef.current,
      driverRoutes: driverRoutesRef.current || null,
      multiTaskIds,
      isAssignMultiTask,
      isOpenTaskDetail,
      isOpenManageRoutes,
      taskId,
      driverId,
      viewControls,
      manageRoutesRef,
      expandedLiveMonitoring,
      mapBoundsPadding,
      handleMapFitBounds,
      setViewControls,
      addMarkerWithTasks,
      addMarkerWithDrivers,
      addMarkerWithStoreLocations,
      showMarkers,
      hideMarkers,
      handleCloseTaskDetail,
      handleOpenTaskDetail,
      handleOpenManageRoutes,
      handleCloseManageRoutes,
      setMultiTaskIds,
      setExpandedLiveMonitoring,
    }),
    [
      map,
      multiTaskIds,
      isAssignMultiTask,
      isOpenTaskDetail,
      isOpenManageRoutes,
      taskId,
      driverId,
      viewControls,
      expandedLiveMonitoring,
      mapBoundsPadding,
    ],
  );

  return (
    <LiveMapContext.Provider value={contextValue}>
      {children}
    </LiveMapContext.Provider>
  );
};
