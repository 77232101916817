/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '~/themes';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { Rating } from '~/components/common/Rating';
import StarIcon from '~/assets/images/icons/star-large.svg';
import EmptyStarIcon from '~/assets/images/icons/empty-star-large.svg';
import { ITrackData } from '~/models/track';
import { useUpdateTrackRating } from '~/services/api/track';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import { useParams } from 'react-router-dom';
import TrackFeedback from './TrackFeedback';
import { formatDateTimeLocal } from './TrackVerticalProgress';

const style = (theme: Theme) =>
  createStyles({
    contentContainer: {
      padding: theme.spacing(1.6),
    },
    ratingContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    feedbackContainer: {},
    closeFeedback: {
      marginLeft: -12,
      color: theme.color.black,
      marginBottom: theme.spacing(1),
    },
    ratingTitle: {
      marginBottom: theme.spacing(1),
      textAlign: 'center',
      whiteSpace: 'pre-line',
      fontWeight: 'bold',
      color: theme.color.violet500,
    },
    ratingDesc: {
      color: '#717D96',
      fontSize: 14,
      marginBottom: theme.spacing(1.6),
    },
    message: {
      fontSize: 18,
      marginBottom: theme.spacing(1),
    },
    addFeedback: {
      color: theme.color.violet900,
      fontSize: 16,
      fontWeight: 600,
      marginTop: theme.spacing(3),
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    textInputContainer: {
      padding: 0,
    },
    textInput: {
      'fontSize': 16,
      '&::placeholder': {
        color: theme.color.gray900,
      },
    },
    feedbackMessage: {
      backgroundColor: theme.color.gray300,
      fontSize: 16,
      padding: theme.spacing(1),
      marginTop: theme.spacing(4),
      width: 'calc(100% - 20px)',
    },
  });

interface ITrackRate extends WithStyles<typeof style> {
  data: ITrackData;
}

const TrackRate: React.FC<ITrackRate> = ({ classes, data }) => {
  const calendarFormats = {
    sameDay: 'h:mm a [today]',
    nextDay: 'h:mm a [tomorrow]',
    lastDay: 'h:mm a [yesterday]',
    nextWeek: 'h:mm a | MM/DD/YYYY',
    lastWeek: 'h:mm a | MM/DD/YYYY',
    sameElse: 'h:mm a | MM/DD/YYYY',
  };
  const { code } = useParams();
  const [openComment, setOpenComment] = useState(false);

  const { mutate: updateTrackRating } = useUpdateTrackRating({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.track.getTrack]);
    },
  });

  const submitRate = (value: number) => {
    updateTrackRating({
      id: code,
      rating: value,
    });
  };

  if (openComment) {
    return <TrackFeedback setOpenComment={setOpenComment} />;
  }

  const isRated = !!data.rating;
  const hasFeedback = !!data.feed_back;

  return (
    <div className={clsx(classes.contentContainer, classes.ratingContainer)}>
      <Typography
        variant='h1'
        className={clsx(classes.message, classes.ratingTitle)}
      >
        {isRated ? 'Thank you!' : 'Rate your delivery'}
      </Typography>
      <div className={classes.ratingDesc}>
        {isRated
          ? `Your rating${
              hasFeedback ? ' and feedback were' : ' was'
            } submitted.`
          : `Your order has been delivered at ${formatDateTimeLocal(
              data?.delivery_completed_time,
              calendarFormats,
            )}`}
      </div>
      <Rating
        customSize={40}
        customStar={StarIcon}
        customEmptyStar={EmptyStarIcon}
        value={data.rating}
        readOnly={isRated}
        onChange={isRated ? null : (e, newValue) => submitRate(newValue)}
      />
      {isRated && hasFeedback && (
        <div className={classes.feedbackMessage}>{data?.feed_back}</div>
      )}
      {isRated && !hasFeedback ? (
        <div
          className={classes.addFeedback}
          onClick={() => setOpenComment(true)}
          role='button'
          tabIndex={0}
        >
          Add feedback
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default withStyles(style)(TrackRate);
