import React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { Theme } from '~/themes';
import { IOptionItem } from '~/models/common';
import Tabs from './Tabs';

const style = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: 43,
    },
    tab: {
      minHeight: 30,
      marginRight: 0,
    },
    flexContainer: {
      borderBottom: '2px solid #E9E7F6',
    },
    indicator: {
      'height': '2px !important',
      'borderRadius': 2,
      'bottom': 6,
      '& > span': {
        maxWidth: 60,
        minWidth: 60,
        width: '100%',
        backgroundColor: theme.color.primaryOrange,
        borderRadius: theme.spacing(1),
      },
    },
    label: {
      textTransform: 'none',
    },
  });

interface Props extends WithStyles<typeof style> {
  className?: string;
  tabs: IOptionItem[];
  dividerBefore?: Array<number>;
  value: string;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => void;
  lowerCaseLabel?: boolean;
  containerClassName?: string;
}

const DrawerTabs: React.FC<Props> = ({
  classes,
  className,
  lowerCaseLabel = false,
  variant = 'fullWidth',
  containerClassName,
  ...props
}) => (
  <Tabs
    className={clsx(classes.root, className)}
    tabClassName={classes.tab}
    indicatorClassName={classes.indicator}
    labelClassName={clsx({ [classes.label]: lowerCaseLabel })}
    flexContainerClassName={clsx(classes.flexContainer, containerClassName)}
    variant={variant}
    {...props}
  />
);

export default withStyles(style)(DrawerTabs);
