import { useQuery } from '@tanstack/react-query';
import httpClient from '~/lib/client/httpClient';
import { toQueryString } from '~/utils/queryString';
import endpoints from './endpoints';
import { queryKeys } from './queryKeys';

export interface IDashboardParams {
  date_gte: string;
  length: number;
  timezone: string;
}

export interface IDashboard {
  completed_tasks?: number;
  cancelled_tasks?: number;
  returned_tasks?: number;
  unique_recipients?: number;
  late_tasks?: number;
  on_time_and_late_tasks_by_day?: OnTimeAndLateTasksByDay;
  time_driver_spent_on_tasks_per_day?: number;
  time_driver_idle_per_day?: number;
  avg_time_to_assignment?: number;
  tasks_without_reassignment?: number;
  tasks_with_reassignment?: number;
  avg_rating?: number;
  low_reviews?: any[];
  high_reviews?: any[];
  timezone?: string;
  driver_table_info?: DriverTableInfo[];
  hit_map_data: any;
  store_locations: any;
  heat_map_data: any;
  cancelled_tasks_trend?: {
    difference: number;
  };
  completed_tasks_trend?: {
    difference: number;
  };
  late_tasks_trend?: {
    difference: number;
  };
  returned_tasks_trend?: {
    difference: number;
  };
  on_time_tasks_trend?: {
    difference: number;
  };
  current_engagement_rate?: number;
  engagement_trend?: {
    trend: number;
  };
  weekly_hourly_tasks?: any;
  on_time_tasks?: number;
}

export interface DriverTableInfo {
  completed_task?: number;
  driver_avg_rate?: number;
  driver_avg_on_time?: number;
  driver_ulitization_rate?: number;
  total_distance?: number;
  avatar?: string;
  full_name?: string;
  id?: string;
}

export interface OnTimeAndLateTasksByDay {
  headers?: string[];
  data?: Array<Array<Date | number>>;
}

export enum PrecisionLevel {
  Precision3 = 'precision_3',
  Precision4 = 'precision_4',
  Precision5 = 'precision_5',
}

export type HitMapData = {
  precisionKey: PrecisionLevel;
  zones?: {
    totalTask?: number;
    centerPoint?: { lat: number; lng: number };
    latLngs: { lat: number; lng: number }[];
  }[];
};

export const getDashboard = (params: IDashboardParams): Promise<IDashboard> =>
  httpClient.json.get(
    `${endpoints.dashboard.dashboard}?${toQueryString(params)}`,
  );

export const useGetDashboardQuery = ({
  params,
  enabled,
  onSuccess,
  onError,
}: {
  params: IDashboardParams;
  enabled?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
}) =>
  useQuery({
    queryKey: [queryKeys.dashboard.getDashboard, params],
    queryFn: () => getDashboard(params),
    initialData: null,
    onSuccess,
    onError,
    enabled,
  });
