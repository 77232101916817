import { Button, IconButton, Menu, Stack, Typography } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import './style.scss';
import moment from 'moment-timezone';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import 'react-calendar/dist/Calendar.css';

type Props = {
  anchorEl?: any;
  open: boolean;
  handleClose: () => void;
  handleSelect: (values: any) => void;
  timezone?: string;
};

const DateRangeSelector = ({
  anchorEl,
  open,
  handleClose,
  handleSelect,
  timezone,
}: Props) => {
  const nowTzDate = useMemo(() => {
    const now = moment().tz(timezone);
    return new Date(
      now.year(),
      now.month(),
      now.date(),
      now.hour(),
      now.minute(),
      now.second(),
    );
  }, [timezone]);

  const endDateRef = useRef<any>();

  const [dateRange, setDateRange] = useState<any>([nowTzDate, nowTzDate]);
  const [activeStartDate, setActiveStartDate] = useState(moment());
  const [count, setCount] = useState(0);

  const handleNextMonth = () => {
    const nextMonth = moment(activeStartDate).add(1, 'months');
    setActiveStartDate(nextMonth);
  };

  const handlePrevMonth = () => {
    const prevMonth = moment(activeStartDate).subtract(1, 'months');
    setActiveStartDate(prevMonth);
  };

  const isValidRangeDate = useMemo(() => {
    const [startDate, endDate] = dateRange;
    const mStartDate = moment(startDate)?.local();
    const mEndDate = moment(endDate)?.local();

    if (
      mStartDate.isSameOrBefore(mEndDate, 'date') &&
      mEndDate.isSameOrBefore(moment(nowTzDate), 'date') &&
      mStartDate.isSameOrAfter(moment('2000/01/01'), 'date')
    )
      return true;
    return false;
  }, [dateRange]);

  const handleSubmit = () => {
    const startDate = moment(dateRange[0])?.local();
    const endDate = moment(dateRange[1])?.local();

    if (startDate.isSame(endDate, 'days')) {
      handleSelect({
        endDate: endDate?.clone(),
        selectedDate: 1,
      });
    } else {
      handleSelect({
        endDate,
        selectedDate: endDate.diff(startDate, 'days') + 1,
      });
    }
  };

  const handleKeyDownStartDate = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      endDateRef.current?.focus();
    }
  };

  const handleKeyDownEndDate = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && isValidRangeDate) {
      handleSubmit();
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{ paper: 'custom-paper' }}
      open={open}
      onClose={() => handleClose()}
      MenuListProps={{
        sx: {
          padding: 0,
        },
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: '8px',
            p: 2.4,
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.16)',
          },
        },
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            color: '#33363D',
            fontSize: 18,
            fontWeight: 700,
          }}
        >
          {activeStartDate?.format('MMMM YYYY')}{' '}
        </Typography>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <IconButton
            onClick={handlePrevMonth}
            sx={{
              color: '#33363D',
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            onClick={handleNextMonth}
            sx={{
              color: '#33363D',
            }}
            disabled={moment(activeStartDate).isSame(moment(), 'months')}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Stack>
      </Stack>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1.6,
            mt: 0.5,
          }}
        >
          <Stack
            sx={{
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 500,
                color: '#33363D',
              }}
            >
              From date
            </Typography>
            <DateField
              label=''
              format='MM/DD/YYYY'
              value={moment(dateRange[0])?.local()}
              disableFuture
              maxDate={moment(nowTzDate)}
              minDate={moment('2000-01-01')}
              onChange={(newValue) => {
                const newStartDate = moment(newValue);
                const endDate = dateRange[1];
                if (newStartDate.isValid()) {
                  setCount((prev) => prev + 1);
                  setDateRange([newStartDate.toDate(), endDate]);
                }
              }}
              onKeyDown={handleKeyDownStartDate}
            />
          </Stack>
          <Stack
            sx={{
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 500,
                color: '#33363D',
              }}
            >
              To date
            </Typography>
            <DateField
              label=''
              format='MM/DD/YYYY'
              value={moment(dateRange[1])?.local()}
              disableFuture
              minDate={moment('2000-01-01')}
              maxDate={moment(nowTzDate)}
              onChange={(newValue) => {
                const startDate = dateRange[0];
                const newEndDate = moment(newValue);
                if (newEndDate.isValid()) {
                  setCount((prev) => prev + 1);
                  setDateRange([startDate, newEndDate.toDate()]);
                }
              }}
              onKeyDown={handleKeyDownEndDate}
              inputRef={endDateRef}
            />
          </Stack>
        </Stack>
      </LocalizationProvider>
      <Stack
        sx={{
          width: '100%',
          aspectRatio: '1/1.1',
        }}
      >
        <DateRangePicker
          key={`${count}`}
          className='custom-date-range'
          isOpen
          calendarIcon={null}
          clearIcon={null}
          onChange={(newDateRange) => {
            setDateRange(newDateRange);
            if (newDateRange[1]) {
              setActiveStartDate(moment(newDateRange[1]));
            } else if (newDateRange[0]) {
              setActiveStartDate(moment(newDateRange[0]));
            }
          }}
          value={dateRange}
          closeCalendar={false}
          maxDate={nowTzDate}
          calendarProps={{
            activeStartDate: activeStartDate?.toDate(),
            onActiveStartDateChange: ({ value }: any) =>
              setDateRange([
                moment(value).local().toDate(),
                moment(value).local().toDate(),
              ]),
            formatShortWeekday: (_, date) => moment(date).format('dd'),
          }}
        />
      </Stack>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 1.2,
          justifyContent: 'flex-end',
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        <Button
          sx={{
            'color': '#121142',
            'width': '70px',
            'backgroundColor': 'white',
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              textDecoration: 'underline',
            },
          }}
          onClick={() => {
            setDateRange([nowTzDate, nowTzDate]);
            setActiveStartDate(moment());
            setCount((prev) => prev + 1);
          }}
        >
          Clear
        </Button>
        <Button
          sx={{
            'color': 'white',
            'width': '70px',
            'backgroundColor': '#121142',
            'borderRadius': '6px',
            '&:hover': {
              backgroundColor: '#121142',
              opacity: 0.8,
            },
          }}
          onClick={handleSubmit}
          disabled={!isValidRangeDate}
        >
          Apply
        </Button>
      </Stack>
    </Menu>
  );
};

export default DateRangeSelector;
