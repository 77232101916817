import { Box } from '@mui/system';
import React from 'react';
import { Skeleton } from '@mui/material';
import UpTrendIcon from '~/assets/images/icons/up-trend.svg';
import DownTrendIcon from '~/assets/images/icons/down-trend.svg';
import themes from '~/themes';
import { StyledContainer } from '../styles';

export interface ISummaryItem {
  title: string;
  count: number;
  trend: number;
}

interface ISummary {
  items: ISummaryItem[];
  isLoading: boolean;
}

const Summary: React.FC<ISummary> = ({ items, isLoading }) => (
  <StyledContainer
    sx={{
      justifyContent: isLoading ? 'space-around' : 'space-between',
      flexDirection: 'row',
      gap: 1.6,
      [themes.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    }}
  >
    {items.map((item) =>
      isLoading ? (
        <Skeleton height={47} width={85} />
      ) : (
        <Box
          key={item.title}
          display='flex'
          flexDirection='column'
          alignItems='start'
          gap={0.4}
          flex={1}
          sx={{
            [themes.breakpoints.down('sm')]: {
              alignItems: 'center',
            },
          }}
        >
          <Box fontSize={14} color='#717D96'>
            {item.title}
          </Box>
          <Box fontSize={20} fontWeight='bold' color='#33363D'>
            {item.count}
          </Box>
          <Box
            fontSize={12}
            color={item.trend < 0 ? '#F24822' : '#1BC47D'}
            fontWeight={500}
            display='flex'
            flexDirection='row'
            gap={0.4}
            alignItems='center'
            justifyContent='center'
          >
            <img src={item.trend < 0 ? DownTrendIcon : UpTrendIcon} alt='' />
            {Math.abs(item.trend)}
          </Box>
        </Box>
      ),
    )}
  </StyledContainer>
);

export default Summary;
