import { useMutation, useQuery } from '@tanstack/react-query';
import httpClient from '~/lib/client/httpClient';
import {
  Zone,
  IBSStore,
  IBSStoreParams,
  ICheckConnectionParams,
  ICheckConnectionResponse,
  ICreateStore,
  IStore,
  Delivery,
} from '~/models/stores';
import { alertParams, showAlert } from '~/components/common/Alert';
import { queryClient } from '~/lib/react-query';
import { cleanDeep, toQueryString } from '~/utils/queryString';
import { queryKeys } from './queryKeys';
import endpoints from './endpoints';

export const checkConnectionInAdmin = (params: {
  integration_id: string;
}): Promise<any> => httpClient.json.post('check_connection_in_admin', params);

export const getListIntegration = (params): Promise<IStore[]> => {
  cleanDeep(params);
  return httpClient.json.get(`/integrations?${toQueryString(params)}`);
};

export const getAllIntegration = (): Promise<IStore[]> =>
  httpClient.json.get(`/integrations:all`);

export const getListHubZone = (params): Promise<Zone[]> =>
  httpClient.json.post(`/hub_zones`, params);

export const createHubZones = (body): Promise<{ id: string; zone: Zone }> =>
  httpClient.json.post(`/hub_zones`, body);

export const createIntegration = (
  params: ICreateStore,
): Promise<{ id: string; zone: Zone }> =>
  httpClient.json.post(endpoints.integration.integration, params);

export const checkConnection = (
  params: ICheckConnectionParams,
): Promise<ICheckConnectionResponse> =>
  httpClient.json.get(endpoints.integration.checkConnectionOnboarding, {
    params,
  });

export const getBreadstackStores = (
  params: IBSStoreParams,
): Promise<IBSStore[]> =>
  httpClient.json.get(endpoints.integration.breadStackStores, {
    params,
  });
export const connectToShopify = (params: any): Promise<any> =>
  httpClient.json.post('shopify/install', params);

export const previewTimeSlot = (params: IStore): Promise<Delivery> =>
  httpClient.json.post('integrations:preview_time_slot', {
    delivery: params,
    id: params.id,
  });

export const getPreviewTimeSlot = ({
  delivery,
  id,
  date_from,
  offset,
}: any): Promise<any> =>
  httpClient.json.post('integrations:get_time_slot', {
    delivery,
    id,
    date_from,
    offset,
  });

export const exportPostalCode = async (storeId): Promise<any> =>
  httpClient.fullRes.get(`integrations/export-postal-zone/${storeId}`, {
    responseType: 'blob',
  });

export const uploadPostalCodes = ({
  storeId,
  file,
}: {
  storeId: string | string[];
  file: any;
}): Promise<any> =>
  httpClient.formData.post(`integrations/import-postal-zone/${storeId}`, file);

export const getPostalCodeUploadedInfo = (
  id: string,
): Promise<{
  status?: string;
  created_at?: string;
  updated_at?: string;
  progress?: string;
  id?: string;
  failed_info?: Array<{ [k: string]: any }>;
}> => httpClient.json.get(`integrations/import-postal-zone/status/${id}`);

export const useGetListIntegrationQuery = (params) =>
  useQuery({
    queryKey: [queryKeys.stores.getListIntegration, params],
    queryFn: () => getListIntegration(params),
    initialData: () =>
      queryClient.getQueryData([queryKeys.stores.getListIntegration, params]),
  });

export const useGetAllIntegrationQuery = () =>
  useQuery({
    queryKey: [queryKeys.stores.getAllIntegration],
    queryFn: () => getAllIntegration(),
    initialData: () =>
      queryClient.getQueryData([queryKeys.stores.getAllIntegration]),
  });

export const useCreateHubZonesMutation = ({ onSuccess }) =>
  useMutation({
    mutationFn: createHubZones,
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries([queryKeys.stores.getListIntegration]);
      queryClient.invalidateQueries([queryKeys.stores.getListZone]);
      showAlert({
        ...alertParams.success,
        title: 'Add Zone successfully',
      });
    },
  });

export const useUpdateHubZonesMutation = (data) => {
  const { onSuccess } = data;
  return useMutation({
    mutationFn: createHubZones,
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries([queryKeys.stores.getListIntegration]);
      queryClient.invalidateQueries([queryKeys.stores.getListZone]);
      showAlert({
        ...alertParams.success,
        title: 'Update Zone successfully',
      });
    },
  });
};

export const useGetListHubZone = (params) =>
  useQuery({
    queryKey: [queryKeys.stores.getListZone, params.parameters],
    queryFn: () => getListHubZone(params),
    initialData: [],
  });

export const useCreateIntegration = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: createIntegration,
    onSuccess,
    onError,
  });

export const useCheckConnection = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: checkConnection,
    onSuccess,
    onError,
  });

export const useGetBreadstackStores = (
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (resp: any) => void;
    onError?: (err: any) => void;
  } = {
    onSuccess: () => {},
    onError: () => {},
  },
) =>
  useMutation({
    mutationFn: getBreadstackStores,
    onSuccess,
    onError,
  });

export const useCheckConnectionInAdmin = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: checkConnectionInAdmin,
    onSuccess,
    onError,
  });
};

export const useConnectToShopify = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: connectToShopify,
    onSuccess,
    onError,
  });
};

export const usePreviewTimeSlot = (data) => {
  const { onSuccess, onError } = data;
  return useQuery({
    queryKey: ['usePreviewTimeSlot', data.params],
    queryFn: () => previewTimeSlot(data.params),
    onSuccess,
    onError,
  });
};

export const useGenTimeSlot = (data) => {
  const { onSuccess, onError } = data;
  return useMutation({
    mutationFn: (params: IStore) => previewTimeSlot(params),
    onSuccess,
    onError,
  });
};

export const useExportPostalCodes = () =>
  useMutation({
    mutationFn: exportPostalCode,
  });

export const useUploadPostalCodes = (params) => {
  const { onSuccess, onError } = params;
  return useMutation({
    mutationFn: uploadPostalCodes,
    onSuccess,
    onError,
  });
};

export const useGetPostalCodeUploadedInfo = (params) => {
  const { id, onSuccess, onError } = params;
  return useQuery({
    queryKey: [queryKeys.stores.getStatusUploadPostalCode, id],
    queryFn: () => getPostalCodeUploadedInfo(id),
    enabled: !!id,
    initialData: { failed_info: [] },
    refetchInterval: 2000,
    onSuccess,
    onError,
  });
};

export const useGetPreviewTimeSlot = ({ params, enabled = true }) =>
  useQuery({
    queryKey: [queryKeys.stores.previewTimeSlot, params],
    queryFn: () => getPreviewTimeSlot(params),
    enabled,
  });
