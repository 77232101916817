/* eslint-disable max-len */
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React from 'react';
import { Theme } from '~/themes';

const style = (theme: Theme) =>
  createStyles({
    container: {
      margin: 0,
      padding: theme.spacing(0.5, 0, 0, 1.7),
    },
    item: {
      '&:last-child': {
        marginBottom: 0,
      },
    },
  });

interface IProps extends WithStyles<typeof style> {}

const TooltipPassword: React.FC<IProps> = ({ classes }) => (
  <>
    <div>Your password must contain:</div>
    <ul className={classes.container}>
      <li className={classes.item}>At least eight characters in total</li>
      <li className={classes.item}>At least one lower-case letter</li>
      <li className={classes.item}>At least one upper-case letter</li>
      <li className={classes.item}>
        At least one of these special characters:{' '}
        {`!"#$%&'()*+,-./:;<=>?@^_{|}~`}
      </li>
      <li className={classes.item}>No spaces</li>
    </ul>
  </>
);

export default withStyles(style)(TooltipPassword);
