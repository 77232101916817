import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { SSE_ENDPOINT } from '~/config';
import { firebaseGetToken } from '~/services/firebase';
import { memoryStorage } from '~/utils/localStorage';

const TIMEOUT_DURATION = 2 * 60 * 1000;
const MIN_CALL_INTERVAL = 5000; // 5 seconds

function logWithMoment(message, event: any = '') {
  const formattedTime = moment().format('HH:mm:ss');
  console.log(`[${formattedTime}] ${message}`, event);
}

const useCheckRefreshTask = () => {
  const [hasUpdate, setHasUpdate] = useState(true);
  const [hasConnected, setHasConnected] = useState(false);
  const reconnectAttemptsRef = useRef(0);
  const eventSourceRef = useRef(null);
  const timeoutRef = useRef(null);
  const timeoutReconnectRef = useRef(null);
  const isWaitingRef = useRef(false);
  const isWaitingTimeoutRef = useRef(null);
  const autoUpdateTimeoutRef = useRef(null);

  const createEventSource = async () => {
    const token = await firebaseGetToken();
    const currentOrgId = memoryStorage.getOrgId();

    const eventSource = new EventSource(
      `${SSE_ENDPOINT}/sse/tasks/?token=${token}&canfleet-org-id=${currentOrgId}`,
    );

    const handleReconnection = () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(timeoutReconnectRef.current);
      const delay = Math.min(
        5 * 1000 * reconnectAttemptsRef.current,
        TIMEOUT_DURATION,
      );
      timeoutReconnectRef.current = setTimeout(() => {
        reconnectAttemptsRef.current += 1;
        createEventSource();
      }, delay);
    };

    timeoutRef.current = setTimeout(() => {
      logWithMoment('Connection timed out. Reconnecting...');

      eventSource.close();
      handleReconnection();
    }, TIMEOUT_DURATION);

    eventSource.onopen = (event) => {
      logWithMoment('Connected to server:', event);
      setHasUpdate(true);
      reconnectAttemptsRef.current = 0;
      setHasConnected(true);
      clearTimeout(timeoutRef.current);

      autoUpdateTimeoutRef.current = setTimeout(() => {
        logWithMoment('1 hour passed since connection. Auto-updating...');
        setHasUpdate(true);
      }, 60 * 60 * 1000);
    };

    eventSource.onmessage = (event) => {
      logWithMoment('On update', event);

      const isRefresh = JSON.parse(event?.data);
      if (isRefresh && !isWaitingRef.current) {
        isWaitingRef.current = true;
        isWaitingTimeoutRef.current = setTimeout(() => {
          setHasUpdate(true);
          isWaitingRef.current = false;
        }, MIN_CALL_INTERVAL);
      }
    };

    eventSource.onerror = (event) => {
      logWithMoment('Connection failed. Attempting to reconnect...', event);

      setHasConnected(false);
      eventSource.close();
      handleReconnection();
      clearTimeout(autoUpdateTimeoutRef.current);
    };

    eventSourceRef.current = eventSource;
  };

  const resetUpdate = () => {
    setHasUpdate(false);
  };

  const forceUpdate = () => {
    setHasUpdate(true);
  };

  useEffect(() => {
    createEventSource();

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current?.close();
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (isWaitingTimeoutRef.current) {
        clearTimeout(isWaitingTimeoutRef.current);
      }
      if (autoUpdateTimeoutRef.current) {
        clearTimeout(autoUpdateTimeoutRef.current);
      }
    };
  }, []);

  return {
    hasUpdate,
    resetUpdate,
    hasConnected,
    forceUpdate,
  };
};

export default useCheckRefreshTask;
