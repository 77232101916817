import React, { useMemo } from 'react';
import { NewColumnChart } from 'analytics-library';
import { Box, Stack } from '@mui/system';
import moment from 'moment';
import ChartContainer from '../ChartContainer';

const colors = ['#34BF4B', '#E3301D'];

const loadingColors = ['#E5E5E5', '#E5E5E5'];

export interface ITaskItem {
  date: string;
  value: number;
  type: string;
  tooltipDate?: string;
}

interface ITasks {
  isLoading: boolean;
  items: ITaskItem[];
}

const Tasks: React.FC<ITasks> = ({ isLoading, items }) => {
  const taskLegend = (
    <Stack direction='row' gap={2} ml='auto'>
      <Stack direction='row' gap={0.5} alignItems='center'>
        <Box
          width={12}
          height={12}
          borderRadius={0.5}
          sx={{ backgroundColor: colors[0] }}
        />
        <Box fontSize={12} fontWeight='normal' color='#9B9B9B'>
          On time task
        </Box>
      </Stack>
      <Stack direction='row' gap={0.5} alignItems='center'>
        <Box
          width={12}
          height={12}
          borderRadius={0.5}
          sx={{ backgroundColor: colors[1] }}
        />
        <Box fontSize={12} fontWeight='normal' color='#9B9B9B'>
          Late task
        </Box>
      </Stack>
    </Stack>
  );

  const itemLength = items?.length || 0;

  const extraProps = useMemo(() => {
    // number is days x 2
    switch (itemLength) {
      case 180:
        return {
          columnStyle: (item) => {
            if (item.type === 'Late tasks') {
              return {};
            }
            return {
              radius: [0, 0, 0, 0],
            };
          },
          columnWidthRatio: 1,
        };
      case 60:
        return {
          columnStyle: (item) => {
            if (item.type === 'Late tasks') {
              return {};
            }
            return {
              radius: [4, 4, 0, 0],
            };
          },
          maxColumnWidth: 10,
        };
      case 28:
      case 14:
      default:
        return {
          columnStyle: (item) => {
            if (item.type === 'Late tasks') {
              return {};
            }
            return {
              radius: [4, 4, 0, 0],
            };
          },
          minColumnWidth: 22,
        };
    }
  }, [itemLength]);

  const tickMethodVal = useMemo(() => {
    switch (itemLength) {
      case 180: {
        const dateRange = 7;
        return Array(Math.round(itemLength / 2 / dateRange))
          .fill('')
          .map((_, i) => i * dateRange);
      }
      default:
        return null;
    }
  }, [itemLength]);

  return (
    <ChartContainer
      title='Task performance'
      sxContainer={{ gap: 3 }}
      sxContent={{ maxHeight: 200, minHeight: 200 }}
      titleDescription={taskLegend}
    >
      <NewColumnChart
        color={isLoading ? loadingColors : colors}
        data={items}
        xField='date'
        yField='value'
        seriesField='type'
        isStack
        legend={false}
        tooltip={
          isLoading
            ? false
            : {
                position: 'top',
                fields: ['value'],
                title: (title, data) => data.tooltipDate,
                customItems: (originalItems) => [
                  {
                    ...originalItems[0],
                    name: '',
                  },
                  {
                    ...originalItems[1],
                    name: '',
                  },
                ],
                domStyles: {
                  'g2-tooltip': {
                    width: '70px',
                    height: '83px',
                    borderRadius: '2px',
                    boxShadow: '0px 3px 6px #00000029',
                    background: '#33363D',
                  },
                  'g2-tooltip-title': {
                    color: '#FFF',
                    fontSize: '12px',
                    marginLeft: '12px',
                  },
                  'g2-tooltip-list': {
                    marginTop: '35px',
                    color: '#33363D',
                  },
                  'g2-tooltip-list-item': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2px',
                    margin: '8px 0 0 0',
                  },
                  'g2-tooltip-marker': {
                    width: '12px',
                    height: '12px',
                    borderRadius: '2px',
                    marginRight: '0px',
                  },
                  'g2-tooltip-value': {
                    fontWeight: 500,
                    color: '#FFF',
                    marginLeft: 0,
                    float: 'unset',
                    fontSize: '14px',
                  },
                },
              }
        }
        xAxis={{
          label: {
            offset: 15,
            style: {
              fontSize: 12,
            },
            formatter: (date) => moment(date, 'YYYY/MM/DD').format('MMM DD'),
          },
          tickLine: null,
          line: null,
          min: 0,
          ...(tickMethodVal && { tickMethod: () => tickMethodVal }),
        }}
        yAxis={{
          label: {
            offset: 20,
          },
          grid: {
            line: {
              style: {
                stroke: '#DEE2E6',
                lineWidth: 1.2,
                lineDash: [3, 4],
                strokeOpacity: 2,
                fillOpacity: 1,
                shadowOffsetX: 5,
                shadowOffsetY: 5,
              },
            },
          },
        }}
        {...extraProps}
      />
    </ChartContainer>
  );
};

export default Tasks;
