import { createStyles, Divider, Typography } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { Box, Stack } from '@mui/system';
import themes, { Theme } from '~/themes';
import { Form, Formik } from 'formik';
import { Field } from '~/components/common/Formik';
import { IStore } from '~/models/stores';
import Button from '~/components/common/Button';
import { useUpdateConnectionMutation } from '~/services/api/userManagement';
import { every } from 'lodash';
import SwitchButton from '~/components/common/SwitchButton';
import Tooltip from '~/components/common/Tooltip';
import InfoIcon from '~/assets/images/icons/info-gray.svg';

const style = (theme: Theme) =>
  createStyles({
    switchLabel: {
      marginLeft: theme.spacing(1),
    },
  });

type Props = {
  data: IStore;
};

const defaultDisableValue = {
  enable: false,
  channel: {
    email: false,
    sms: false,
  },
  status_update: {
    delivered: false,
    driver_is_arriving: false,
    in_transit: false,
    order_received: false,
    out_for_delivery: false,
  },
};

const defaultEnableValue = {
  enable: true,
  channel: {
    email: true,
    sms: true,
  },
  status_update: {
    delivered: true,
    driver_is_arriving: true,
    in_transit: true,
    order_received: true,
    out_for_delivery: true,
  },
};

const NotifyCustomer = ({
  data,
  classes,
}: Props & WithStyles<typeof style>) => {
  const { mutate: updateConnectionMutation, isLoading } =
    useUpdateConnectionMutation({});

  const onSubmit = (values, { setValues }) => {
    let paramNotification = { ...values };
    if (!paramNotification.enable) {
      paramNotification = {
        ...defaultDisableValue,
      };
    } else if (
      every(paramNotification.channel, (v) => !v) ||
      every(paramNotification.status_update, (v) => !v)
    ) {
      paramNotification = { ...defaultDisableValue };
    }

    setValues(paramNotification);
    const params = {
      ...data,
      notification_setting: {
        ...paramNotification,
      },
    };

    updateConnectionMutation(params);
  };

  return (
    <Stack
      spacing={2}
      pb={1.5}
      color={themes.color.violet500}
      sx={{
        '& .MuiCheckbox-root': {
          padding: '6px !important',
          width: '36px',
          height: '36px',
        },
      }}
    >
      <Formik
        initialValues={{
          ...data.notification_setting,
        }}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values, setValues, dirty }) => (
          <Form id='notify_customer'>
            <Stack flexDirection='column' gap={1.6}>
              <Typography
                color={themes.color.violet500}
                variant='h5'
                fontWeight={700}
              >
                Enable Notification
              </Typography>
              <Stack
                flexDirection='row'
                alignItems='center'
                color={themes.color.violet500}
              >
                <SwitchButton
                  noText
                  formClasses={{
                    label: classes.switchLabel,
                  }}
                  checked={values.enable}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValues(defaultEnableValue);
                    } else {
                      setValues(defaultDisableValue);
                    }
                  }}
                />
                Notify customers about the delivery progress.
              </Stack>
            </Stack>
            {values.enable && (
              <>
                <Divider sx={{ my: 2 }} />
                <Stack flexDirection='column' gap={1.6}>
                  <Box>
                    <Typography
                      variant='h5'
                      fontWeight={700}
                      color={themes.color.violet500}
                    >
                      Notification Channels
                    </Typography>
                    <Typography>
                      How would you like to notify your customers?
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      '& .MuiFormControlLabel-root': {
                        marginLeft: '-6px',
                        width: 'fit-content',
                      },
                    }}
                  >
                    <Field.CheckBox
                      name='channel.sms'
                      defaultCheckedIcon
                      label='Send SMS message to notify customer'
                    />
                    <Field.CheckBox
                      name='channel.email'
                      defaultCheckedIcon
                      label='Send email to notify customer'
                    />
                  </Box>
                </Stack>
                <Divider sx={{ my: 2 }} />
                <Stack flexDirection='column' gap={1.6}>
                  <Box>
                    <Typography
                      variant='h5'
                      fontWeight={700}
                      color={themes.color.violet500}
                    >
                      Status Updates
                    </Typography>
                    <Typography>
                      Select the delivery status updates you would like to
                      notify your customer about.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      '& .MuiFormControlLabel-root': {
                        marginLeft: '-6px',
                        width: 'fit-content',
                      },
                    }}
                  >
                    <Field.CheckBox
                      name='status_update.order_received'
                      defaultCheckedIcon
                      label='Order received'
                    />
                    <Field.CheckBox
                      name='status_update.in_transit'
                      defaultCheckedIcon
                      label={
                        <Tooltip
                          title={<Typography>Send SMS only.</Typography>}
                          arrow
                        >
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                              gap: '4px',
                            }}
                          >
                            In transit
                            <img src={InfoIcon} alt='' />
                          </Typography>
                        </Tooltip>
                      }
                    />
                    <Field.CheckBox
                      name='status_update.out_for_delivery'
                      defaultCheckedIcon
                      label='Out for delivery'
                    />
                    <Field.CheckBox
                      name='status_update.driver_is_arriving'
                      defaultCheckedIcon
                      label='Driver is arriving'
                    />
                    <Field.CheckBox
                      name='status_update.delivered'
                      defaultCheckedIcon
                      label='Delivered'
                    />
                  </Box>
                </Stack>
              </>
            )}
            <Button
              disabled={!dirty}
              className='save-change-btn'
              fullWidth
              loading={isLoading}
              type='submit'
              form='notify_customer'
            >
              Save Changes
            </Button>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export default withStyles(style)(NotifyCustomer);
