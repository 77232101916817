import { IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Icon } from '~/components/common/Icon';
import themes, { styled } from '~/themes';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from '~/components/common/Button';
import { useGetTeamsQuery } from '~/services/api/shared';
import { useGetIndependentDrivers } from '~/services/api/task';
import { DriverStatus, LocationInfo } from '~/pages/LiveTracking/types';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { ITeam } from '~/models/shared';
import { DriverAvatar } from '~/components/shared/AssignTask/DriverAvatar';
import {
  LiveTrackingQueryKeys,
  updateTask,
  useGetDrivers,
  useUpdateTask,
} from '~/pages/LiveTracking/apis';
import { queryClient } from '~/lib/react-query';
import { random } from 'lodash';
import { showAlert, alertParams } from '~/components/common/Alert';
import { errorAlert } from '~/components/common/Alert/alertParams';
import { SearchInput } from '~/components/common/Search';
import { DriverDistance } from './DriverDistance';
import { AccordionItem } from '../../AddTask/components/AccordionItem';

type AssignDriverProps = {
  multiTaskIds?: string[];
  isOpenTaskDetail?: boolean;
  isAssignMultiTask?: boolean;
  taskId?: string;
  hubId: string;
  pickup?: LocationInfo | null;
  delivery?: LocationInfo;
  onBackToTaskDetail?: () => void;
  handleCloseTaskDetail?: () => void;
  onChangeAssigner?: (data: { id: string; type: string }) => void;
  setMultiTaskIds?: (v?: string[]) => void;
};

const StyledDriverListContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  width: '100%',
  boxSizing: 'border-box',
  padding: theme.spacing(1, 2),
}));

export const AssignDriver: React.FC<AssignDriverProps> = ({
  taskId,
  hubId,
  pickup,
  delivery,
  multiTaskIds,
  isAssignMultiTask,
  onBackToTaskDetail,
  setMultiTaskIds,
  onChangeAssigner,
  handleCloseTaskDetail,
}) => {
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [assignerId, setAssignerId] = useState('');
  const [searchDriverQuery, setSearchDriverQuery] = useState('');

  const [isLoadingUpdateMultiTasks, setIsLoadingUpdateMultiTasks] =
    useState(false);

  const { isStarterOrStandardPlan } = usePaymentInfo();

  const { data: teamData, isFetching: isFetchingTeam } = useGetTeamsQuery({
    params: {
      hub_id: hubId,
    },
    enabled: !isStarterOrStandardPlan,
  });
  const {
    data: independentDrivers,
    isFetching: isFetchingGetIndependentDrivers,
  } = useGetIndependentDrivers({ enabled: !isStarterOrStandardPlan });

  const { data: driverData, isFetching: isFetchingDriver } = useGetDrivers({
    params: {
      status: 'active',
      expand: 'team',
      roles: 'driver',
    },
    enabled: isStarterOrStandardPlan,
  });

  const { mutate: updateTaskMutation, isLoading: isLoadingUpdateTask } =
    useUpdateTask({
      onSuccess: (resp) => {
        onBackToTaskDetail();
        showAlert(alertParams.successDark);
        queryClient.invalidateQueries([
          LiveTrackingQueryKeys.TaskDetail,
          resp?.id,
        ]);
        queryClient.invalidateQueries([LiveTrackingQueryKeys.TaskLog]);
        queryClient.invalidateQueries([LiveTrackingQueryKeys.TasksList]);
        queryClient.invalidateQueries([LiveTrackingQueryKeys.DriverList]);
      },
    });

  const expanded = (id: string) => !!expandedIds.find((e) => e === id);

  useEffect(() => {
    if (teamData?.length) {
      setExpandedIds(teamData.map((t) => t.id));
    }
  }, [teamData]);

  const handleChangeExpandedIds = (newId: string) => (event, isExpanded) => {
    setExpandedIds((prevIds) =>
      isExpanded
        ? prevIds.concat([newId])
        : prevIds.filter((id) => id !== newId),
    );
  };

  const handleClosePanel = useCallback(() => {
    if (isAssignMultiTask) {
      setMultiTaskIds([]);
    } else {
      onBackToTaskDetail();
    }
  }, [isAssignMultiTask]);

  const handleAssignMultiTask = useCallback(async () => {
    const promises = multiTaskIds.map((id) =>
      updateTask({
        type: 'driver',
        id,
        executor_id: assignerId.split('&')[1],
        executor_type: 'driver',
      }),
    );

    setIsLoadingUpdateMultiTasks(true);
    try {
      await Promise.all(promises);
      handleCloseTaskDetail();
      showAlert(alertParams.successDark);

      queryClient.invalidateQueries([LiveTrackingQueryKeys.TasksList]);
      queryClient.invalidateQueries([LiveTrackingQueryKeys.DriverList]);
    } catch (error) {
      showAlert(errorAlert(error));
    } finally {
      setIsLoadingUpdateMultiTasks(false);
    }
  }, [multiTaskIds, assignerId, taskId]);

  const skeletonLoadingComponent = useMemo(
    () => (
      <StyledDriverListContainer spacing={1} className='customized-scrollbar'>
        {Array(9)
          .fill('')
          .map(() => (
            <Stack
              key={Math.random()}
              p={1.5}
              spacing={1}
              direction='row'
              alignItems='center'
              sx={{
                background: 'white',
                p: 1.5,
                borderRadius: '10px',
              }}
            >
              <Skeleton
                animation={false}
                variant='circular'
                width={30}
                height={30}
              />
              <Skeleton width={random(80, 150)} height={20} variant='text' />
            </Stack>
          ))}
      </StyledDriverListContainer>
    ),
    [],
  );

  const headerComponent = useMemo(
    () => (
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        justifyContent='space-between'
        sx={{ background: themes.bg.white, p: 2 }}
      >
        <Stack direction='row' alignItems='center' spacing={1}>
          {!isAssignMultiTask && (
            <IconButton
              onClick={onBackToTaskDetail}
              sx={{
                width: 30,
                height: 30,
                background: themes.bg.midPurple,
                borderRadius: '5px',
              }}
            >
              <ArrowBackIosNewIcon
                sx={{ color: themes.color.violet900, width: 16, height: 16 }}
              />
            </IconButton>
          )}
          <Typography variant='h4'>
            {isAssignMultiTask ? 'ASSIGN MULTIPLE TASKS' : 'ASSIGN TASK'}
          </Typography>
        </Stack>
        <IconButton onClick={handleClosePanel}>
          <Icon name='close' size={14} color={themes.bg.darkPurple} />
        </IconButton>
      </Stack>
    ),
    [isAssignMultiTask],
  );

  const searchComponent = useMemo(
    () => (
      <Stack
        spacing={1}
        sx={{
          p: '20px !important',
          borderRadius: '15px',
          background: themes.bg.white,
          mb: 1,
        }}
      >
        <Typography fontSize={16} fontWeight={500}>
          Assign Driver (Optional)
        </Typography>
        <Stack direction='row' alignItems='center'>
          <SearchInput
            fullWidth
            placeholder='Search Driver'
            value={searchDriverQuery}
            onKeyDown={(event: any) => {
              if (event.keyCode === 13) {
                // detect Enter key press
                event.preventDefault(); // prevent default behavior
              }
            }}
            onChange={(e) => setSearchDriverQuery(e.target.value)}
            onClear={() => setSearchDriverQuery('')}
          />
          {isStarterOrStandardPlan ? (
            <div />
          ) : (
            <Typography
              sx={{
                textDecoration: 'underline',
                minWidth: 100,
                cursor: 'pointer',
              }}
              textAlign='right'
              onClick={() =>
                expandedIds.length
                  ? setExpandedIds([])
                  : setExpandedIds(teamData.map((t) => t.id))
              }
            >
              {expandedIds.length ? 'Collapse All' : 'Expand All'}
            </Typography>
          )}
        </Stack>
      </Stack>
    ),
    [searchDriverQuery, isStarterOrStandardPlan, teamData, expandedIds],
  );

  const renderDriver = (teamId, driver) => {
    const {
      id,
      display_name,
      driver_status,
      receive_auto_assign_task,
      idle_at,
    } = driver;
    const idSelect = `${teamId}&${id}`;
    const isChecked = assignerId === idSelect;

    return (
      <Stack
        key={teamId + id}
        component='li'
        value={display_name}
        data-value={id}
        data-testid='driver-assign-item'
        direction='row'
        spacing={1}
        justifyContent='space-between'
        alignItems='center'
        onClick={() => {
          if (isChecked) {
            setAssignerId('');
          } else {
            setAssignerId(idSelect);
          }
          if (onChangeAssigner) {
            onChangeAssigner({ id: isChecked ? null : id, type: 'driver' });
          }
        }}
        sx={{
          background: 'white',
          cursor: 'pointer',
          p: 1.5,
          borderRadius: '10px',
          ...(isChecked && {
            background: themes.bg.midPurple,
          }),
        }}
      >
        <Stack direction='row' spacing={1} alignItems='center'>
          <DriverAvatar
            status={driver_status}
            avatar={driver.avatar}
            name={display_name}
            receiveAutoAssignTask={receive_auto_assign_task}
            idleAt={idle_at}
          />
          <Typography color={themes.color.black} fontWeight={500}>
            {display_name}
          </Typography>
        </Stack>

        {driver.driver_status === DriverStatus.Online && !isAssignMultiTask ? (
          <DriverDistance
            driver={driver}
            pickup={pickup || {}}
            delivery={delivery || {}}
          />
        ) : (
          ''
        )}
      </Stack>
    );
  };

  const renderAccordionItem = (team: ITeam, idx) => {
    const { id, driver_info, is_preferred_team } = team;
    const driverInfo = driver_info.filter((e) =>
      e.display_name.toLowerCase().includes(searchDriverQuery?.toLowerCase()),
    );
    const isShowPreferredTeam = hubId && is_preferred_team && idx === 0;
    return (
      <AccordionItem
        id={id}
        expanded={expanded}
        key={id}
        handleChangeExpandedIds={handleChangeExpandedIds}
        styles={{
          container: {
            'borderRadius': '10px !important',
            '&::before': {
              display: 'none',
            },
          },
          summary: {
            'padding': themes.spacing(1),
            '& > div': {
              margin: '8px 10px !important',
            },
          },
          detail: {
            p: '0px 10px 10px 10px !important',
          },
        }}
        summaryContent={
          isShowPreferredTeam ? (
            <Typography
              fontWeight={500}
            >{`Preferred Team: ${team.name}`}</Typography>
          ) : (
            <Typography fontWeight={500}>{`Team: ${team.name}`}</Typography>
          )
        }
      >
        {driverInfo.length ? (
          driverInfo.map((driver) => renderDriver(id, driver))
        ) : (
          <Stack p={1.5} pl={2}>
            <Typography color={themes.color.black}>
              No driver available
            </Typography>
          </Stack>
        )}
      </AccordionItem>
    );
  };

  const renderDriversForStarterAndStandardPlan = () =>
    driverData
      .filter((e) =>
        e.display_name.toLowerCase().includes(searchDriverQuery?.toLowerCase()),
      )
      .map((driver) => renderDriver('', driver));

  const driverListComponent = useMemo(() => {
    if (
      (!isStarterOrStandardPlan &&
        !teamData?.length &&
        !independentDrivers?.length) ||
      (isStarterOrStandardPlan && !driverData?.length)
    )
      return (
        <StyledDriverListContainer>
          <Typography color={themes.color.black}>
            No driver available
          </Typography>
        </StyledDriverListContainer>
      );
    return (
      <StyledDriverListContainer spacing={1} className='customized-scrollbar'>
        {isAssignMultiTask && (
          <Typography
            sx={{
              p: 0.3,
              textAlign: 'center',
              background: themes.bg.midPurple,
              borderRadius: '5px',
            }}
          >
            {multiTaskIds.length}
            {` ${multiTaskIds.length > 1 ? 'tasks' : 'task'}`} selected
          </Typography>
        )}
        {isStarterOrStandardPlan ? (
          renderDriversForStarterAndStandardPlan()
        ) : (
          <Stack mt={1} spacing={1}>
            {teamData?.length
              ? teamData.map((team, teamIdx) =>
                  renderAccordionItem(team, teamIdx),
                )
              : ''}
          </Stack>
        )}
        {independentDrivers.length
          ? independentDrivers
              .filter((e) =>
                e.display_name
                  ?.toLowerCase()
                  .includes(searchDriverQuery?.toLowerCase()),
              )
              .map((driver) => renderDriver('', driver))
          : ''}
      </StyledDriverListContainer>
    );
  }, [
    teamData,
    independentDrivers,
    expandedIds,
    assignerId,
    isStarterOrStandardPlan,
    driverData,
    searchDriverQuery,
    isAssignMultiTask,
    multiTaskIds,
  ]);
  const bottomButtonsComponent = useMemo(
    () => (
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        justifyContent='space-between'
        sx={{ background: themes.bg.white, p: 2 }}
      >
        <Button
          buttonType='default'
          fullWidth
          rounder10
          onClick={handleClosePanel}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          buttonType='primary-dark'
          rounder10
          disabled={!assignerId}
          loading={isLoadingUpdateTask || isLoadingUpdateMultiTasks}
          onClick={async () => {
            if (isAssignMultiTask) {
              handleAssignMultiTask();
            } else {
              updateTaskMutation({
                type: 'driver',
                id: taskId,
                executor_id: assignerId.split('&')[1],
                executor_type: 'driver',
              });
            }
          }}
        >
          Assign
        </Button>
      </Stack>
    ),
    [taskId, isLoadingUpdateMultiTasks, isLoadingUpdateTask, assignerId],
  );

  const isShowSkeletonLoading =
    isFetchingTeam || isFetchingGetIndependentDrivers || isFetchingDriver;

  return (
    <>
      {(taskId || isAssignMultiTask) && headerComponent}
      {!taskId && !isAssignMultiTask && searchComponent}
      {isShowSkeletonLoading ? skeletonLoadingComponent : driverListComponent}
      {(taskId || isAssignMultiTask) && bottomButtonsComponent}
    </>
  );
};
