import { useNavigate, useParams } from 'react-router-dom';
import { memoryStorage } from '~/utils/localStorage';

export const useWorkspaceNavigate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const workspaceId = id || memoryStorage.getOrgId();

  return (path, options = {}) => {
    let fullPath = path;
    if (path.startsWith('/')) {
      if (workspaceId) {
        fullPath = `/ws/${workspaceId}${path}`;
      } else {
        console.warn(
          'Workspace ID is not available, navigating to an absolute path.',
        );
      }
    }
    navigate(fullPath, options);
  };
};
