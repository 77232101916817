import { IBillingFrequency, IPlanTier } from '~/models/plan';

export const plansJsonData = [
  {
    id: 1,
    tier: IPlanTier.Free,
    title: 'Starter',
    price: '$0',
    price_num: 0,
    frequency: IBillingFrequency.Month,
    key_features: [
      '1 user & 1 driver',
      '50 tasks/mo',
      'Additional tasks: Not available',
    ],
    checks: ['1 user', '1 driver', '50 tasks/mo', 'No additional task'],
    numbers_of_task: '50',
  },
  {
    id: 3,
    tier: IPlanTier.Standard,
    price: '$189',
    price_num: 189,
    priceId: '',
    frequency: IBillingFrequency.Month,
    title: 'Standard',
    key_features: [
      'Unlimited drivers & users',
      '2000 tasks/mo',
      'Additional tasks: $0.08/task',
    ],
    checks: [
      'Unlimited drivers',
      'Unlimited users',
      '2000 tasks/mo',
      '$0.08/additional task',
    ],
    numbers_of_task: '2,000',
  },
  {
    id: 4,
    tier: IPlanTier.Standard,
    price: '$149',
    price_num: 149,
    priceId: '',
    frequency: IBillingFrequency.Year,
    title: 'Standard',
    key_features: [
      'Unlimited drivers & users',
      '2000 tasks/mo',
      'Additional tasks: $0.08/task',
    ],
    checks: [
      'Unlimited drivers',
      'Unlimited users',
      '2000 tasks/mo',
      '$0.08/additional task',
    ],
    numbers_of_task: '2,000',
  },
  {
    id: 5,
    tier: IPlanTier.Advance,
    title: 'Advanced',
    price: '$439',
    price_num: 439,
    priceId: '',
    frequency: IBillingFrequency.Month,
    key_features: [
      'Unlimited drivers & users',
      '6000 tasks/mo',
      'Additional tasks: $0.06/task',
    ],
    checks: [
      'Unlimited drivers',
      'Unlimited users',
      '6000 tasks/mo',
      '$0.06/additional task',
    ],
    numbers_of_task: '6,000',
  },
  {
    id: 6,
    tier: IPlanTier.Advance,
    title: 'Advanced',
    price: '$349',
    price_num: 349,
    priceId: '',
    frequency: IBillingFrequency.Year,
    key_features: [
      'Unlimited drivers & users',
      '6000 tasks/mo',
      'Additional tasks: $0.06/task',
    ],
    checks: [
      'Unlimited drivers',
      'Unlimited users',
      '6000 tasks/mo',
      '$0.06/additional task',
    ],
    numbers_of_task: '6,000',
  },
  {
    id: 7,
    tier: IPlanTier.Bundle,
    title: 'Dispensary',
    price: 'Bundle',
    price_num: 349,
    priceId: '',
    frequency: IBillingFrequency.Year,
    key_features: [
      'Unlimited drivers & users',
      '6000 tasks/mo',
      'Additional tasks: $0.06/task',
    ],
    checks: ['Unlimited drivers', 'Unlimited users', 'Unlimited tasks'],
    numbers_of_task: '6,000',
  },
];
