import {
  DriverMarkerContent,
  DriverRouteInfoWindow,
  DriverRouteMarker,
} from '../components/Markers/MarkerContents';
import {
  Driver,
  Driver as IDriver,
  IDriverRoute,
  IDriverRouteMap,
  MarkerType,
} from '../types';
import { groupDriverRoutesByPosition, isValidLatLngLiteral } from '../utils';

export interface IDriverRouteGroup {
  [key: string]: IDriverRoute;
}

export class DriverRoutes {
  private map: google.maps.Map | null;

  private directionsService = new google.maps.DirectionsService();

  private directionsRenderer: google.maps.DirectionsRenderer;

  public routes: IDriverRoute[] = [];

  public updateRoutes: IDriverRoute[] = [];

  private driver: IDriver | null = null;

  private driverInfoWindow: google.maps.InfoWindow;

  private driverInfoWindowMarker: google.maps.marker.AdvancedMarkerElement;

  private driverRoutesEvents: {
    el: HTMLElement;
    handler: () => void;
  }[] = [];

  // eslint-disable-next-line class-methods-use-this
  private forceRender: React.Dispatch<React.SetStateAction<number>> = () => {};

  private directionsRendererOptions: google.maps.DirectionsRendererOptions = {
    suppressMarkers: true,
    markerOptions: {
      visible: false,
    },
    preserveViewport: true,
    polylineOptions: {
      visible: true,
      strokeWeight: 5,
      strokeColor: '#0897FF',
    },
  };

  private driverRoutesMap: IDriverRouteMap[] = [];

  constructor(map: google.maps.Map | null) {
    this.map = map;
  }

  setFnForceRender(fn: React.Dispatch<React.SetStateAction<number>>) {
    this.forceRender = fn;
  }

  addDriverInfoWindow(driver: Driver) {
    if (isValidLatLngLiteral(driver)) {
      this.driver = driver;
      const driverPosition = { lat: driver.lat, lng: driver.lng };
      this.driverInfoWindowMarker =
        new google.maps.marker.AdvancedMarkerElement({
          position: driverPosition,
        });

      this.driverInfoWindow = new google.maps.InfoWindow({
        position: driverPosition,
        content: DriverMarkerContent({
          avatar: driver.avatar,
          driverStatus: driver.driver_status,
          name: driver?.display_name,
        }),
        pixelOffset: new google.maps.Size(2, 50),
      });

      this.driverInfoWindow.open(this.map, this.driverInfoWindowMarker);
    }
  }

  showDriverInfoWindow() {
    this.driverInfoWindow.open(this.map);
  }

  hideDriverInfoWindow() {
    if (this.driverInfoWindow) {
      this.driverInfoWindow.close();
    }
  }

  clearDriverInfoWindow() {
    if (this.driverInfoWindow) {
      this.driverInfoWindow.close();
    }
    this.driverInfoWindow = null;
  }

  getDriverSelected() {
    return this.driver;
  }

  setDriverSelected(driver: Driver) {
    this.driver = driver;
  }

  setDriverRoutes(driverRoutes: IDriverRoute[]) {
    this.routes = driverRoutes;
  }

  setUpdateDriverRoutes(driverRoutes: IDriverRoute[]) {
    this.updateRoutes = driverRoutes;
  }

  expandTasks(key: string) {
    this.driverRoutesMap = this.driverRoutesMap.map((r) => {
      const { infoWindow, routes, isExpanded } = r;
      if (key === `${`${r.position.lat}&${r.position.lng}`}`) {
        infoWindow.setContent(
          DriverRouteInfoWindow(routes, {
            isOnManageRoutes: true,
            isExpanded: !isExpanded,
            latLngId: key,
          }),
        );

        if (!isExpanded === true) {
          infoWindow.setZIndex(9999);
        } else {
          infoWindow.setZIndex(1);
        }

        return {
          ...r,
          isExpanded: !isExpanded,
        };
      }
      return r;
    });
  }

  addDriverRoutesMarkers(
    driverRoutes: IDriverRoute[],
    opts: {
      isOnManageRoutes?: boolean;
      isOnlyOneRoute?: boolean;
    } = { isOnManageRoutes: false, isOnlyOneRoute: false },
  ) {
    if (!opts.isOnManageRoutes) {
      driverRoutes = driverRoutes.map((r, i) => ({ ...r, sort_id: i + 1 }));
    }
    const driverRoutesGrouped = groupDriverRoutesByPosition(driverRoutes);
    Object.keys(driverRoutesGrouped).forEach((key) => {
      const routes = driverRoutesGrouped[key];
      const latLngs: google.maps.LatLngLiteral = {
        lat: routes[0].lat,
        lng: routes[0].lng,
      };
      if (!isValidLatLngLiteral(latLngs)) return;
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: latLngs,
        content: DriverRouteMarker(routes, {
          isOnManageRoutes: opts.isOnManageRoutes,
          isExpanded: false,
          latLngId: key,
          isOnlyOneRoute: opts.isOnlyOneRoute,
        }),
        zIndex: 1,
      });

      marker.map = this.map;

      marker.addListener('click', () => {
        this.driverRoutesMap = this.driverRoutesMap.map((r) => {
          if (`${r.position.lat}&${r.position.lng}` === key) {
            const newExpandedState = !r.isExpanded;
            r.marker.content = DriverRouteMarker(routes, {
              isOnManageRoutes: opts.isOnManageRoutes,
              isExpanded: newExpandedState,
              latLngId: key,
              isOnlyOneRoute: opts.isOnlyOneRoute,
            });
            r.marker.zIndex = newExpandedState ? 10 : 1;
            return { ...r, isExpanded: newExpandedState };
          }
          return r;
        });
      });

      const sortIds = routes
        .map((r) => (opts.isOnManageRoutes ? r.update_sort_id : r.sort_id))
        .filter((r) => !!r);
      const waypointSortId = sortIds.length ? Math.min(...sortIds) : null;
      this.driverRoutesMap.push({
        infoWindow: null,
        marker,
        routes,
        position: latLngs,
        isExpanded: false,
        waypoints: {
          location: latLngs,
          stopover: routes.some((e) => !!e.sort_id),
        },
        waypointSortId,
      });
    });
  }

  clearDriverRoutesEvents() {
    this.driverRoutesEvents.forEach((e) =>
      e?.el?.removeEventListener('click', e.handler),
    );
    this.driverRoutesEvents = [];
  }

  clearDriverRoutesMarkers() {
    if (this.driverRoutesMap.length) {
      this.clearDriverRoutesEvents();
      this.driverRoutesMap.forEach(({ marker }) => {
        google.maps.event.clearListeners(marker, 'click');
        marker.map = null;
      });
    }
    this.driverRoutesMap = [];
  }

  updateDriverRoutes(updatedRoutes: IDriverRoute[]) {
    this.clearDriverRoutesEvents();
    const driverRoutesGrouped = groupDriverRoutesByPosition(updatedRoutes);
    this.driverRoutesMap = this.driverRoutesMap.map((r) => {
      const { infoWindow, position, waypoints } = r;
      const key = `${position.lat}&${position.lng}`;
      const routes: IDriverRoute[] = driverRoutesGrouped[key];

      const sortIds = routes.map((e) => e.update_sort_id).filter((e) => !!e);

      const waypointSortId = sortIds.length ? Math.min(...sortIds) : null;

      infoWindow.setContent(
        DriverRouteInfoWindow(routes, {
          isOnManageRoutes: true,
          isExpanded: r.isExpanded,
          latLngId: key,
        }),
      );
      return {
        ...r,
        routes,
        infoWindow,
        waypoints: {
          location: waypoints.location,
          stopover: routes.some((e) => !!e.update_sort_id),
        },
        waypointSortId,
      };
    });

    this.drawRoutes({ isOnManageRoutes: true });
  }

  drawRoutes(params: { isOnManageRoutes?: boolean }) {
    const waypoints = this.driverRoutesMap
      .filter((r) => r.waypoints.stopover)
      .sort((a, b) => a.waypointSortId - b.waypointSortId)
      .map((r) => r.waypoints);
    if (waypoints.length === 0) return;

    const driverPosition: google.maps.LatLngLiteral = {
      lat: this.driver?.lat,
      lng: this.driver?.lng,
    };

    if (!isValidLatLngLiteral(driverPosition)) return;

    const directionsRenderer = new google.maps.DirectionsRenderer();
    directionsRenderer.setOptions({
      map: this.map,
      ...this.directionsRendererOptions,
      ...(params?.isOnManageRoutes && {
        polylineOptions: {
          visible: true,
          strokeWeight: 5,
          strokeColor: '#C9C9C9',
        },
      }),
    });

    const request: google.maps.DirectionsRequest = {
      origin: driverPosition,
      destination: waypoints[waypoints.length - 1].location,
      travelMode: google.maps.TravelMode.DRIVING,
      waypoints,
      // optimizeWaypoints: true,
    };
    this.directionsRenderer = directionsRenderer;
    this.directionsService.route(request, (response, status) => {
      if (status === 'OK') {
        // Display the route on the map
        directionsRenderer.setDirections(response);
      } else {
        this.directionsRenderer.setMap(null);
      }
    });
  }

  clearRoutes() {
    if (this.directionsRenderer) {
      this.directionsRenderer.setOptions({
        polylineOptions: {
          visible: false,
        },
      });
      this.directionsRenderer.setMap(null);
    }
  }

  setDriverRoutesMap(data: IDriverRouteMap[]) {
    this.driverRoutesMap = data;
  }

  getDriverRoutesMap() {
    return this.driverRoutesMap;
  }

  clearDriverRoutes() {
    if (this.directionsRenderer) {
      this.directionsRenderer.setOptions({
        polylineOptions: {
          visible: false,
        },
      });
      this.directionsRenderer.setMap(null);
    }
  }

  hideMarkers(
    markerTypes: MarkerType[] = [
      MarkerType.DropOff,
      MarkerType.Store,
      MarkerType.Driver,
    ],
  ) {
    markerTypes.forEach((type) => {
      if (type === MarkerType.Driver) {
        this.driverInfoWindow.close();
      } else {
        this.driverRoutesMap.forEach(({ marker }) => {
          marker.map = null;
        });
      }
    });
  }

  showMarkers(
    markerTypes: MarkerType[] = [
      MarkerType.DropOff,
      MarkerType.Store,
      MarkerType.Driver,
    ],
  ) {
    markerTypes.forEach((type) => {
      if (type === MarkerType.Driver) {
        this.driverInfoWindow?.open(this.map, this.driverInfoWindowMarker);
      } else {
        this.driverRoutesMap.forEach(({ marker }) => {
          marker.map = this.map;
        });
      }
    });
  }
}
