import moment from 'moment';
import { useContext, createContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TIMEZONE_SETTING } from '~/constants/adminOptions';
import { queryClient } from '~/lib/react-query';
import { IAccount } from '~/models/adminOptions';
import { ICreateOrgParams, IUnifiedNavigationData } from '~/models/auth';
import { IUser, IUserTooltip } from '~/models/user';
import { useGetAccounts } from '~/services/api/auth';
import { useUpdateOrgMutation } from '~/services/api/org';
import { queryKeys } from '~/services/api/queryKeys';
import {
  useGetCountriesQuery,
  useGetUnifiedNavigation,
} from '~/services/api/shared';
import { useUpdateUserTooltipMutation } from '~/services/api/userManagement';
import { convertTooltipData } from '~/utils/common';
import {
  memoryStorage,
  LOCAL_STORAGE_KEYS,
  set,
  get,
} from '~/utils/localStorage';
import { fromQueryString } from '~/utils/queryString';
import { updateTimeZoneMoment } from '~/utils/settings';

import { setAccountQueryData } from '~/utils/reactQuery';
import { firebaseGetToken } from '~/services/firebase';
import { jwtDecode } from 'jwt-decode';
import { CountryListItem } from '~/models/common';
import { BroadcastEvents, channel } from '~/lib/broadcast';
import { useUnifiedAuthContext } from './UnifiedAuthProvider';

export interface AuthContextProps {
  accounts: IAccount[];
  account: IAccount;
  isFetchingAccounts: boolean;
  isFetchingUnifiedNavigation: boolean;
  unifiedNavigation: IUnifiedNavigationData;
  unifiedAccountInfo: {
    has_fb_account?: boolean;
    has_app?: boolean;
    has_bs?: boolean;
  };
  countries: CountryListItem[];
  isFetchingCountry: boolean;
  defaultAddress: {
    pickup: { country: string; state: string };
    delivery: { country: string; state: string };
  };
  refetchAccounts: () => {};
  updateUserTooltip: (value: IUserTooltip) => void;
  updateOrgTooltip: (value: ICreateOrgParams) => void;
  getTooltipOpen: (id: string) => boolean;
}
export const AuthContext = createContext({} as AuthContextProps);

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isVerifyingSSO } = useUnifiedAuthContext();

  const searchQueries = fromQueryString(location.search);
  const isSkipRedirectPage = [
    '/invite-user',
    '/t',
    '/register/verified',
    '/reset-password',
    '/new-password',
    '/register',
    '/privacy-policy',
  ].some((p) => location.pathname.includes(p));

  const isSkipRedirectWhenLogged = [
    '/invite-user',
    '/invite-user/success',
    '/t',
    '/register/verified',
    '/new-password',
    '/privacy-policy',
  ].some((p) => location.pathname.includes(p));

  const isEnabledNavigationRequest =
    ![
      '/t',
      '/login',
      '/register/verified',
      '/reset-password',
      '/invite-user',
      '/invite-user/success',
      '/register',
      '/user-onboarding',
      '/new-password',
      '/privacy-policy',
      '/create-workspace',
    ].some((p) => location.pathname.includes(p)) ||
    location.pathname.includes('/tasks');

  const orgId = useMemo(() => {
    if (memoryStorage.getOrgId()) return memoryStorage.getOrgId();

    const path = location.pathname;
    const parts = path.split('/').filter(Boolean);

    const wsIndex = parts.indexOf('ws');
    if (wsIndex !== -1 && wsIndex + 1 < parts.length) {
      return parts[wsIndex + 1];
    }

    return null;
  }, [location.pathname]);

  const {
    data: { users: accounts, has_app, has_fb_account, has_bs },
    isFetching: isFetchingAccounts,
    refetch,
  } = useGetAccounts({
    enabled: !isVerifyingSSO,
    params: {
      'canfleet-org-id': orgId,
    },
    onSuccess: async (resp: {
      users: IAccount[];
      has_fb_account?: boolean;
      has_app?: boolean;
      email_verified?: boolean;
    }) => {
      if (isSkipRedirectWhenLogged) return false;
      if (resp?.users?.length) {
        const defaultAccount = resp.users.find((x) => x.default) || accounts[0];
        memoryStorage.setOrgId(defaultAccount?.org_id);
      }

      if (!resp.users.length) {
        const idToken = await firebaseGetToken();
        const tkDecoded = jwtDecode<{ email: string }>(idToken);
        if (resp?.has_fb_account && !resp?.has_app) {
          if (location.pathname === '/login') {
            return true;
          }
          const encodedEmail = encodeURIComponent(tkDecoded.email);
          setTimeout(() => navigate(`/register?email=${encodedEmail}`), 0);
        } else {
          setTimeout(
            () =>
              navigate(
                {
                  pathname: 'user-onboarding',
                  search: location.search,
                },
                { replace: true },
              ),
            0,
          );
        }
      }
      return true;
    },
    onError: () => {
      if (isSkipRedirectPage) return false;
      return navigate('/login');
    },
  });

  const account: IAccount = useMemo(() => {
    if (!accounts.length) return null;
    if (accounts.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      return accounts[0];
    }
    return accounts.find((x) => x.default) || accounts[0];
  }, [accounts]);

  const {
    data: unifiedNavigationData,
    isFetching: isFetchingUnifiedNavigation,
  } = useGetUnifiedNavigation({
    enabled: isEnabledNavigationRequest && !!account?.id,
    params: {
      cf_org_id: account?.org_id,
      source: 'canfleet',
    },
  });
  const unifiedNavigation = unifiedNavigationData.data;

  const { data: countriesResp, isFetching: isFetchingCountry } =
    useGetCountriesQuery({
      enabled: !!account?.id,
    });

  const countries = countriesResp as CountryListItem[];

  const defaultAddress = useMemo(() => {
    const address = {
      pickup: {
        country: 'CA',
        state: 'BC',
      },
      delivery: {
        country: 'CA',
        state: 'BC',
      },
    };

    if (countries.length) {
      countries.forEach((country) => {
        country.states
          .filter((s) => s.is_suggestion_pickup || s.is_suggestion_delivery)
          .forEach((state) => {
            if (state.is_suggestion_pickup) {
              address.pickup.country = country.value;
              address.pickup.state = state.value;
            }
            if (state.is_suggestion_delivery) {
              address.delivery.country = country.value;
              address.delivery.state = state.value;
            }
          });
      });
    }

    return address;
  }, [countries]);

  useEffect(() => {
    const shopifyStoreURLStorage = get(
      LOCAL_STORAGE_KEYS.SHOPIFY_REDIRECT_STORE_URL,
    );

    if (account?.org_id) {
      if (shopifyStoreURLStorage) {
        navigate(
          `/setting/stores?${LOCAL_STORAGE_KEYS.SHOPIFY_REDIRECT_STORE_URL}=${shopifyStoreURLStorage}`,
        );
      }
      if (account?.timezone) {
        updateTimeZoneMoment(
          account?.timezone === TIMEZONE_SETTING.AUTO_DETECT
            ? moment.tz.guess() // detect with local timezone
            : account?.timezone,
        );
      }

      memoryStorage.setOrgId(account.org_id);
      if (isEnabledNavigationRequest && (!orgId || orgId !== account.org_id)) {
        navigate(`/ws/${account.org_id}/`);
      }
      if (isEnabledNavigationRequest) {
        channel.postMessage({
          type: BroadcastEvents.RefreshOtherPageWhenChangedOrg,
          payload: {
            orgId: account.org_id,
          },
        });
      }
    }
  }, [account]);

  useEffect(() => {
    if (searchQueries[LOCAL_STORAGE_KEYS.SHOPIFY_REDIRECT_STORE_URL]) {
      set(
        LOCAL_STORAGE_KEYS.SHOPIFY_REDIRECT_STORE_URL,
        searchQueries[LOCAL_STORAGE_KEYS.SHOPIFY_REDIRECT_STORE_URL],
      );
    }
  }, [searchQueries]);

  const { mutate: updateOrg } = useUpdateOrgMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.orgManagement.org]);
    },
  });

  const updateOrgTooltip = (newTooltip: ICreateOrgParams) => {
    updateOrg(newTooltip);
  };

  const { mutate: onUpdateUserTooltip } = useUpdateUserTooltipMutation({
    onSuccess: (res: IUser) => {
      setAccountQueryData({
        ...account,
        tooltip: res.tooltip,
      });
    },
    onError: () => {},
  });

  const updateUserTooltip = (newTooltip: IUserTooltip) => {
    onUpdateUserTooltip({
      tooltip: convertTooltipData({
        ...account?.tooltip,
        ...newTooltip,
      }),
    });
  };

  const getTooltipOpen = (id: string) => account?.tooltip?.[id] === false;

  const value = useMemo(
    () => ({
      accounts,
      account,
      unifiedAccountInfo: {
        has_app: !!has_app,
        has_fb_account: !!has_fb_account,
        has_bs: !!has_bs,
      },
      unifiedNavigation,
      isFetchingAccounts,
      isFetchingUnifiedNavigation,
      countries,
      isFetchingCountry,
      defaultAddress,
      refetchAccounts: refetch,
      updateUserTooltip,
      getTooltipOpen,
      updateOrgTooltip,
    }),
    [
      accounts,
      account,
      has_app,
      has_fb_account,
      has_bs,
      unifiedNavigation,
      isFetchingAccounts,
      isFetchingUnifiedNavigation,
      countries,
      isFetchingCountry,
      defaultAddress,
      refetch,
      updateUserTooltip,
      getTooltipOpen,
      updateOrgTooltip,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('Components cannot be rendered outside the Auth component');
  }
  return context;
};

export { AuthProvider, useAuth };
