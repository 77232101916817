import { Grid, Stack, Typography } from '@mui/material';
import Button, { CloseButton } from '~/components/common/Button';
import Modal from '~/components/common/Modal';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import themes, { styled } from '~/themes';
import BannerBgImg from '~/assets/images/background/upsell-plans.png';
import { Icon } from '~/components/common/Icon';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';
import { useWorkspaceNavigate } from '~/hooks/useWorkspaceNavigate';

type DialogUpSellUpgradePlanProps = {};

const StyledImage = styled('img')(() => ({
  width: '100%',
  height: 'auto',
  maxWidth: 500,
}));

const StyledText = styled(Typography)(() => ({
  marginLeft: themes.spacing(1),
  whiteSpace: 'break-spaces',
  fontWeight: 'bold',
  color: themes.color.violet900,
  lineHeight: '16px',
}));

export const DialogUpSellUpgradePlan: React.FC<
  DialogUpSellUpgradePlanProps
> = () => {
  const { isOpenUpSellDialog, setIsOpenUpSellDialog } = usePaymentInfo();
  const navigate = useWorkspaceNavigate();
  const onClose = () => setIsOpenUpSellDialog(false);

  const planOptions = [
    {
      icon: 'package',
      label: 'Increased\nTask Limit',
    },
    {
      icon: 'driver',
      label: 'Unlimited\nDrivers & Users',
    },
    {
      icon: 'store',
      label: 'Set Store\nDriver Team',
    },
    {
      icon: 'watch',
      label: 'Automated\nTask Flow',
    },
    {
      icon: 'path',
      label: 'Delivery Tracking\nfor Customers',
    },
  ];
  return (
    <Modal
      disableCloseOutside
      open={isOpenUpSellDialog}
      onClose={onClose}
      PaperProps={{
        sx: {
          'maxWidth': 850,
          'height': 558,
          'borderRadius': '10px !important',
          '& .MuiDialogContent-root': {
            padding: 0,
          },
        },
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          overflow: 'hidden',
          height: '100%',
          width: '100%',
        }}
      >
        <Stack
          justifyContent='center'
          alignItems='center'
          sx={{
            flex: 1,
            position: 'relative',
            p: 2,
            background: themes.bg.darkPurple,
            [themes.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
              display: 'none',
            },
          }}
        >
          <StyledImage src={BannerBgImg} alt='banner-bg-img' />
        </Stack>
        <Stack
          sx={{ flex: 1.1, px: 4, position: 'relative' }}
          justifyContent='center'
        >
          <CloseButton
            sx={{
              position: 'absolute',
              top: 20,
              right: 20,
            }}
            onClick={onClose}
          />
          <Typography variant='h2'>
            Your current plan’s capacity has been reached
          </Typography>
          <Typography color={themes.color.gray900} mt={1.5} mb={3}>
            Unlock Breadstack Delivery’s full potential by upgrading your plan
            to enhance task efficiency and elevate customer satisfaction!
          </Typography>
          <Grid container spacing={4}>
            {planOptions.map((option) => (
              <Grid item xs={6} key={option.label}>
                <Stack direction='row' alignItems='center'>
                  <Icon name={option.icon} size={40} useBackgroundImg />
                  <StyledText>{option.label}</StyledText>
                </Stack>
              </Grid>
            ))}
          </Grid>

          <Button
            fullWidth
            sx={{ mt: 6 }}
            onClick={() => {
              onClose();
              navigate('/setting/plans');
            }}
          >
            Upgrade Now
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
