import { useEffect, useMemo, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { Stack, Divider, Typography } from '@mui/material';
import themes, { styled } from '~/themes';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import moment from 'moment';
import ReturnIcon from '~/assets/images/map/return-purple.svg';
import DropOffIcon from '~/assets/images/map/dropoff-green.svg';
import PickUpIcon from '~/assets/images/map/pickup-orange.svg';
import { IDriverRoute, MarkerType } from '~/pages/LiveTracking/types';
import { CSS } from '@dnd-kit/utilities';
import MenuIcon from '@mui/icons-material/Menu';
import { isEqual } from 'lodash';

export const StyledDivider = styled(Divider)(() => ({
  'color': '#717D96',
  'background': 'inherit !important',
  'fontSize': 14,
  'width': 'calc(100% + 40px)',
  '& .MuiDivider-wrapper': {
    maxWidth: '100%',
    overflow: 'hidden',
    flexGrow: '0.1',
    padding: '0 5px',
  },
  '&::before': {
    top: 0,
    borderTop: '1px dashed #717D96',
    minWidth: '10%',
    flex: '1',
  },
  '&::after': {
    top: 0,
    borderTop: '1px dashed #717D96',
    minWidth: '10%',
    flex: '1',
  },
}));

export const StyledDividerInner = styled('div')(() => ({
  overflow: 'hidden',
  display: 'inline-flex',
}));

export enum BorderRouteType {
  Precede = 'precede',
  Succeed = 'succeed',
}

type Props = {
  route: IDriverRoute;
  index: any;
  borderRoute: any;
  getRouteIdx?: any;
  overlay?: boolean;
};

const SortableTask = ({
  route,
  index,
  borderRoute,
  getRouteIdx,
  overlay = false,
}: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    newIndex,
    activeIndex,
    overIndex,
    setActivatorNodeRef,
  } = useSortable({
    id: route.id,
    disabled: false,
  });

  const [prevTransform, setPrevTransform] = useState(transform);

  const notAllow = useMemo(() => {
    if (borderRoute?.type === BorderRouteType.Precede) {
      return index >= borderRoute.idx;
    }

    if (borderRoute?.type === BorderRouteType.Succeed) {
      return index <= borderRoute.idx;
    }

    return false;
  }, [borderRoute, index]);

  useEffect(() => {
    if (isEqual(transform, prevTransform) || overIndex === -1) return;
    if (newIndex === activeIndex) {
      setPrevTransform(transform);
      return;
    }
    if (
      borderRoute?.type === BorderRouteType.Precede &&
      newIndex >= borderRoute.idx
    ) {
      return;
    }
    if (
      borderRoute?.type === BorderRouteType.Succeed &&
      newIndex <= borderRoute.idx
    ) {
      return;
    }

    setPrevTransform(transform);
  }, [newIndex, transform, borderRoute]);

  const isShowDivider = borderRoute && index === borderRoute.idx;

  const style = {
    opacity: isDragging ? 0 : 1,
    transform:
      (notAllow && !isDragging) || !transform
        ? undefined
        : CSS.Translate.toString(isDragging ? prevTransform : transform),
    transition,
  };

  const getTaskIcon = (markerType: MarkerType) => {
    switch (markerType) {
      case MarkerType.Store:
        return (
          <>
            <img
              style={{ marginRight: 4, marginBottom: 1 }}
              src={PickUpIcon}
              alt='task-icon'
            />
            <span style={{ marginRight: 4, color: '#F7982A' }}>Pickup</span>
          </>
        );
      case MarkerType.DropOff:
        return (
          <>
            <img
              style={{ marginRight: 4, marginBottom: 1 }}
              src={DropOffIcon}
              alt='task-icon'
            />
            <span style={{ marginRight: 4, color: '#74D6BA' }}>Dropoff</span>
          </>
        );
      case MarkerType.Return:
        return (
          <>
            <img
              style={{ marginRight: 4, marginBottom: 1 }}
              src={ReturnIcon}
              alt='task-icon'
            />
            <span style={{ marginRight: 4, color: '#9B57D1' }}>Return</span>
          </>
        );
      default:
        return '';
    }
  };

  return (
    <Stack
      width='100%'
      overflow='hidden'
      direction='column'
      alignItems='center'
      marginBottom='8px'
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {isShowDivider && borderRoute.type === BorderRouteType.Precede && (
        <StyledDivider
          sx={{ marginTop: '10px', marginBottom: '20px' }}
          key={`divider-${route.id}`}
        >
          <StyledDividerInner>
            task must precede its linked dropoff
          </StyledDividerInner>
        </StyledDivider>
      )}

      <Stack
        width='100%'
        direction='row'
        alignItems='center'
        sx={{
          touchAction: 'manipulation',
          cursor: 'pointer',
          ...(notAllow && { opacity: 0.4 }),
        }}
        ref={setActivatorNodeRef}
      >
        {overlay ? (
          <Typography
            textAlign='center'
            minWidth={30}
            fontWeight={500}
            sx={{
              touchAction: 'none',
              cursor: 'pointer',
            }}
          >
            <MenuIcon />
          </Typography>
        ) : (
          <Typography
            textAlign='center'
            minWidth={30}
            fontWeight={500}
            sx={{
              touchAction: 'none',
              cursor: 'pointer',
            }}
          >
            {getRouteIdx(route, index)}
          </Typography>
        )}
        <Stack
          width='100%'
          p={1}
          ml={0.5}
          gap={0.4}
          sx={{
            background: themes.bg.white,
            borderRadius: '8px',
            position: 'relative',
            ...((overlay || isShowDivider) && {
              border: '1px solid #33363D',
              boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.16)',
            }),
          }}
        >
          <Stack spacing={0.5} direction='row' justifyContent='space-between'>
            <Typography
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#33363D',
              }}
            >
              {getTaskIcon(route.marker_type)}
              {route.task_name || ''}
            </Typography>
            <Typography
              height={21}
              color={route.is_alerted ? '#F75879' : '#8C95BA'}
            >
              {route.expected_delivery_before || ''}
            </Typography>
          </Stack>
          <Stack
            spacing={0.5}
            direction='row'
            justifyContent='space-between'
            flex={1}
          >
            <ToolTipText
              text={`${route.address_1}, ${route.city}`}
              maxLength={route.eta_time ? 18 : 40}
              textNode={(v) => <Typography>{v}</Typography>}
            />
            <Typography color={route.is_alerted ? '#F64066' : '#979DC3'}>
              {route.eta_time
                ? `ETA ${moment().add(route.eta_time, 's').calendar(null, {
                    sameDay: 'h:mm a',
                    nextDay: 'h:mm a | [Tmrw]',
                    lastDay: 'h:mm a | [Ystd]',
                    nextWeek: 'hh:mm a | MM/DD',
                    lastWeek: 'hh:mm a | MM/DD',
                    sameElse: 'hh:mm a | MM/DD',
                  })}`
                : '-'}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {isShowDivider && borderRoute.type === BorderRouteType.Succeed && (
        <StyledDivider
          sx={{ marginBottom: '10px', marginTop: '20px' }}
          key={`divider-${route.id}`}
        >
          <StyledDividerInner>
            task must follow its linked pickup
          </StyledDividerInner>
        </StyledDivider>
      )}
    </Stack>
  );
};

export default SortableTask;
