import {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import { ControlPosition } from '~/pages/LiveTracking/constants';
import { Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';
import PolylineIcon from '@mui/icons-material/Polyline';
import resetIcon from '~/assets/images/icons/reset.svg';
import resetGrayIcon from '~/assets/images/icons/reset-gray.svg';
import undoIcon from '~/assets/images/icons/undo.svg';
import undoGrayIcon from '~/assets/images/icons/undo-gray.svg';
import redoIcon from '~/assets/images/icons/redo.svg';
import redoGrayIcon from '~/assets/images/icons/redo-gray.svg';
import themes from '~/themes';

type DrawnZonesMapProps = {
  latLngs?: any;
  setFieldValue?: any;
  isValidateError?: boolean;
  allLatLngs?: any;
  defaultCenter?: {
    lat: number;
    lng: number;
  };
};

export const DEFAULT_LAT = 49.2577143;
export const DEFAULT_LNG = -122.9539433;
export const DEFAULT_LOCATION = { lat: DEFAULT_LAT, lng: DEFAULT_LNG };
const polygonOptions = {
  clickable: true,
  editable: true,
  draggable: true,
  geodesic: true,
  zIndex: 100,
  strokeWeight: 2,
};

const disabledPolygonOptions = {
  clickable: false,
  editable: false,
  draggable: false,
  geodesic: false,
  zIndex: 100,
  strokeWeight: 1,
};

const mapOptions: google.maps.MapOptions = {
  mapTypeControl: false,
  fullscreenControl: true,
  streetViewControl: false,
  zoomControlOptions: {
    position:
      ControlPosition.RIGHT_BOTTOM as unknown as google.maps.ControlPosition,
  },
  zoomControl: true,
  styles: null,
  zoom: 11,
  disableDefaultUI: true,
  center: {
    lat: 49.21556039735341,
    lng: -123.09840777108371,
  },
};

export const DrawnZonesMapUndoRedo = forwardRef(
  (
    {
      latLngs = [],
      allLatLngs = [],
      setFieldValue,
      isValidateError,
      defaultCenter,
    }: DrawnZonesMapProps,
    ref: React.Ref<any>,
  ) => {
    const shapeRef = useRef<any>();
    const oldShapeRef = useRef<any>();
    const mapRef = useRef();
    const [map, setMap] = useState<google.maps.Map | null>(null);

    // Create a state to store the paths
    const [drawingPaths, setDrawingPaths] = useState<any[]>([]);
    const redoStackRef = useRef([]);
    const [undoWhenFinish, setUndoWhenFinish] = useState(false);
    const [redoStack, setRedoStack] = useState<any[][]>([]);
    const [isDrawing, setIsDrawing] = useState(true);
    const [polygonCompleted, setPolygonCompleted] = useState(false);
    const polygonCompletedRef = useRef(polygonCompleted);
    const drawingPathsRef = useRef([]);
    const [undoCount, setUndoCount] = useState(0); // Counter for undo actions
    const [redoCount, setRedoCount] = useState(0); // Counter for redo actions
    const [originLatLngs, setOriginLatLngs] = useState([]);
    const [originAllLatLngs, setOriginAllLatLngs] = useState([]);
    const buttonRef = useRef();
    const virtualLineRef = useRef<google.maps.Polyline | null>(null);

    const setBoundaryToPolygon = (positions) => {
      if (!positions) return;

      const bounds = new google.maps.LatLngBounds();
      if (positions.length) {
        positions.forEach((position) => {
          bounds.extend(position);
        });
        map.fitBounds(bounds);
      }
    };

    const updatePathsFromShape = (shape, isPolyline = false) => {
      const newPaths = shape
        .getPath()
        .getArray()
        .map((latLng) => ({
          lng: latLng.lng(),
          lat: latLng.lat(),
        }));
      if (isPolyline) {
        setDrawingPaths(newPaths);
      }
      if (polygonCompletedRef.current) {
        setFieldValue(
          'long_lat',
          newPaths.map((p) => [p.lng, p.lat]),
        );
      }
    };

    const handlePolygonDragEnd = () => {
      updatePathsFromShape(oldShapeRef.current);
    };

    const handlePolylinePathChanged = () => {
      updatePathsFromShape(shapeRef.current, true);
    };

    const handleSetPolygonToMap = (pathArray) => {
      const polygonDrawn = new google.maps.Polygon({
        paths: pathArray,
        ...polygonOptions,
        ...(allLatLngs.length && disabledPolygonOptions),
      });
      oldShapeRef.current = polygonDrawn;
      // shapeRef.current = polygonDrawn;
      polygonDrawn.setMap(map);
      setFieldValue(
        'long_lat',
        pathArray.map((p) => [p.lng, p.lat]),
      );
      setBoundaryToPolygon(pathArray.map((p) => ({ lng: p.lng, lat: p.lat })));
      // Add event listeners for drag events
      google.maps.event.addListener(
        polygonDrawn,
        'dragend',
        handlePolygonDragEnd,
      );
      google.maps.event.addListener(
        polygonDrawn.getPath(),
        'set_at',
        handlePolygonDragEnd,
      );
      google.maps.event.addListener(
        polygonDrawn.getPath(),
        'insert_at',
        handlePolygonDragEnd,
      );

      return () => {
        google.maps.event.clearListeners(polygonDrawn, 'dragend');
        google.maps.event.clearListeners(polygonDrawn.getPath(), 'set_at');
        google.maps.event.clearListeners(polygonDrawn.getPath(), 'insert_at');
      };
    };

    const handleSetShapeToMap = (pathArray, closePolygon = false) => {
      if (shapeRef.current) {
        shapeRef.current.setMap(null);
      }

      if (virtualLineRef.current) {
        virtualLineRef.current.setMap(null);
        virtualLineRef.current = null;
      }

      if (closePolygon) {
        const polygonDrawn = new google.maps.Polygon({
          paths: [...pathArray, pathArray[0]],
          ...polygonOptions,
          ...(allLatLngs.length && disabledPolygonOptions),
        });
        if (oldShapeRef.current) {
          oldShapeRef.current.setMap(null);
        }
        oldShapeRef.current = polygonDrawn;
        polygonDrawn.setMap(map);
        const newPolygon = [];
        for (let i = 0; i <= pathArray.length - 1; i += 1) {
          const point = pathArray[i];
          try {
            newPolygon.push([point.lng(), point.lat()]);
          } catch (err) {
            newPolygon.push([point.lng, point.lat]);
          }
        }
        setFieldValue('long_lat', newPolygon);
        setBoundaryToPolygon(newPolygon.map((p) => ({ lng: p[0], lat: p[1] })));
        setPolygonCompleted(true);
        setUndoWhenFinish(true);
        // Add event listeners for drag events
        google.maps.event.addListener(
          polygonDrawn,
          'dragend',
          handlePolygonDragEnd,
        );
        google.maps.event.addListener(
          polygonDrawn.getPath(),
          'set_at',
          handlePolygonDragEnd,
        );
        google.maps.event.addListener(
          polygonDrawn.getPath(),
          'insert_at',
          handlePolygonDragEnd,
        );
        return () => {
          google.maps.event.clearListeners(polygonDrawn, 'dragend');
          google.maps.event.clearListeners(polygonDrawn.getPath(), 'set_at');
          google.maps.event.clearListeners(polygonDrawn.getPath(), 'insert_at');
        };
      }

      const polylineDrawn = new google.maps.Polyline({
        path: pathArray,
        ...polygonOptions,
        ...(allLatLngs.length && disabledPolygonOptions),
      });
      shapeRef.current = polylineDrawn;
      polylineDrawn.setMap(map);
      if (pathArray.length === 0 && oldShapeRef.current) {
        const newPaths = oldShapeRef.current
          .getPath()
          .getArray()
          .map((latLng) => ({
            lng: latLng.lng(),
            lat: latLng.lat(),
          }));

        setFieldValue(
          'long_lat',
          newPaths.map((p) => [p.lng, p.lat]),
        );
      } else {
        setFieldValue('long_lat', []);
      }
      // Add event listeners for path change events
      google.maps.event.addListener(
        polylineDrawn.getPath(),
        'set_at',
        handlePolylinePathChanged,
      );
      google.maps.event.addListener(
        polylineDrawn.getPath(),
        'insert_at',
        handlePolylinePathChanged,
      );
      google.maps.event.addListener(polylineDrawn, 'click', (event) => {
        // Check if the vertex clicked is the first one and the path length is greater than 2
        if (event.vertex === 0 && pathArray.length > 2) {
          // Call the handleSetShapeToMap function with the closed path
          handleSetShapeToMap(drawingPathsRef.current, true);
        }
      });

      return () => {
        google.maps.event.clearListeners(polylineDrawn.getPath(), 'set_at');
        google.maps.event.clearListeners(polylineDrawn.getPath(), 'insert_at');
        google.maps.event.clearListeners(polylineDrawn, 'click');
      };
    };

    const updateShape = (newPaths, closePolygon = false) => {
      handleSetShapeToMap(newPaths, closePolygon);
    };

    const startDrawing = () => {
      setIsDrawing(true);
    };

    const finishDrawing = () => {
      setIsDrawing(false);
    };

    const reset = () => {
      setDrawingPaths([]);
      setRedoStack([]);
      updateShape([]);
      setRedoCount(0);
      setUndoCount(0);
      setFieldValue('long_lat', []);

      if (shapeRef.current) {
        shapeRef.current.setMap(null);
      }

      if (oldShapeRef.current) {
        oldShapeRef.current.setMap(null);
      }

      shapeRef.current = null;
      oldShapeRef.current = null;

      if (originLatLngs?.length || originAllLatLngs.length) {
        if (originAllLatLngs.length) {
          originAllLatLngs.forEach((item) => {
            const cvPaths = item.map((e) => ({ lng: e[0], lat: e[1] }));
            handleSetPolygonToMap(cvPaths);
          });
        } else {
          const cvPaths = originLatLngs.map((e) => ({ lng: e[0], lat: e[1] }));
          handleSetPolygonToMap(cvPaths);
        }

        const bounds = new google.maps.LatLngBounds();
        let positions = [];

        if (originAllLatLngs.length) {
          positions = originAllLatLngs
            .flat()
            .map((e) => ({ lng: e[0], lat: e[1] }));
        }
        if (originLatLngs.length) {
          positions = originLatLngs.map((e) => ({ lng: e[0], lat: e[1] }));
        }
        if (positions.length) {
          positions.forEach((position) => {
            bounds.extend(position);
          });
          map.fitBounds(bounds);
        } else {
          // Set the center of the map to the default location
          map.setCenter(defaultCenter || DEFAULT_LOCATION);
        }
      }
    };

    const undo = () => {
      if (!isDrawing || drawingPaths.length === 0) return;

      let newDrawingPaths = drawingPaths;

      if (polygonCompletedRef.current) {
        if (shapeRef.current) {
          shapeRef.current.setMap(null);
        }

        if (oldShapeRef.current) {
          oldShapeRef.current.setMap(null);
        }

        shapeRef.current = null;
        oldShapeRef.current = null;

        setPolygonCompleted(false);
      } else {
        newDrawingPaths = drawingPaths.slice(0, -1);
      }

      setDrawingPaths(newDrawingPaths);
      if (oldShapeRef.current && !newDrawingPaths.length) {
        const newPaths = oldShapeRef.current
          .getPath()
          .getArray()
          .map((latLng) => ({
            lng: latLng.lng(),
            lat: latLng.lat(),
          }));
        setFieldValue(
          'long_lat',
          newPaths.map((p) => [p.lng, p.lat]),
        );
      }
      setRedoStack((prev) => [drawingPaths, ...prev]); // Save the current drawingPaths to redoStack

      updateShape(newDrawingPaths);
      setRedoCount(0); // Reset the redo counter
      setUndoCount((prev) => prev + 1); // Increment the undo counter
    };

    const redo = () => {
      if (redoStack.length === 0) return;

      const lastRedo = redoStack[0]; // Get the first item in redoStack
      setRedoStack((prev) => prev.slice(1)); // Remove the first item from redoStack

      setDrawingPaths(lastRedo);

      if (undoWhenFinish && redoStackRef.current.length === 1) {
        updateShape(lastRedo, true);
      } else {
        updateShape(lastRedo);
      }
      setUndoCount(0); // Reset the undo counter
      setRedoCount((prev) => prev + 1); // Increment the redo counter
    };

    const disableResetButton =
      drawingPaths.length === 0 &&
      originLatLngs.length === 0 &&
      originAllLatLngs.length === 0 &&
      !oldShapeRef.current &&
      !shapeRef.current;

    const handleMouseMove = (event) => {
      if (
        !isDrawing ||
        drawingPaths.length === 0 ||
        polygonCompletedRef.current
      ) {
        return;
      }
      const lastPoint = drawingPaths.slice(-1)[0];
      const currentPoint = event.latLng;

      if (virtualLineRef.current) {
        virtualLineRef.current.setMap(null);
        virtualLineRef.current = null;
      }
      const polylineDrawn = new google.maps.Polyline({
        path: [lastPoint, currentPoint],
        ...disabledPolygonOptions,
        strokeColor: '#7d6d6d',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      virtualLineRef.current = polylineDrawn;
      polylineDrawn.setMap(map);

      google.maps.event.addListener(polylineDrawn, 'click', (e) => {
        if (polygonCompletedRef.current) {
          // Clear the drawing paths and polygonCompleted state
          setDrawingPaths([]);
          setPolygonCompleted(false);
          drawingPathsRef.current = [];
        }

        const newPoint = e.latLng;
        const newDrawingPaths = [...drawingPathsRef.current, newPoint];
        setDrawingPaths(newDrawingPaths);
        updateShape(newDrawingPaths);
        setRedoStack([]);
        setRedoCount(0);
        setUndoCount(0);
        setUndoWhenFinish(false);
      });

      // eslint-disable-next-line consistent-return
      return () => {
        google.maps.event.clearListeners(polylineDrawn, 'click');
      };
    };

    const handleMouseOut = () => {
      if (virtualLineRef.current) {
        virtualLineRef.current.setMap(null);
        virtualLineRef.current = null;
      }
    };

    useEffect(() => {
      const initMap = new window.google.maps.Map(mapRef.current, {
        ...mapOptions,
        draggable: true,
        gestureHandling: 'auto',
        clickableIcons: false,
        disableDoubleClickZoom: true,
        draggableCursor: 'crosshair',
      });
      setMap(initMap);
      initMap.controls[google.maps.ControlPosition.TOP_CENTER].push(
        buttonRef?.current,
      );
    }, []);

    useEffect(() => {
      if (map) {
        const handleClick = (event) => {
          if (!isDrawing) {
            return;
          }

          if (virtualLineRef.current) {
            virtualLineRef.current.setMap(null);
            virtualLineRef.current = null;
          }

          if (polygonCompletedRef.current) {
            // Clear the drawing paths and polygonCompleted state
            setDrawingPaths([]);
            setPolygonCompleted(false);
            drawingPathsRef.current = [];
          }

          const newPoint = event.latLng;
          const newDrawingPaths = [...drawingPathsRef.current, newPoint];
          setDrawingPaths(newDrawingPaths);
          updateShape(newDrawingPaths);
          setRedoStack([]);
          setRedoCount(0);
          setUndoCount(0);
          setUndoWhenFinish(false);
        };

        const handleKeyDown = (event) => {
          if (!isDrawing) {
            return;
          }

          if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
            event.preventDefault();
            if (event.shiftKey) {
              if (redoStackRef.current.length === 0 || redoCount >= 20) {
                return;
              }
              redo();
            } else {
              if (drawingPaths.length === 0 || undoCount >= 20) {
                return;
              }
              undo();
            }
          } else if (
            (event.ctrlKey || event.metaKey) &&
            (event.key === 'y' || (event.key === 'Z' && event.shiftKey))
          ) {
            event.preventDefault();
            if (redoStackRef.current.length === 0 || redoCount >= 20) {
              return;
            }
            redo();
          }
        };

        const mapClickListener = map.addListener('click', handleClick);
        window.addEventListener('keydown', handleKeyDown);
        const mouseMoveListener = map.addListener('mousemove', handleMouseMove);

        const mouseOutListener = map.addListener('mouseout', handleMouseOut);

        return () => {
          google.maps.event.removeListener(mapClickListener);
          google.maps.event.removeListener(mouseMoveListener);
          google.maps.event.removeListener(mouseOutListener);
          window.removeEventListener('keydown', handleKeyDown);
        };
      }
      return () => {};
    }, [map, isDrawing, drawingPaths]);

    useEffect(() => {
      polygonCompletedRef.current = polygonCompleted;
    }, [polygonCompleted]);

    useEffect(() => {
      drawingPathsRef.current = drawingPaths;
    }, [drawingPaths]);

    useEffect(() => {
      redoStackRef.current = redoStack;
    }, [redoStack]);

    useEffect(() => {
      if (map) {
        if (isDrawing) {
          map.setOptions({
            draggable: true,
            gestureHandling: 'auto',
            clickableIcons: false,
            disableDoubleClickZoom: true,
            draggableCursor: 'crosshair',
          });
        } else {
          map.setOptions({
            draggable: true,
            gestureHandling: 'auto',
            clickableIcons: true,
            disableDoubleClickZoom: false,
            draggableCursor: 'grab',
          });
        }
      }
      return () => {};
    }, [isDrawing]);

    useEffect(() => {
      if (map) {
        setOriginLatLngs(latLngs);
        setOriginAllLatLngs(allLatLngs);
        if (latLngs?.length || allLatLngs.length) {
          if (allLatLngs.length) {
            allLatLngs.forEach((item) => {
              const cvPaths = item.map((e) => ({ lng: e[0], lat: e[1] }));
              handleSetPolygonToMap(cvPaths);
            });
          } else {
            const cvPaths = latLngs.map((e) => ({ lng: e[0], lat: e[1] }));
            handleSetPolygonToMap(cvPaths);
          }
        }

        const bounds = new google.maps.LatLngBounds();
        let positions = [];

        if (allLatLngs.length) {
          positions = allLatLngs.flat().map((e) => ({ lng: e[0], lat: e[1] }));
        }
        if (latLngs.length) {
          positions = latLngs.map((e) => ({ lng: e[0], lat: e[1] }));
        }
        if (positions.length) {
          positions.forEach((position) => {
            bounds.extend(position);
          });
          map.fitBounds(bounds);
        } else {
          // Set the center of the map to the default location
          map.setCenter(defaultCenter || DEFAULT_LOCATION);
        }
      }
    }, [map]);

    useImperativeHandle(
      ref,
      () => ({
        getPaths: () => {
          if (!shapeRef?.current && !oldShapeRef?.current) return [];
          const newPolygon = [];
          const shape = shapeRef?.current?.getPath();
          if (shape) {
            for (let i = 0; i < shape.getLength(); i += 1) {
              newPolygon.push([shape.getAt(i).lng(), shape.getAt(i).lat()]);
            }
          }
          if (!newPolygon.length) {
            const oldShape = oldShapeRef?.current?.getPath();
            if (oldShape) {
              for (let i = 0; i < oldShape.getLength(); i += 1) {
                newPolygon.push([
                  oldShape.getAt(i).lng(),
                  oldShape.getAt(i).lat(),
                ]);
              }
            }
          }
          return newPolygon;
        },
      }),
      [],
    );

    return (
      <>
        <Grid
          container
          alignItems='end'
          justifyContent='space-between'
          spacing={1}
        >
          <Grid item xs={12} md='auto'>
            <Typography variant='h5' color={themes.color.black}>
              Draw Zone
            </Typography>
            <Typography mt={1}>
              Use the draw tool to determine the delivery zone.
            </Typography>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Stack
              flexDirection='row'
              columnGap={1}
              justifyContent='end'
              sx={{ fontSize: 14 }}
            >
              <Button
                type='button'
                onClick={reset}
                disabled={disableResetButton}
                sx={{
                  backgroundColor: '#E9E7F6',
                  borderRadius: '5px',
                  cursor: disableResetButton ? 'not-allowed' : 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                  outline: 'none',
                  border: 'none',
                  width: 80,
                  height: 35,
                }}
              >
                <img
                  src={disableResetButton ? resetGrayIcon : resetIcon}
                  alt='Reset'
                  width='16'
                  height='16'
                />
                Reset
              </Button>
              <Button
                type='button'
                onClick={undo}
                disabled={drawingPaths.length === 0 || undoCount >= 20}
                sx={{
                  backgroundColor: '#E9E7F6',
                  borderRadius: '5px',
                  cursor: drawingPaths.length === 0 ? 'not-allowed' : 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                  outline: 'none',
                  border: 'none',
                  width: 80,
                  height: 35,
                }}
              >
                <img
                  src={
                    drawingPaths.length === 0 || undoCount >= 20
                      ? undoGrayIcon
                      : undoIcon
                  }
                  alt='Undo'
                  width='14'
                  height='14'
                />
                Undo
              </Button>
              <Button
                type='button'
                onClick={redo}
                disabled={redoStackRef.current.length === 0 || redoCount >= 20}
                sx={{
                  backgroundColor: '#E9E7F6',
                  borderRadius: '5px',
                  cursor: redoStack.length === 0 ? 'not-allowed' : 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                  outline: 'none',
                  border: 'none',
                  width: 80,
                  height: 35,
                }}
              >
                <img
                  src={
                    redoStackRef.current.length === 0 || redoCount >= 20
                      ? redoGrayIcon
                      : redoIcon
                  }
                  alt='Redo'
                  width='14'
                  height='14'
                />
                Redo
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <div style={{ position: 'relative' }}>
          <div
            ref={mapRef}
            id='drawn-zones-map'
            style={{
              marginTop: 20,
              minHeight: 300,
              width: '100%',
              position: 'relative',
              borderRadius: '20px',
              ...(isValidateError && {
                border: '1px solid #F55073',
              }),
            }}
          />
          <div
            ref={buttonRef}
            style={{
              marginTop: 10,
              display: 'flex',
              gap: '3px',
              backgroundColor: 'white',
              borderRadius: '5px',
              padding: '2px',
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Tooltip title='Stop drawing'>
              <button
                type='button'
                onClick={finishDrawing}
                aria-label='Stop drawing'
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  opacity: !isDrawing ? 1 : 0.5,
                  padding: 0,
                  height: 20,
                }}
              >
                <ExploreIcon
                  style={{ fontWeight: !isDrawing ? 'bold' : 'normal' }}
                  fontSize='small'
                />
              </button>
            </Tooltip>
            <Tooltip title='Draw a shape'>
              <button
                type='button'
                onClick={startDrawing}
                aria-label='Draw a shape'
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  opacity: isDrawing ? 1 : 0.5,
                  padding: 0,
                  height: 20,
                }}
              >
                <PolylineIcon
                  style={{ fontWeight: isDrawing ? 'bold' : 'normal' }}
                  fontSize='small'
                />
              </button>
            </Tooltip>
          </div>
        </div>
      </>
    );
  },
);
